import { AxiosResponse } from 'axios';

import client, { request } from '../axios';

export const index = async (withDemo: boolean): Promise<AxiosResponse> => (
  request(client.get(`/shared?with-demo=${withDemo}`))
);

export const show = async (id: string): Promise<AxiosResponse> => (
  request(client.get(`/shared/${id}`))
);
