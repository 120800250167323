import _ from 'lodash/fp';
import { Base64 } from 'js-base64';

// @ts-ignore
const monthToInt = (obj): number => parseInt(obj.month, 10);

const getEventsByMonth = _.flow(
  _.toPairs,
  _.map(_.zipObject(['month', 'events'])),
  _.orderBy([monthToInt], ['desc']),
);

export const eventsByYear = _.flow(
  _.groupBy('dateYear'),
  _.mapValues(_.groupBy('dateMonth')),
  _.toPairs,
  _.map((item) => ({
    year: item[0],
    eventsByMonth: getEventsByMonth(item[1]),
  })),
  _.orderBy(['year'], ['desc']),
);

export const assistantEvents = _.flow(
  _.filter('isAssistant'),
  eventsByYear,
);

export const encodeToken = Base64.encode;
