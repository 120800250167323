import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash/fp';

import TextContainer from '../../../../components/TextContainer';
import Image from '../../../../components/Image';
import i18n from '../../../../services/i18n';

type Props = {
  children: ReactNode;
  icon: string;
  title: string;
  styles?: string;
  viewAll?: string;
}

const HomeSection: FunctionComponent<Props> = (
  {
    icon,
    title,
    children,
    styles = '',
    viewAll = '',
  }: Props,
) => (
  <div className={`home-section ${styles}`}>
    <div className="decoration">
      <div className="w-32 h-1 bg-primary-x-light" />
      <div className="w-16 h-16 bg-primary-x-light rounded-full" />
      <Image src={icon} styles="h-16 absolute top-0 -right-12" />
    </div>
    <div className="flex justify-between items-end w-full max-w-192 mx-auto mb-8">
      <TextContainer
        tag="h3"
        size="one-line-medium"
        font="montserrat-bold"
        styles="uppercase flex items-center"
      >
        <img src={icon} alt="" className="h-16 mr-4 md:hidden" />
        {title}
      </TextContainer>
      {!_.isEmpty(viewAll) && (
        <Link to={viewAll} className="block text-small mb-2 pb-1">
          {i18n.t('view_all')}
        </Link>
      )}
    </div>
    {children}
  </div>
);

export default HomeSection;
