import _ from 'lodash/fp';
import React, { FunctionComponent } from 'react';

import i18n from '../../../../../services/i18n';
import { CarAttributes, CarParams } from '../../../../../store/types/car';
import Image from '../../../../../components/Image';
import icons from '../../../../../assets/images/icons';
import {
  carImageBase64,
  carImage,
  isProgressComplete,
} from '../../../../../lib/helpers/car';
import { getDocument } from '../../../../../lib/helpers/documents';

import ProgressItem from './components/ProgressItem';

type Props = {
  values: CarParams;
  car: CarAttributes
}

const Progress: FunctionComponent<Props> = ({ values, car }: Props) => (
  <div className="w-full py-6 hidden xl:block">
    <div className="wrap flex items-end justify-around">
      <Image src={icons.progress.initial} styles="w-32 h-32" />
      <ProgressItem
        title={i18n.t('car:attributes:make')}
        value={values.carMake}
        icon={icons.vehicle.make}
      />
      <ProgressItem
        title={i18n.t('car:attributes:model')}
        value={values.modelName}
        icon={icons.vehicle.model}
      />
      <ProgressItem
        title={i18n.t('car:attributes:body_type')}
        value={values.bodyType}
        icon={icons.vehicle.body}
      />
      <ProgressItem
        title={i18n.t('car:attributes:manufacture_year')}
        value={values.manufactureYear}
        icon={icons.vehicle.manufacture}
      />
      <ProgressItem
        title={i18n.t('car:attributes:chassis_number')}
        value={values.chassis}
        icon={icons.vehicle.chassis.number}
      />
      <ProgressItem
        title={i18n.t('car:attributes:chassis_pic')}
        value={
          _.isEmpty(getDocument(car.chassisImage, 'thumb'))
            ? values.chassisFile.base64Data
            : getDocument(car.chassisImage, 'thumb')
        }
        icon={icons.vehicle.chassis.picture}
      />
      <ProgressItem
        title={i18n.t('car:attributes:thumbnail')}
        value={
          _.isEmpty(carImageBase64(values.images))
            ? carImage(car.carImages, 'thumb')
            : carImageBase64(values.images)
        }
        icon={icons.camera}
      />
      {isProgressComplete(values, car)
        ? <Image src={icons.progress.complete} styles="w-32 h-32" />
        : <Image src={icons.progress.incomplete} styles="w-32 h-32" />}
    </div>
  </div>
);

export default Progress;
