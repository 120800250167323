import { AxiosResponse } from 'axios';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { isValidResponse } from '../../../../api/axios';
import AppLoader from '../../../../components/loaders/AppLoader';
import { paths } from '../../../../lib/routing';
import useNavigation from '../../../../services/hooks/navigation';
import { useStoreActions } from '../../../../store/hooks';
import { emptyCar } from '../../../../store/types/car';
import { emptyEvent } from '../../../../store/types/event';

import Event from './Event';

type RouteParams = {
  carID: string;
}

type Props = {
  service?: boolean;
}

const NewEvent: FunctionComponent<Props> = ({ service = false }: Props) => {
  const { carID } = useParams<RouteParams>();
  const { show } = useStoreActions((actions) => actions.cars);
  const { show: shared } = useStoreActions((actions) => actions.shared);
  const { flash } = useStoreActions((actions) => actions);
  const [isLoading, setIsLoading] = useState(true);
  const [car, setCar] = useState(emptyCar);
  const navigate = useNavigation();

  useEffect(() => {
    (async () => {
      const response: AxiosResponse = service ? await shared(carID) : await show(carID);
      if (isValidResponse(response)) {
        setCar(service ? response.data.car : response.data);
        setIsLoading(false);
        return;
      }
      flash.set({ value: response.data, type: 'error' });
      navigate(paths.home);
    })();
  }, []);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <Event car={car} event={emptyEvent} service={service} />
  );
};

export default NewEvent;
