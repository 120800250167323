import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  children: ReactNode;
}

const Content: FunctionComponent<Props> = ({ children }: Props) => (
  <div className="expand wrap">
    {children}
  </div>
);

export default Content;
