import React, { FunctionComponent, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import { useFormik } from 'formik';

import { useStoreActions } from '../../../store/hooks';
import { isValidResponse } from '../../../api/axios';
import Authenticated from '../../../components/layout/Authenticated';
import icons from '../../../assets/images/icons';
import InfoModal from '../../../components/modals/InfoModal';
import TextContainer from '../../../components/TextContainer';
import EmailInput from '../../../components/forms/EmailInput';
import SubmitButton from '../../../components/buttons/SubmitButton';

import Form from './components/Form';

type Inputs = {
  email: string;
}

const Invitations: FunctionComponent = () => {
  const { t } = useTranslation();
  const { suggest } = useStoreActions((actions) => actions.user);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('errors:email:valid'))
      .required(t('errors:email:required')),
  });

  const handleOnSubmit = async (data: Inputs): Promise<void> => {
    const response = await suggest(data.email);
    if (!isValidResponse(response)) {
      setMessage(response.data);
    }
    setIsOpen(true);
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: { email: '' },
    onSubmit: handleOnSubmit,
    validationSchema: schema,
  });

  return (
    <Authenticated>
      <Form onSubmit={handleSubmit} title={t('authenticated:recomendations')} icon={icons.invitations.blue}>
        <InfoModal
          isOpen={isOpen}
          message={
            _.isEmpty(message)
              ? t('invitations:invitation_success', { email: values.email })
              : message
          }
          onAccept={() => setIsOpen(false)}
        />
        <TextContainer styles="max-w-192 mx-auto w-full my-12">
          {t('invitations:invitation_info')}
        </TextContainer>
        <EmailInput
          name="email"
          value={values.email}
          placeholder={t('session:email')}
          onChange={handleChange('email')}
          onBlur={handleBlur('email')}
          touched={touched.email}
          errors={errors.email}
        />
        <SubmitButton
          title={t('send')}
          disabled={!isValid || isSubmitting}
          styles="mx-auto mt-12"
        />
      </Form>
    </Authenticated>
  );
};

export default Invitations;
