export const routes = {
  root: '/',
  signin: '/sign-in',
  signup: '/sign-up',
  password: {
    new: '/forgot-password',
    edit: '/reset/:email/:token',
  },
  home: '/home',
  subscriptions: '/subscriptions',
  myCars: '/my-cars',
  sharedWithMeCars: '/shared-with-me',
  user: {
    profile: '/profile',
    invitations: '/recomend-the-motor-chain',
    settings: '/settings',
  },
  car: {
    new: '/car/new',
    edit: '/car/:id',
    subscribe: '/car/:id/subscribe',
  },
  mobileSubscribe: '/car/:id/mobile-subscribe',
  paymentCheckout: '/payment-checkout',
  events: {
    new: '/car/:carID/events/new',
    edit: '/car/:carID/events/:id/edit',
    show: '/car/:carID/events/:id',
  },
  sharePanel: '/share-panel/:id',
  sharedCar: '/shared-car/:id',
  sharedCarEvent: '/shared-car/:carID/events/:id',
  snapshot: '/snapshot/:id',
  serviceCar: {
    edit: '/service/:id',
    events: {
      new: '/service/:carID/events/new',
      edit: '/service/:carID/events/:id/edit',
      show: '/service/:carID/events/:id',
    },
  },
  share: {
    snapshot: '/share/:id/snapshot/:token',
    service: '/share/:id/service/:token',
    transfer: '/share/:id/transfer/:token',
  },
};

export const paths = {
  root: '/',
  signin: '/sign-in',
  signup: '/sign-up',
  password: {
    new: '/forgot-password',
    edit: (email: string, token: string): string => `reset/${email}/${token}`,
  },
  home: '/home',
  subscriptions: '/subscriptions',
  myCars: '/my-cars',
  sharedWithMeCars: '/shared-with-me',
  user: {
    profile: '/profile',
    invitations: '/recomend-the-motor-chain',
    settings: '/settings',
  },
  car: {
    new: '/car/new',
    edit: (id: number): string => `/car/${id}`,
    subscribe: (id: number): string => `/car/${id}/subscribe`,
  },
  mobileSubscribe: (id: number): string => `/car/${id}/mobile-subscribe`,
  paymentCheckout: '/payment-checkout',
  events: {
    new: (carID: number): string => `/car/${carID}/events/new`,
    edit: (carID: number, id: number): string => `/car/${carID}/events/${id}/edit`,
    show: (carID: number, id: number): string => `/car/${carID}/events/${id}`,
  },
  sharePanel: (id: number): string => `/share-panel/${id}`,
  sharedCar: (id: number): string => `/shared-car/${id}`,
  sharedCarEvent: (carID: number, id: number): string => `/shared-car/${carID}/events/${id}`,
  snapshot: (id: number): string => `/snapshot/${id}`,
  serviceCar: {
    edit: (id: number): string => `/service/${id}`,
    events: {
      new: (carID: number): string => `/service/${carID}/events/new`,
      edit: (carID: number, id: number): string => `/service/${carID}/events/${id}/edit`,
      show: (carID: number, id: number): string => `/service/${carID}/events/${id}`,
    },
  },
};
