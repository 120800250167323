import React, {
  ChangeEvent, FocusEvent, FunctionComponent, useState,
} from 'react';
import _ from 'lodash/fp';

import { hasErrors, isTouched } from '../../lib/helpers/forms';
import TextContainer from '../TextContainer';

import FormInput from './components/FormInput';
import InputLabel from './components/InputLabel';
import InputError from './components/InputError';
import InputIcon from './components/InputIcon';

export type Props = {
  name: string;
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  hint?: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
  readonly?: boolean;
}

const PasswordInput: FunctionComponent<Props> = (
  {
    name,
    value,
    placeholder,
    onChange,
    hint = '',
    onBlur = undefined,
    touched = false,
    errors = '',
    readonly = false,
  }: Props,
) => {
  const [isSecure, setIsSecure] = useState(true);
  return (
    <FormInput background>
      <InputLabel name={name} placeholder={_.isEmpty(value) ? '' : placeholder} />
      <input
        type={isSecure ? 'password' : 'text'}
        name={name}
        id={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        readOnly={readonly}
      />
      <InputIcon active={isSecure} setActive={(): void => setIsSecure(!isSecure)} />
      {!hasErrors(errors)
        ? (
          <TextContainer
            size="one-line-small"
            color="text-gray-4"
            styles="px-6"
          >
            {hint}
          </TextContainer>
        ) : <InputError errors={hasErrors(errors) && isTouched(touched) ? errors : ''} />}

    </FormInput>
  );
};

export default PasswordInput;
