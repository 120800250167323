import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import Image from './Image';

export type Props = {
  text: string;
  type: 'green' | 'gray' | 'blue' | 'dark-blue' | 'light-blue' | 'red';
  icon?: string;
  action?: () => void;
  styles?: string;
}

const Tag: FunctionComponent<Props> = (
  {
    text,
    type,
    icon = '',
    action,
    styles = '',
  }: Props,
) => (
  <button
    type="button"
    onClick={action}
    disabled={_.isUndefined(action)}
    className={`tag-${type} ${styles} ${_.isUndefined(action) ? 'cursor-default' : ''}`}
  >
    {text}
    {!_.isEmpty(icon) && <Image src={icon} />}
  </button>
);

export default Tag;
