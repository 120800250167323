import { AxiosResponse } from 'axios';

import { RecoveryPasswordParams } from '../../store/types/passwords';
import client, { request } from '../axios';

export const create = async (email: string): Promise<AxiosResponse> => (
  request(client.post(`/passwords/${email.toLowerCase()}`))
);

export const update = async (params: RecoveryPasswordParams): Promise<AxiosResponse> => (
  request(
    client.put(`/passwords/${params.email.toLowerCase()}/${params.token}`, {
      password: params.password,
    }),
  )
);
