import React, { FunctionComponent } from 'react';

import TextContainer from '../../TextContainer';

import Calendly from './Calendly';

type Props = {
  styles?: string;
}

const Footer: FunctionComponent<Props> = ({ styles = '' }: Props) => (
  <footer id="site-footer" className={`${styles}`}>
    <TextContainer size="one-line-x-small" font="montserrat-bold" styles="text-center mb-6">
      Copyright © 2021 The Motor Chain. All rights reserved.
    </TextContainer>
    <a href="https://themotorchain.com" target="_blank" rel="noopener noreferrer" className="block mb-4">
      <TextContainer size="one-line-x-small" font="montserrat-bold" styles="text-center" color="text-primary">
        themotorchain.com
      </TextContainer>
    </a>
    <Calendly />
  </footer>
);

export default Footer;
