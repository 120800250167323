import React, { FunctionComponent, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import { useHistory, useLocation } from 'react-router-dom';
import { useFormik } from 'formik';

import { useStoreActions } from '../../store/hooks';
import useQuery from '../../services/hooks/query';
import { SessionParams } from '../../store/types/session';
import TextContainer from '../../components/TextContainer';
import NavigationLink from '../../components/NavigationLink';
import { paths } from '../../lib/routing';
import SubmitButton from '../../components/buttons/SubmitButton';
import Unauthenticated from '../../components/layout/Unauthenticated';
import EmailInput from '../../components/forms/EmailInput';
import Flash from '../../components/forms/Flash';
import PasswordInput from '../../components/forms/PasswordInput';
import SubmitLoader from '../../components/loaders/SubmitLoader';
import Back from '../../components/layout/components/Back';

import Card from './components/Card';

const Signin: FunctionComponent = () => {
  const { t } = useTranslation();
  const { create } = useStoreActions((actions) => actions.session);
  const [message, setMessage] = useState('');
  const params = useQuery();
  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: paths.root } };

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('errors:email:valid'))
      .required(t('errors:email:required')),
    password: yup.string()
      .required(t('errors:required')),
  });

  const handleOnSubmit = async (data: SessionParams): Promise<void> => {
    const response: string = await create(data);
    if (_.isEmpty(response)) {
      history.replace(from);
      return;
    }
    setMessage(response);
  };

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: {
      email: params.get('email') || '',
      password: params.get('password') || '',
    },
    onSubmit: handleOnSubmit,
    validationSchema: schema,
  });

  return (
    <Unauthenticated>
      {isSubmitting && <SubmitLoader />}
      <Card border>
        <Back url={paths.root} styles="absolute top-0 left-0" />
        <TextContainer
          tag="h1"
          font="montserrat"
          size="one-line-large"
          styles="text-center mb-16"
        >
          {t('signin:title')}
        </TextContainer>
        <form onSubmit={handleSubmit} className="block max-w-full">
          <Flash message={message} />
          <EmailInput
            name="email"
            value={values.email}
            placeholder={t('session:email')}
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            touched={touched.email}
            errors={errors.email}
          />
          <PasswordInput
            name="password"
            value={values.password}
            placeholder={t('session:password')}
            onChange={handleChange('password')}
            onBlur={handleBlur('password')}
            touched={touched.password}
            errors={errors.password}
          />
          <NavigationLink
            to={paths.password.new}
            text={t('signin:forgot_password')}
            styles="block mb-24 text-right max-w-192 mx-auto"
          />
          <SubmitButton
            title={t('unauthenticated:login')}
            disabled={isSubmitting || !isValid}
            styles="mx-auto mb-16"
          />
          <NavigationLink
            to={paths.signup}
            text={t('signin:new_to_tmc')}
            styles="block text-center mb-24 max-w-192 mx-auto"
          />
        </form>
      </Card>
    </Unauthenticated>
  );
};

export default Signin;
