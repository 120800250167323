import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import { EventAttributes } from '../../../../../store/types/event';
import { CategoryFamilyAttributes } from '../../../../../store/types/category';
import TextContainer from '../../../../../components/TextContainer';
import {
  groupSubcategories,
  isFiltered,
  nameWithoutLineFeed,
  getMinorCategories,
  getMajorCategories,
} from '../../../../../lib/helpers/categories';
import i18n from '../../../../../services/i18n';
import Image from '../../../../../components/Image';
import icons from '../../../../../assets/images/icons';
import { setFirstImage } from '../../../../../lib/helpers/event';
import { DocumentURL } from '../../../../../store/types/document';

type EventCategoryProps = {
  eventCategory: CategoryFamilyAttributes;
  filter: string;
}

type ServiceByProps = {
  name: string;
}

type EventImageProps = {
  documents: DocumentURL[];
}

type Props = {
  event: EventAttributes;
  onClickEvent: (event: EventAttributes) => void;
  filter: string;
}

const isCategoryFiltered = (filter: string, category: string): boolean => (
  !_.isEmpty(filter) && !_.isEqual(nameWithoutLineFeed(filter), category)
);

const isSubcategoryFiltered = (filter: string, subcategories: string[]): boolean => (
  !_.isEmpty(filter) && _.lt(_.indexOf(nameWithoutLineFeed(filter))(subcategories), 0)
);

const subcategoriesNames = _.flatMap('name');

const EventCategory: FunctionComponent<EventCategoryProps> = (
  {
    eventCategory,
    filter,
  }: EventCategoryProps,
) => (
  <div key={eventCategory.category.id} className="mb-4">
    <TextContainer
      font="montserrat"
      styles={`major-category ${isCategoryFiltered(filter, eventCategory.category.name) ? 'filtered' : ''}`}
      size="one-line-regular"
    >
      {nameWithoutLineFeed(eventCategory.category.name)}
    </TextContainer>
    <TextContainer
      size="text-small"
      styles={`minor-category ${isSubcategoryFiltered(filter, subcategoriesNames(eventCategory.subcategories)) ? 'filtered' : ''}`}
    >
      {groupSubcategories(eventCategory.subcategories)}
    </TextContainer>
  </div>
);

const ServicedBy: FunctionComponent<ServiceByProps> = ({ name }: ServiceByProps) => (
  <div className="serviced">
    {!_.isEmpty(name) && (
      <>
        <Image src={icons.mechanic} styles="w-12 h-12 mr-2" />
        <TextContainer size="text-small">
          {name}
        </TextContainer>
      </>
    )}
  </div>
);

const EventImage: FunctionComponent<EventImageProps> = ({ documents }: EventImageProps) => (
  <div className="image">
    {(!_.isNil(documents) && !_.isEmpty(setFirstImage(documents))) && (
      <div
        className="has-bg-image w-24 h-24 rounded"
        style={{ backgroundImage: `url(${setFirstImage(documents)})` }}
      />
    )}
  </div>
);

const joinCategories = (event: EventAttributes): string[] => (
  _.concat(
    getMinorCategories(event.eventCategories),
    getMajorCategories(event.eventCategories),
  )
);

const Event: FunctionComponent<Props> = ({ event, onClickEvent, filter }: Props) => (
  <div
    onClick={() => onClickEvent(event)}
    className={`event ${event.isDraft ? 'draft' : ''} ${isFiltered(filter, joinCategories(event)) ? 'filtered' : ''}`}
  >
    {event.isHighlighted && (
      <div className="absolute top-4 w-8 h-8 flex items-center justify-center bg-white rounded-full" style={{ left: '-26px' }}>
        <Image src={icons.star.filled} styles="w-6 h-6" />
      </div>
    )}
    <div className="w-full xs:flex">
      <div className="w-full mb-4 xs:w-1/2 xs:mb-0">
        {_.map((eventCategory: CategoryFamilyAttributes) => (
          <EventCategory
            key={eventCategory.category.id}
            eventCategory={eventCategory}
            filter={filter}
          />
        ))(event.eventCategories)}
        {_.gt(event.mileage, 0) && (
          <TextContainer size="one-line-small" styles="mileage">
            {i18n.t('timeline:mileage', { number: event.mileage })}
          </TextContainer>
        )}
      </div>
      <div className="w-full flex items-center xs:items-start xs:w-1/2">
        <ServicedBy name={event.servicedBy.name} />
        <EventImage documents={event.documents} />
      </div>
    </div>
    {event.isDraft && (
      <TextContainer size="one-line-small" styles="draft-event">
        {i18n.t('timeline:draft_event')}
      </TextContainer>
    )}
  </div>
);

export default Event;
