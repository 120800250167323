import { AxiosResponse } from 'axios';
import {
  Thunk,
  thunk,
} from 'easy-peasy';

import { isValidResponse } from '../../../api/axios';
import { PaymentParams } from '../../types/payments';

export interface PaymentsModel {
  create: Thunk<PaymentsModel, PaymentParams>;
  update: Thunk<PaymentsModel, number>;
  promotionalCode: Thunk<PaymentsModel, string>;
}

const paymentsModel: PaymentsModel = {
  create: thunk(async (_actions, params, { injections }) => {
    const { paymentsService } = injections;
    return paymentsService.create(params);
  }),

  update: thunk(async (_actions, params, { injections }) => {
    const { paymentsService } = injections;
    const response: AxiosResponse = await paymentsService.update(params);
    if (isValidResponse(response)) {
      return '';
    }

    return response.data;
  }),

  promotionalCode: thunk(async (_actions, params, { injections }) => {
    const { paymentsService } = injections;
    return paymentsService.index(params);
  }),
};

export default paymentsModel;
