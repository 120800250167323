import React, { FunctionComponent } from 'react';

type Props = {
  type: 'red' | 'yellow' | 'green';
  styles?: string;
  size?: 2 | 4 | 6;
}

const Badge: FunctionComponent<Props> = ({ type, styles = '', size = 4 }: Props) => (
  <div className={`bg-${type} w-${size} h-${size} rounded-full ${styles}`} />
);

export default Badge;
