import React, { FunctionComponent } from 'react';

type Props = {
  name: string;
  placeholder: string;
}

const InputLabel: FunctionComponent<Props> = ({ name, placeholder }: Props) => (
  <label htmlFor={name}>{placeholder}</label>
);

export default InputLabel;
