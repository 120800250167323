import React, { FunctionComponent } from 'react';

import TextContainer from '../../TextContainer';

type Props = {
  errors: string;
}

const InputError: FunctionComponent<Props> = ({ errors }: Props) => (
  <TextContainer
    size="one-line-small"
    color="text-red"
    styles="px-6"
  >
    {errors}
  </TextContainer>
);

export default InputError;
