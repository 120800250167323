import React, { FunctionComponent, ReactNode } from 'react';
import _ from 'lodash/fp';

import Badge from '../../../../../components/Badge';
import Image from '../../../../../components/Image';

export type Props = {
  value?: string;
  type?: 'red' | 'yellow';
  icon?: string;
  children: ReactNode;
}

const VehicleInput: FunctionComponent<Props> = (
  {
    children,
    value = '',
    type = 'red',
    icon = '',
  }: Props,
) => (
  <div className="form-vehicle-input">
    <div className="vehicle-icon">
      {!_.isEmpty(icon) && (
        <>
          {_.isEmpty(value) && (
            <Badge type={type} styles="mr-4" />
          )}
          <Image src={icon} styles="w-24 h-24" />
        </>
      )}
    </div>
    {children}
  </div>
);

export default VehicleInput;
