import _ from 'lodash/fp';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import icons from '../../../assets/images/icons';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import InfoModal from '../../../components/modals/InfoModal';
import Tag from '../../../components/Tag';
import TextContainer from '../../../components/TextContainer';
import { carImage, carTitle } from '../../../lib/helpers/car';
import { paths } from '../../../lib/routing';
import date from '../../../services/date';
import useQuery from '../../../services/hooks/query';
import i18n from '../../../services/i18n';
import { useStoreActions } from '../../../store/hooks';
import { CarAttributes } from '../../../store/types/car';

type Props = {
  car: CarAttributes;
}

const Subscribed: FunctionComponent<Props> = ({ car }: Props) => {
  const { update } = useStoreActions((actions) => actions.payments);
  const { index } = useStoreActions((actions) => actions.cars);
  const { t } = useTranslation();
  const [confirmModal, setConfirmModal] = useState(false);
  const [message, setMessage] = useState('');
  const canceled = car.subscriptionCanceled;

  const closeModal = (): void => setConfirmModal(false);
  const openModal = (): void => setConfirmModal(true);

  const handleOnUpdate = async (): Promise<void> => {
    const response: string = await update(car.id);
    if (_.isEmpty(response)) {
      await index();
      closeModal();
      return;
    }
    setMessage(response);
    closeModal();
  };

  return (
    <>
      <div className="flex items-stretch w-full max-w-256 h-64 sm:h-48 rounded-md shadow-2xl mb-12 lg:mr-12">
        <div
          className="has-bg-image rounded-md h-64 sm:h-48 w-48 min-w-48"
          style={{ backgroundImage: `url(${carImage(car.carImages, 'large')})` }}
        />
        <div className="expand py-8 px-6 justify-between">
          <TextContainer tag="h5" font="montserrat">
            {carTitle(car)}
          </TextContainer>
          <div className="flex flex-col sm:flex-row items-center justify-between">
            <Tag
              text={canceled ? t('subscriptions:canceled') : t('subscriptions:subscribed', { date: date.format(car.endSubscription, 'DD-MM-YYYY') })}
              type={canceled ? 'gray' : 'green'}
              icon={canceled ? undefined : icons.verified.white}
              styles="mb-2 sm:mb-0"
            />
            <Tag
              text={canceled ? t('subscriptions:activate') : t('subscriptions:cancel')}
              type={canceled ? 'green' : 'red'}
              action={openModal}
            />
          </div>
        </div>
      </div>
      <ConfirmModal
        isOpen={confirmModal}
        message={canceled ? t('subscriptions:active_advise') : t('subscriptions:cancel_advise')}
        onAccept={(): Promise<void> => handleOnUpdate()}
        onReject={closeModal}
        alert
      />
      <InfoModal
        isOpen={!_.isEmpty(message)}
        message={message}
        onAccept={(): void => setMessage('')}
      />
    </>
  );
};

const OldSubscription: FunctionComponent<Props> = ({ car }: Props) => (
  <div className="flex items-stretch w-full max-w-256 h-48 rounded-md shadow-2xl mb-12 lg:mr-12">
    <div
      className="has-bg-image rounded-md h-48 w-48 min-w-48"
      style={{ backgroundImage: `url(${carImage(car.carImages, 'large')})` }}
    />
    <div className="expand py-8 px-6 justify-between">
      <TextContainer tag="h5" font="montserrat" styles="mb-2">
        {carTitle(car)}
      </TextContainer>
      <TextContainer size="text-small">
        {i18n.t('subscriptions:old', { date: date.format(car.endSubscription, 'DD-MM-YYYY') })}
      </TextContainer>
    </div>
  </div>
);

const SubscriptionCard: FunctionComponent<Props> = ({ car }: Props) => {
  const { t } = useTranslation();
  const endDateAfterToday = date.isAfter(date.toDate(car.endSubscription));
  const query = useQuery();
  const token: string = query.get('token') || '';
  if (car.subscribed && endDateAfterToday) {
    return <Subscribed car={car} />;
  }

  if (!car.subscribed && endDateAfterToday) {
    return <OldSubscription car={car} />;
  }

  const setSubsPath = (): string => {
    if (!_.isEmpty(token)) {
      if (!_.isEmpty(car.coupon)) {
        return `${paths.mobileSubscribe(car.id)}?coupon=${car.coupon}`;
      }

      return `${paths.mobileSubscribe(car.id)}`;
    }

    if (_.isEmpty(car.coupon)) {
      return `${paths.car.subscribe(car.id)}?from=subscriptions`;
    }

    return `${paths.car.subscribe(car.id)}?coupon=${car.coupon}&from=subscriptions`;
  };

  return (
    <Link to={setSubsPath()} className="flex items-stretch w-full max-w-256 h-48 rounded-md shadow-2xl mb-12 lg:mr-12">
      <div
        className="has-bg-image rounded-md h-48 w-48 min-w-48"
        style={{ backgroundImage: `url(${carImage(car.carImages, 'large')})` }}
      />
      <div className="expand py-8 px-6 justify-between">
        <TextContainer tag="h5" font="montserrat" styles="mb-2">
          {carTitle(car)}
        </TextContainer>
        <div className="flex items-center justify-between">
          <TextContainer size="one-line-small" color="text-gray-2">
            {t('subscriptions:unsubscribed')}
          </TextContainer>
          <div className="flex items-center">
            <TextContainer size="one-line-small">
              {t('subscriptions:subscribe')}
            </TextContainer>
            <img src={icons.arrow.right} className="h-6 ml-4 hidden xs:block" alt={t('icon')} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SubscriptionCard;
