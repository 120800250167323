import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import _ from 'lodash/fp';

import { Page } from '../../../../../store/types/car';
import i18n from '../../../../../services/i18n';

import Tab from './components/Tab';

type Props = {
  current: Page;
  setCurrent: Dispatch<SetStateAction<Page>>;
  isComplete: boolean;
}

const isActive = (page: Page, current: Page): boolean => _.isEqual(page, current);

const CarTabs: FunctionComponent<Props> = ({ current, setCurrent, isComplete }: Props) => (
  <div className="w-full">
    <div className="wrap flex justify-around border-b border-primary-light">
      <Tab
        title={i18n.t('car:timeline')}
        onClick={(): void => setCurrent('timeline')}
        isActive={isActive('timeline', current)}
        isComplete={isComplete}
      />
      <Tab
        title={i18n.t('car:vehicle_data')}
        onClick={(): void => setCurrent('vehicle-data')}
        isActive={isActive('vehicle-data', current)}
      />
      <Tab
        title={i18n.t('car:snapshot')}
        onClick={(): void => setCurrent('snapshot')}
        isActive={isActive('snapshot', current)}
        isComplete={isComplete}
      />
    </div>
  </div>

);

export default CarTabs;
