import Cookies from 'universal-cookie';
import _ from 'lodash/fp';

import date from '../date';

export const INFINITE_DATE = new Date(2100, 11, 31);

export const saveToken = (token: string): void => {
  const cookies = new Cookies();
  cookies.set('token', token, {
    expires: INFINITE_DATE,
    sameSite: true,
    path: '/',
  });
};

export const getToken = (): string => {
  const cookies = new Cookies();

  return cookies.get('token');
};

export const removeToken = (): void => {
  const cookies = new Cookies();
  cookies.remove('token', { path: '/', sameSite: true });
};

export const getLanguage = (): string => {
  const cookies = new Cookies();
  const lang = cookies.get('lang');

  if (_.isEmpty(lang)) {
    return 'en-GB';
  }

  return lang;
};

export const setCalendlyCookie = (): void => {
  const cookies = new Cookies();
  cookies.set('calendly', false, {
    expires: date.add7Days(new Date()),
    sameSite: true,
    path: '/',
  });
};

export const getCalendlyCookie = (): string => {
  const cookies = new Cookies();
  return cookies.get('calendly');
};
