import carsModel, { CarsModel } from './cars/cars';
import passwordsModel, { PasswordsModel } from './passwords/passwords';
import sessionModel, { SessionModel } from './session/session';
import userModel, { UserModel } from './user/user';
import eventsModel, { EventsModel } from './events/events';
import settingsModel, { SettingsModel } from './settings/settings';
import categoriesModel, { CategoriesModel } from './categories/categories';
import documentsModel, { DocumentsModel } from './documents/documents';
import sharedModel, { SharedModel } from './shared/shared';
import viewersModel, { ViewersModel } from './viewers/viewers';
import paymentsModel, { PaymentsModel } from './payments/payments';
import flashModel, { FlashModel } from './flash/flash';

export interface StoreModel {
  session: SessionModel;
  user: UserModel;
  settings: SettingsModel;
  passwords: PasswordsModel;
  cars: CarsModel;
  events: EventsModel;
  flash: FlashModel;
  categories: CategoriesModel;
  documents: DocumentsModel;
  shared: SharedModel;
  viewers: ViewersModel;
  payments: PaymentsModel;
}

const storeModel: StoreModel = {
  session: sessionModel,
  user: userModel,
  settings: settingsModel,
  passwords: passwordsModel,
  cars: carsModel,
  events: eventsModel,
  flash: flashModel,
  categories: categoriesModel,
  documents: documentsModel,
  shared: sharedModel,
  viewers: viewersModel,
  payments: paymentsModel,
};

export default storeModel;
