import {
  Thunk, thunk,
} from 'easy-peasy';

import { SettingsAttributes } from '../../types/settings';

export interface SettingsModel {
  update: Thunk<SettingsModel, SettingsAttributes>;
}

const settingsModel: SettingsModel = {
  update: thunk(async (actions, params, { injections, getStoreActions }) => {
    const { settingsService } = injections;
    const response = await settingsService.update(params);
    // @ts-ignore
    getStoreActions().user.init(response.data);
    return response;
  }),
};

export default settingsModel;
