import { AxiosResponse } from 'axios';

import { EventParams, EventURLIDs } from '../../store/types/event';
import client, { request } from '../axios';

export const index = async (carID: number): Promise<AxiosResponse> => (
  request(client.get(`/cars/${carID}/events`))
);

export const show = async (params: EventURLIDs): Promise<AxiosResponse> => (
  request(client.get(`/cars/${params.carID}/events/${params.id}`))
);

export const create = async (params: EventParams): Promise<AxiosResponse> => (
  request(client.post(`/cars/${params.carID}/events`, { ...params }))
);

export const update = async (params: EventParams): Promise<AxiosResponse> => (
  request(client.put(`/cars/${params.carID}/events/${params.id}`, { ...params }))
);

export const destroy = async (params: EventURLIDs): Promise<AxiosResponse> => (
  request(client.delete(`/cars/${params.carID}/events/${params.id}`))
);
