import addCircleBlue from './icons/add-circle-blue.svg';
import addCircleEmpty from './icons/add-circle-empty.svg';
import addCircleWhite from './icons/add-circle-white.svg';
import angleDownBlue from './icons/angle-down-dark-blue.svg';
import angleDownWhite from './icons/angle-down-white.svg';
import angleRightBlue from './icons/angle-right-blue.svg';
import angleUpWhite from './icons/angle-up-white.svg';
import arrowLeft from './icons/arrow-left.svg';
import arrowRight from './icons/arrow-right.svg';
import camera from './icons/camera.png';
import carProgressComplete from './icons/car-progress-complete.svg';
import carProgressIncomplete from './icons/car-progress-incomplete.svg';
import carProgress from './icons/car-progress.svg';
import cogBlue from './icons/cog-dark-blue.svg';
import cogWhite from './icons/cog-white.svg';
import crossBlue from './icons/cross-blue.svg';
import crossWhite from './icons/cross-white.svg';
import expandBlue from './icons/expand-blue.svg';
import eyeSlash from './icons/eye-slash.svg';
import eye from './icons/eye.svg';
import forbidenDark from './icons/forbiden-dark.svg';
import forbidenLight from './icons/forbiden-light.svg';
import glass from './icons/glass.svg';
import invitationsBlue from './icons/invitations.png';
import invitationsWhite from './icons/invitations-white.svg';
import logout from './icons/logout-white.svg';
import mechanic from './icons/mechanic.svg';
import menu from './icons/menu.svg';
import myCars from './icons/my-cars.png';
import payments from './icons/payments.svg';
import pdf from './icons/pdf.svg';
import pencil from './icons/pencil.svg';
import picture from './icons/picture.svg';
import serviceCar from './icons/service-car.svg';
import share from './icons/share.svg';
import sharedByMe from './icons/shared-by-me.png';
import sharedWithMe from './icons/shared-with-me.png';
import starFilled from './icons/star-filled.svg';
import starUnfilledLightBlue from './icons/star-unfilled-blue-light.svg';
import starUnfilled from './icons/star-unfilled.svg';
import timelineAssistantDarkBlue from './icons/timeline-assistant-dark-blue.svg';
import timelineAssistantWhite from './icons/timeline-assistant-white.svg';
import userCircle from './icons/user-circle.svg';
import userDarkBlue from './icons/user-dark-blue.svg';
import userWhite from './icons/user-white.svg';
import carBody from './icons/vehicle-body-type.png';
import carChassisPic from './icons/vehicle-chassis-picture.png';
import carChassis from './icons/vehicle-chassis.png';
import carEngine from './icons/vehicle-engine.png';
import carGear from './icons/vehicle-gearbox.png';
import carMake from './icons/vehicle-make.png';
import carManufacture from './icons/vehicle-manufacture.png';
import carModel from './icons/vehicle-model.png';
import verifiedBlue from './icons/verified-blue.svg';
import verifiedWhite from './icons/verified-white.svg';
import viewCar from './icons/view-car.svg';
import viewersLarge from './icons/viewers-large.svg';
import viewersSmall from './icons/viewers-small.svg';
import bubble from './icons/bubble.svg';
import credit from './icons/credit-card.svg';
import creditBlue from './icons/credit-card-blue.svg';

const icons = {
  add: {
    blue: addCircleBlue,
    empty: addCircleEmpty,
    white: addCircleWhite,
  },
  angleDown: {
    blue: angleDownBlue,
    white: angleDownWhite,
  },
  angleRight: {
    blue: angleRightBlue,
  },
  angleUp: {
    white: angleUpWhite,
  },
  arrow: {
    left: arrowLeft,
    right: arrowRight,
  },
  camera,
  progress: {
    initial: carProgress,
    complete: carProgressComplete,
    incomplete: carProgressIncomplete,
  },
  cog: {
    blue: cogBlue,
    white: cogWhite,
  },
  cross: {
    blue: crossBlue,
    white: crossWhite,
  },
  expand: {
    blue: expandBlue,
  },
  eye: {
    regular: eye,
    slash: eyeSlash,
  },
  forbiden: {
    active: forbidenDark,
    inactive: forbidenLight,
  },
  glass,
  invitations: {
    blue: invitationsBlue,
    white: invitationsWhite,
  },
  logout,
  mechanic,
  menu,
  myCars,
  payments,
  pdf,
  pencil,
  picture,
  serviceCar,
  share,
  sharedByMe,
  sharedWithMe,
  star: {
    filled: starFilled,
    unfilled: starUnfilled,
    light: starUnfilledLightBlue,
  },
  assistant: {
    blue: timelineAssistantDarkBlue,
    white: timelineAssistantWhite,
  },
  user: {
    circle: userCircle,
    blue: userDarkBlue,
    white: userWhite,
  },
  vehicle: {
    make: carMake,
    model: carModel,
    body: carBody,
    manufacture: carManufacture,
    chassis: {
      number: carChassis,
      picture: carChassisPic,
    },
    engine: carEngine,
    gearbox: carGear,
  },
  verified: {
    blue: verifiedBlue,
    white: verifiedWhite,
  },
  viewCar,
  viewers: {
    small: viewersSmall,
    large: viewersLarge,
  },
  credit,
  bubble,
  creditBlue,
};

export default icons;
