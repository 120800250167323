import { DocumentBase64, DocumentURL } from './document';
import emptySettings, { SettingsAttributes } from './settings';

export type AccountDelete = {
  password: string;
}

export type NewUserParams = {
  email: string;
  password: string;
};

type UserBase = {
  email: string;
  name: string;
  country: string;
  city: string;
  verifiedDate: string;
}

export type UserParams = UserBase & {
  document: DocumentBase64;
}

export type UserAttributes = UserBase & {
  settings: SettingsAttributes;
  document: DocumentURL;
}

export const emptyUser: UserAttributes = {
  email: '',
  name: '',
  country: '',
  city: '',
  verifiedDate: '',
  settings: emptySettings,
  document: {
    id: -1,
    thumb: '',
    medium: '',
    large: '',
    full: '',
  },
};
