import _ from 'lodash/fp';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import icons from '../../../assets/images/icons';
import Authenticated from '../../../components/layout/Authenticated';
import Back from '../../../components/layout/components/Back';
import AppLoader from '../../../components/loaders/AppLoader';
import TextContainer from '../../../components/TextContainer';
import { isCarComplete } from '../../../lib/helpers/car';
import { hasTransferPending } from '../../../lib/helpers/viewers';
import { paths } from '../../../lib/routing';
import useQuery from '../../../services/hooks/query';
import { useStoreActions, useStoreState } from '../../../store/hooks';
import { CarAttributes } from '../../../store/types/car';

import SubscriptionCard from './SubscriptionCard';

const Subscriptions: FunctionComponent = () => {
  const { portal } = useStoreActions((actions) => actions.user);
  const { index } = useStoreActions((actions) => actions.cars);
  const { cars } = useStoreState((state) => state.cars);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const [portalURI, setPortalURI] = useState('');
  const query = useQuery();
  const token: string = query.get('token') || '';

  useEffect(() => index().then((result: boolean) => setIsLoading(!result)), []);
  useEffect(() => portal().then((result: string) => setPortalURI(result)), []);

  if (isLoading) return <AppLoader />;

  const carsForSub = _.filter(
    (car: CarAttributes) => isCarComplete(car) && !hasTransferPending(car.viewers),
  )(cars);

  return (
    <Authenticated>
      <div className="py-8 md:flex items-center justify-between">
        <div className="py-8 sm:flex items-center">
          {_.isEmpty(token) && (
            <Back url={paths.home} styles="mr-4" />
          )}
          <TextContainer tag="h1" size="one-line-large" font="montserrat-bold" styles="uppercase flex items-center">
            <img src={icons.creditBlue} className="h-16 mr-4 hidden sm:block" alt={t('icon')} />
            {t('subscriptions:subscriptions')}
          </TextContainer>
        </div>
        {!_.isEmpty(portalURI) && (
          <a href={portalURI} className="btn-outline btn-large">
            {t('subscriptions:billing_portal')}
          </a>
        )}
      </div>
      <div className="flex flex-wrap justify-center md:justify-start">
        {_.map((car: CarAttributes) => (
          <SubscriptionCard key={car.id} car={car} />
        ))(carsForSub)}
      </div>
    </Authenticated>
  );
};

export default Subscriptions;
