import React, { FunctionComponent } from 'react';

import icons from '../../../../assets/images/icons';
import Badge from '../../../../components/Badge';
import Image from '../../../../components/Image';
import TextContainer from '../../../../components/TextContainer';
import i18n from '../../../../services/i18n';

type Props = {
  viewersNumber: number;
  hasRequests: boolean;
  pendingRequest: number;
}

const RequestInfo: FunctionComponent<Props> = (
  {
    viewersNumber,
    hasRequests,
    pendingRequest,
  }: Props,
) => (
  <div className="flex items-center">
    <Image src={icons.viewers.large} styles="w-24 h24 mr-2" />
    <TextContainer font="montserrat" styles="relative text-center w-16">
      {viewersNumber}
      <span className="absolute w-full top-full left-0 bg-primary h-2" />
    </TextContainer>
    {hasRequests && (
      <div className="flex items-center">
        <div className="w-32 h-1 bg-primary-light" />
        <Badge type="red" styles="mr-4" />
        <TextContainer font="montserrat" color="text-red">
          {i18n.t('share:requests', { number: pendingRequest })}
        </TextContainer>
      </div>
    )}
  </div>
);

export default RequestInfo;
