import React, { FunctionComponent, ChangeEvent } from 'react';

import icons from '../../../../../../assets/images/icons';
import FormInput from '../../../../../../components/forms/components/FormInput';
import Image from '../../../../../../components/Image';
import TextContainer from '../../../../../../components/TextContainer';
import i18n from '../../../../../../services/i18n';

type Props = {
  name: string;
  value: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const HighlightInput: FunctionComponent<Props> = (
  {
    name,
    value,
    onChange,
  }: Props,
) => (
  <FormInput styles="event-input highlight">
    <div className="mb-6">
      <label htmlFor={name} className="flex items-center cursor-pointer">
        {i18n.t('add_event:highlight_event')}
        <Image src={value ? icons.star.filled : icons.star.unfilled} />
      </label>
    </div>
    <TextContainer color="text-gray-3" size="text-small">
      {i18n.t('add_event:hightlight_description')}
    </TextContainer>
    <input
      type="checkbox"
      name={name}
      id={name}
      checked={value}
      onChange={onChange}
      className="hidden"
    />
  </FormInput>
);

export default HighlightInput;
