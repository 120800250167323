import _ from 'lodash/fp';

import date from '../../services/date';
import { CarAttributes, CarParams } from '../../store/types/car';
import { DocumentBase64, DocumentSize, DocumentURL } from '../../store/types/document';

import { getBase64ImageSRC, getDocument } from './documents';

const TOTAL_PROGRESS_FIELDS = 7;

const COMPLETE_CAR_PROGRESS = 1;

export const mandatoryFields = [
  'carMake',
  'modelName',
  'bodyType',
  'manufactureYear',
  'chassis',
];

const extraDetails = [
  'intro',
  'nickName',
  'licensePlate',
  'color',
  'seatsColor',
  'upholsteryColor',
  'dashboard',
  'firstRegistration',
];

export const carParamsFields = [
  ...mandatoryFields,
  'engine',
  'gearbox',
  ...extraDetails,
];

const calculateCarProgress = _.flow(
  _.valuesIn,
  _.compact,
  _.size,
  // @ts-ignore
  _.divide(_, TOTAL_PROGRESS_FIELDS),
);

export function hasCarImage<Type>(documents: Type[]): boolean {
  return !_.isEmpty(documents);
}

export const carImage = (carImages: DocumentURL[], size: DocumentSize): string => (
  hasCarImage(carImages) ? getDocument(carImages[0], size) : ''
);

export const carImageBase64 = (carImages: DocumentBase64[]): string => (
  hasCarImage(carImages) ? getBase64ImageSRC(carImages[0]) : ''
);

const isComplete = (car: CarAttributes) => (
  _.isEqual(
    calculateCarProgress({
      ..._.pick(mandatoryFields)(car),
      chassisFile: car.chassisImage.full,
      carImage: carImage(car.carImages, 'thumb'),
    }), COMPLETE_CAR_PROGRESS,
  )
);

const checkDocument = (data: string, url: string): string => (
  _.isEmpty(data) ? url : data
);

export const isProgressComplete = (data: CarParams, car: CarAttributes): boolean => (
  _.isEqual(
    calculateCarProgress({
      ..._.pick(mandatoryFields)(data),
      chassisFile: checkDocument(data.chassisFile.base64Data, car.chassisImage.full),
      carImage: checkDocument(carImageBase64(data.images), carImage(car.carImages, 'thumb')),
    }), COMPLETE_CAR_PROGRESS,
  )
);

export const carName = (car: CarAttributes, max = 57): string => _.truncate({ length: max, omission: '...' })(`${car.carMake} ${car.modelName}`);

export const carTitle = (car: CarAttributes, max = 57): string => (
  _.isEmpty(car.nickName) ? carName(car, max) : _.truncate({ length: max, omission: '...' })(car.nickName)
);

export const isCarComplete = (car: CarAttributes): boolean => (
  _.lt(car.id, 0) ? false : isComplete(car)
);

export const isCarProgressComplete = (data: CarParams, car: CarAttributes): boolean => (
  isCarComplete(car) || isProgressComplete(data, car)
);

export const extraDetailsEntries = _.flow(
  _.pick(extraDetails),
  _.valuesIn,
  _.compact,
  _.size,
);

export const isNewCar = (id: number): boolean => (
  _.lt(id, 0)
);

export const minimumCarRegistration = (value: string, current: string): Date | string => {
  if (!_.isEmpty(value)) return value;

  if (!date.isNullDate(current)) return current;

  return new Date(1900, 0, 1);
};
