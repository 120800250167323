import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import Badge from '../../../../../../components/Badge';
import Image from '../../../../../../components/Image';

type Props = {
  icon: string;
  value: string;
  title: string;
}

const ProgressItem: FunctionComponent<Props> = ({ icon, value, title }: Props) => (
  <div className="w-64 mb-12">
    <Image src={icon} alt={title} styles="w-32 mx-auto" />
    <div className="min-h-16 flex items-center justify-center">
      {_.isEmpty(value) && <Badge type="red" />}
    </div>
    <div
      title={title}
      className={`w-full h-2 ${_.isEmpty(value) ? 'bg-primary-x-light' : 'bg-green'}`}
    />
  </div>
);

export default ProgressItem;
