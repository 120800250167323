import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash/fp';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import Authenticated from '../../../components/layout/Authenticated';
import { useStoreActions } from '../../../store/hooks';
import { emptyCar } from '../../../store/types/car';
import { isValidResponse } from '../../../api/axios';
import useNavigation from '../../../services/hooks/navigation';
import { paths } from '../../../lib/routing';
import PageHeader from '../components/PageHeader';
import CarPicture from '../../../components/CarPicture';
import { carImage } from '../../../lib/helpers/car';
import icons from '../../../assets/images/icons';
import TextContainer from '../../../components/TextContainer';
import {
  hasPendingRequests,
  hasTransferPending,
  requestPending,
  viewersNumber,
} from '../../../lib/helpers/viewers';
import { ViewerAttributes } from '../../../store/types/viewers';
import UnautorizedModal from '../../../components/modals/UnauthorizedModal';
import PasswordInput from '../../../components/forms/PasswordInput';
import SubmitButton from '../../../components/buttons/SubmitButton';
import Back from '../../../components/layout/components/Back';

import Section from './components/Section';
import SectionBlock from './components/SectionBlock';
import RequestPanel from './components/RequestPanel';
import RequestInfo from './components/RequestInfo';
import SectionTitle from './components/SectionTitle';
import UserCard from './components/UserCard';

type RouteParams = {
  id: string;
}

const viewersPending = (viewers: ViewerAttributes[]): ViewerAttributes[] => (
  _.filter(
    (viewer: ViewerAttributes) => (
      _.isEqual(viewer.accessLevel, 'SNAPSHOT') && viewer.pending
    ),
  )(viewers)
);

// @ts-ignore
const snapshotPending = (viewers: ViewerAttributes[]): boolean => _.flow(
  viewersPending,
  _.size,
  // @ts-ignore
  _.gt(_, 0),
)(viewers);

const viewersFullAccess = (viewers: ViewerAttributes[]): ViewerAttributes[] => (
  _.filter(
    (viewer: ViewerAttributes) => (
      _.isEqual(viewer.accessLevel, 'FULL') && !viewer.pending
    ),
  )(viewers)
);

const hasViewers = _.flow(
  viewersFullAccess,
  _.size,
  // @ts-ignore
  _.gt(_, 0),
);

const viewersService = (viewers: ViewerAttributes[]): ViewerAttributes[] => (
  _.filter(
    (viewer: ViewerAttributes) => (
      _.isEqual(viewer.accessLevel, 'GARAGE') && !viewer.pending
    ),
  )(viewers)
);

const hasService = _.flow(
  viewersService,
  _.size,
  // @ts-ignore
  _.gt(_, 0),
);

const viewersTransfer = (viewers: ViewerAttributes[]): ViewerAttributes[] => (
  _.filter(
    (viewer: ViewerAttributes) => (
      _.isEqual(viewer.accessLevel, 'TRANSFER') && viewer.pending
    ),
  )(viewers)
);

type TransferParams = {
  password: string;
}

const SharePanel: FunctionComponent = () => {
  const { t } = useTranslation();
  const { id } = useParams<RouteParams>();
  const { show } = useStoreActions((actions) => actions.cars);
  const { update, destroy, transfer } = useStoreActions((actions) => actions.viewers);
  const { flash } = useStoreActions((actions) => actions);
  const [isLoading, setIsLoading] = useState(true);
  const [car, setCar] = useState(emptyCar);
  const [message, setMessage] = useState('');
  const [transferID, setTransferID] = useState(-1);
  const [isTransfer, setIsTransfer] = useState(false);
  const navigate = useNavigation();

  const handleResponse = (response: AxiosResponse): void => {
    if (isValidResponse(response)) {
      setCar(response.data);
      setIsLoading(false);
      return;
    }
    flash.set({ value: response.data, type: 'error' });
    navigate(paths.home);
  };

  useEffect(() => {
    show(id).then(handleResponse);
  }, []);

  const onAccept = (viewerID: number): Promise<void> => (
    update({ carID: car.id, id: viewerID }).then(handleResponse)
  );

  const onReject = (viewerID: number): Promise<void> => (
    destroy({ carID: car.id, id: viewerID }).then(handleResponse)
  );

  const onTransfer = (viewerID: number): void => {
    setTransferID(viewerID);
    setIsTransfer(true);
  };

  if (_.isEmpty(car.viewers) && !isLoading) {
    navigate('/');
  }

  const handleOnSubmit = async (data: TransferParams): Promise<void> => {
    transfer({ carID: car.id, id: transferID, password: data.password })
      .then((response: AxiosResponse) => {
        if (isValidResponse(response)) {
          navigate(paths.home);
          return;
        }
        setMessage(response.data);
      });
  };

  const schema = yup.object().shape({
    password: yup.string()
      .required(t('errors:required')),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    onSubmit: handleOnSubmit,
    validationSchema: schema,
  });

  return (
    <Authenticated title={<PageHeader title={t('share_panel:title')} graphic="car" />}>
      <div className="expand mt-24 share-panel">
        <Back url={paths.home} />
        <div className="expand share">
          <UnautorizedModal isOpen={isTransfer} onClose={() => setIsTransfer(false)}>
            <form onSubmit={formik.handleSubmit} className="block p-16 w-full max-w-full">
              <TextContainer font="montserrat-bold" size="text-medium" styles="mb-6">
                {t('share_panel:transfer_car_info')}
              </TextContainer>
              <TextContainer font="montserrat" size="text-small" styles="mb-6" color="text-red">
                {t('share_panel:transfer_disclaimer')}
              </TextContainer>
              <PasswordInput
                name="password"
                value={formik.values.password}
                placeholder={t('session:password')}
                onChange={formik.handleChange('password')}
                onBlur={formik.handleBlur('password')}
                touched={formik.touched.password}
                errors={formik.errors.password}
              />
              {!_.isEmpty(message) && (
                <TextContainer color="text-red" styles="my-4">
                  {message}
                </TextContainer>
              )}
              <SubmitButton
                title={t('share_panel:transfer_car')}
              />
            </form>
          </UnautorizedModal>
          <Section>
            <SectionBlock border center>
              <CarPicture src={carImage(car.carImages, 'large')} />
            </SectionBlock>
            <SectionBlock center>
              <RequestPanel>
                {hasTransferPending(car.viewers) && (
                  <div className="w-full py-8">
                    <TextContainer size="one-line-small" styles="mb-4">
                      {t('share:transfer_pending')}
                    </TextContainer>
                    {_.map((viewer: ViewerAttributes) => (
                      <UserCard
                        key={viewer.id}
                        viewer={viewer}
                        onAccept={() => onTransfer(viewer.id)}
                        onReject={() => onReject(viewer.id)}
                      />
                    ))(viewersTransfer(car.viewers))}
                  </div>
                )}
                <RequestInfo
                  viewersNumber={viewersNumber(car.viewers)}
                  hasRequests={hasPendingRequests(car.viewers)}
                  pendingRequest={requestPending(car.viewers)}
                />
              </RequestPanel>
            </SectionBlock>
          </Section>
          <Section>
            <SectionBlock border>
              <SectionTitle
                title={t('share_panel:view_car')}
                icon={icons.viewCar}
              />
              {snapshotPending(car.viewers) && (
                <div className="w-full max-w-9/10 py-8">
                  <TextContainer size="one-line-small" styles="mb-4">
                    {t('share_panel:request_pending')}
                  </TextContainer>
                  {_.map((viewer: ViewerAttributes) => (
                    <UserCard
                      key={viewer.id}
                      viewer={viewer}
                      onAccept={() => onAccept(viewer.id)}
                      onReject={() => onReject(viewer.id)}
                    />
                  ))(viewersPending(car.viewers))}
                </div>
              )}
              {hasViewers(car.viewers) && (
                <div className="w-full max-w-9/10 py-8">
                  <TextContainer size="one-line-small" styles="mb-4">
                    {t('share_panel:request_accepted')}
                  </TextContainer>
                  {_.map((viewer: ViewerAttributes) => (
                    <UserCard
                      key={viewer.id}
                      viewer={viewer}
                      onAccept={() => onAccept(viewer.id)}
                      onReject={() => onReject(viewer.id)}
                    />
                  ))(viewersFullAccess(car.viewers))}
                </div>
              )}
            </SectionBlock>
            <SectionBlock>
              <SectionTitle
                title={t('share_panel:service_car')}
                icon={icons.serviceCar}
              />
              {hasService(car.viewers) && (
                <div className="w-full max-w-9/10 py-8">
                  <TextContainer size="one-line-small" styles="mb-4">
                    {t('share_panel:request_accepted')}
                  </TextContainer>
                  {_.map((viewer: ViewerAttributes) => (
                    <UserCard
                      key={viewer.id}
                      viewer={viewer}
                      onAccept={() => onAccept(viewer.id)}
                      onReject={() => onReject(viewer.id)}
                    />
                  ))(viewersService(car.viewers))}
                </div>
              )}
            </SectionBlock>
          </Section>
        </div>
      </div>
    </Authenticated>
  );
};

export default SharePanel;
