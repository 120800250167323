import React, { FunctionComponent } from 'react';

import i18n from '../../../services/i18n';
import Checkbox from '../../forms/Checkbox';
import TextContainer from '../../TextContainer';

type Props = {
  isCheck: boolean;
  onChangeCheck: () => void;
}

const DontShowAgain: FunctionComponent<Props> = ({ isCheck, onChangeCheck }: Props) => (
  <div className="modal-dont-show-again">
    <Checkbox
      name="checkbox"
      value={isCheck}
      onChange={onChangeCheck}
      tip
      simple
    >
      <TextContainer size="one-line-small" color="text-primary">
        {i18n.t('popups:dont_show_again')}
      </TextContainer>
    </Checkbox>
  </div>
);

export default DontShowAgain;
