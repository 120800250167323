import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import english from '../../locales/en.json';
import deutch from '../../locales/de.json';
import spanish from '../../locales/es.json';
import italian from '../../locales/it.json';

const DEFAULT_NAMESPACE = 'common';

export const SUPPORTED_LOCALES = [
  {
    locale: 'en-GB',
    name: 'EN',
  },
  {
    locale: 'de-DE',
    name: 'DE',
  },
  {
    locale: 'es-ES',
    name: 'ES',
  },
  {
    locale: 'it-IT',
    name: 'IT',
  },
];

const namespaces = [
  'common',
  'onboarding',
];

const languageResources = {
  en: english,
  de: deutch,
  es: spanish,
  it: italian,
};
// @ts-ignore
i18n
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    resources: languageResources,
    ns: namespaces,
    defaultNS: DEFAULT_NAMESPACE,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
