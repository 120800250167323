import React, { FunctionComponent } from 'react';

import icons from '../../../assets/images/icons';
import Image from '../../Image';

type Props = {
  onClick: () => void;
}

const CloseTag: FunctionComponent<Props> = ({ onClick }: Props) => (
  <button
    type="button"
    onClick={onClick}
    className="modal-close-tag"
  >
    <Image src={icons.cross.blue} styles="modal-close-tag-icon" />
  </button>
);

export default CloseTag;
