import React, { FunctionComponent } from 'react';
import Loader from 'react-loaders';

import 'loaders.css/loaders.css';
import Main from '../layout/components/Main';
import graphics from '../../assets/images/graphics';
import Image from '../Image';

const AppLoader: FunctionComponent = () => (
  <Main>
    <div className="w-full flex-1 flex flex-col justify-center items-center">
      <div className="wrap flex flex-col items-center">
        <Image src={graphics.logo} alt="The motor chain" styles="mb-2 h-24" />
        <Image src={graphics.textLogo} alt="The motor chain" styles="mb-8 h-8" />
        <Loader
          type="line-scale-pulse-out"
          innerClassName="loader-screen"
          active
        />
      </div>
    </div>
  </Main>
);

export default AppLoader;
