import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import icons from '../../../../assets/images/icons';
import usePagination from '../../../../services/hooks/pagination';
import { CarAttributes } from '../../../../store/types/car';
import Pagination from '../pagination/Pagination';
import FirstCarCard from '../cards/FirstCarCard';
import CarCard from '../cards/CarCard';
import LinkIcon from '../../../../components/buttons/LinkIcon';
import { paths } from '../../../../lib/routing';

import HomeSection from './HomeSection';

type Props = {
  cars: CarAttributes[];
}

const CARS_PER_PAGE = 6;

const MyCars: FunctionComponent<Props> = ({ cars }: Props) => {
  const { t } = useTranslation();
  const [page, next, prev] = usePagination(cars, CARS_PER_PAGE);

  return (
    <HomeSection icon={icons.myCars} title={t('home:my_cars')} viewAll={!_.isEmpty(cars) ? paths.myCars : ''}>
      {_.gt(_.size(cars), CARS_PER_PAGE) && (
      <Pagination next={next} prev={prev} />
      )}
      {_.isEmpty(cars) && <FirstCarCard />}
      {_.map((car: CarAttributes) => (
        <CarCard key={car.id} car={car} />
      ))(page)}
      <LinkIcon
        to={paths.car.new}
        icon={icons.add.empty}
        title={t('car:new_car')}
        styles="mx-auto mb-24"
      />
    </HomeSection>
  );
};

export default MyCars;
