import React, { FunctionComponent } from 'react';

import TextContainer from '../../../../components/TextContainer';

type Props = {
  message: string;
}

const EmptyMessage: FunctionComponent<Props> = ({ message }: Props) => (
  <TextContainer
    color="text-gray-3"
    size="text-small"
    styles="border border-primary p-12 rounded-md max-w-192 w-full"
  >
    {message}
  </TextContainer>
);

export default EmptyMessage;
