import { action, Action } from 'easy-peasy';

import flashMessage, { FlashMessage } from '../../types/flash';

export interface FlashModel {
  flash: FlashMessage;

  set: Action<FlashModel, FlashMessage>;

  unSet: Action<FlashModel>;
}

const flashModel: FlashModel = {
  flash: flashMessage,

  set: action((state, params) => {
    state.flash = { ...params };
  }),

  unSet: action((state) => {
    state.flash = flashMessage;
  }),
};

export default flashModel;
