import { CarAttributes, emptyCar } from './car';
import { EventAttributes } from './event';
import { UserAttributes } from './user';

export type AccessLevel = 'SNAPSHOT' | 'FULL' | 'GARAGE' | 'TRANSFER'

export type ViewerAttributes = {
  id: number;
  accessLevel: AccessLevel;
  pending: boolean;
  since: string;
  user: UserAttributes;
}

export type ViewerParams = {
  carID: number;
  id: number;
  password?: string;
}

export type SnapshotViewParams = {
  carID: number;
  token: string;
}

export type SnapshotAttributes = {
  car: CarAttributes,
  events: EventAttributes[],
}

export type ViewerCreationParams = SnapshotViewParams & {
  accessLevel: string;
  pending: boolean;
}

export const emptySnapshotAttributes = {
  car: emptyCar,
  events: [],
};

const emptyViewers: ViewerAttributes[] = [];

export default emptyViewers;
