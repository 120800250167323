import React, { FunctionComponent, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import classNames from 'classnames';

import client from '../../../api/axios';
import { INFINITE_DATE } from '../../../services/cookies';
import date from '../../../services/date';
import { SUPPORTED_LOCALES } from '../../../services/i18n/index';
import icons from '../../../assets/images/icons';
import Image from '../../Image';

type Props = {
  authenticated: boolean
}

const getLocale = _.flow(
  _.take(2),
  _.join(''),
);

const LanguageSelector: FunctionComponent<Props> = ({ authenticated }: Props) => {
  const [cookies, setCookie] = useCookies(['lang']);
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);

  const onChangeLanguage = (locale: string): void => {
    setOpen(!open);
    i18n.changeLanguage(getLocale(locale));
    client.defaults.headers.common['Accept-Language'] = locale;
    setCookie('lang', locale, {
      expires: INFINITE_DATE,
      sameSite: true,
      path: '/',
    });
    date.init(getLocale(locale));
  };

  const dropdownClass = classNames('language-selector-drop', {
    hidden: !open,
    authenticated,
    unauthenticated: !authenticated,
  });

  const selectorClass = classNames('language-selector', {
    authenticated,
    unauthenticated: !authenticated,
  });

  return (
    <div className="relative w-24 flex flex-col items-center cursor-pointer">
      <button type="button" className={selectorClass} onClick={(): void => setOpen(!open)}>
        {getLocale(cookies.lang)}
        {authenticated
          ? <Image src={icons.angleDown.white} styles="w-8 ml-2" />
          : <Image src={icons.angleDown.blue} styles="w-8 ml-2" />}
      </button>
      <div className={dropdownClass}>
        { _.map((lang: {locale: string, name: string}) => (
          <button type="button" key={lang.locale} onClick={(): void => onChangeLanguage(lang.locale)}>
            {t(lang.name)}
          </button>
        ))(SUPPORTED_LOCALES)}
      </div>
    </div>
  );
};

export default LanguageSelector;
