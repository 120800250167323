import React, { FunctionComponent, ChangeEvent, FocusEvent } from 'react';
import _ from 'lodash/fp';

import { hasErrors, isTouched } from '../../lib/helpers/forms';
import date from '../../services/date';

import FormInput from './components/FormInput';
import InputLabel from './components/InputLabel';
import InputError from './components/InputError';

export type Props = {
  name: string;
  value: string;
  placeholder: string;
  min: string | Date;
  max: string | Date;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
  readonly?: boolean;
  styles?: string;
  red?: boolean;
}

const textColor = (value: string, red: boolean): string => {
  if (_.isEmpty(value) && red) {
    return 'text-red';
  }

  if (_.isEmpty(value)) {
    return 'text-primary';
  }

  return 'text-primary-dark';
};

const DateInput: FunctionComponent<Props> = (
  {
    name,
    value,
    placeholder,
    min,
    max,
    onChange,
    onBlur = undefined,
    touched = false,
    errors = '',
    readonly = false,
    styles = '',
    red = false,
  }: Props,
) => (
  <FormInput styles={styles}>
    <InputLabel name={name} placeholder={_.isEmpty(value) ? '' : placeholder} />
    <input
      type="date"
      name={name}
      id={name}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={!_.isEmpty(value) ? '' : placeholder}
      className={textColor(value, red)}
      value={value}
      readOnly={readonly}
      min={date.format(min, 'YYYY-MM-DD')}
      max={date.format(max, 'YYYY-MM-DD')}
    />
    <InputError errors={hasErrors(errors) && isTouched(touched) ? errors : ''} />
  </FormInput>
);

export default DateInput;
