import { ChangeEvent } from 'react';
import _ from 'lodash/fp';

import {
  CategoryAttributes,
  CategoryFamilyAttributes,
  ServerCategories,
  SubcategoryAttributes,
} from '../../store/types/category';
import i18n from '../../services/i18n';
import { EventAttributes } from '../../store/types/event';

type IDs = {
  category: number;
  subcategory: number;
}

export const sanitizeEventCategories = (
  eventCategories: CategoryFamilyAttributes[],
): CategoryFamilyAttributes[] => (
  _.isEmpty(eventCategories) ? [] : eventCategories
);

export const parseCategoriesForServer = _.map(
  (eventCategory: CategoryFamilyAttributes): ServerCategories => ({
    category: eventCategory.category.id,
    subcategories: _.map('id', eventCategory.subcategories),
  }),
);

const findSubcategory = (
  id: number,
  selection: CategoryFamilyAttributes[],
  // @ts-ignore
): SubcategoryAttributes => _.flow(
  _.flatMap('subcategories'),
  _.find(['id', id]),
)(selection);

const findCategory = (
  id: number,
  selection: CategoryFamilyAttributes[],
  // @ts-ignore
): CategoryAttributes => _.flow(
  _.flatMap('category'),
  _.find(['id', id]),
)(selection);

const setIDs = (nameArray: string[]): IDs => ({
  category: parseInt(nameArray[0], 10),
  subcategory: parseInt(nameArray[1], 10),
});

const filterSelection = _.filter(
  (item: CategoryFamilyAttributes) => !_.isEmpty(item.subcategories),
);

const addCategory = (
  nameArray: string[],
  selection: CategoryFamilyAttributes[],
  categories: CategoryFamilyAttributes[],
): CategoryFamilyAttributes[] => {
  const ids = setIDs(nameArray);
  const category = findCategory(ids.category, categories);
  const subcategory = findSubcategory(ids.subcategory, categories);
  const newSelection: CategoryFamilyAttributes[] = [...selection];
  // eslint-disable-next-line no-restricted-syntax
  for (const key of newSelection) {
    if (key.category.id === ids.category) {
      key.subcategories.push(subcategory);
      return [...newSelection];
    }
  }
  newSelection.push({
    category,
    subcategories: [subcategory],
  });

  return [...newSelection];
};

const deleteCategory = (
  nameArray: string[],
  selection: CategoryFamilyAttributes[],
): CategoryFamilyAttributes[] => {
  const ids = setIDs(nameArray);
  const newSelection: CategoryFamilyAttributes[] = [...selection];
  // eslint-disable-next-line no-restricted-syntax
  for (const key of newSelection) {
    if (key.category.id === ids.category) {
      const newSubSelection = _.filter(
        (item: SubcategoryAttributes) => item.id !== ids.subcategory,
      )(key.subcategories);
      key.subcategories = [...newSubSelection];
    }
  }

  return [...filterSelection(newSelection)];
};

export const deleteMajorCategory = (
  id: number,
  selection: CategoryFamilyAttributes[],
): CategoryFamilyAttributes[] => (
  _.filter((category: CategoryFamilyAttributes) => (
    !_.isEqual(category.category.id, id)
  ))(selection)
);

export const onChangeCategory = (
  e: ChangeEvent<HTMLInputElement>,
  selection: CategoryFamilyAttributes[],
  categories: CategoryFamilyAttributes[],
): CategoryFamilyAttributes[] => {
  const { checked, name } = e.target;
  const nameArray = _.split('.')(name);
  if (checked) {
    return addCategory(nameArray, selection, categories);
  }
  return deleteCategory(nameArray, selection);
};

export const groupSubcategories = _.flow(
  _.map((subcategory: SubcategoryAttributes) => (
    i18n.t(`categories:${subcategory.name}`)
  )),
  _.join(', '),
);

export const nameWithoutLineFeed = (value: string): string => (
  _.replace(/\r?\n|\r/g, '')(i18n.t(`categories:${value}`))
);

export const getMajorCategories = _.flow(
  _.flatMap('category'),
  _.flatMap('name'),
);

export const getMinorCategories = _.flow(
  _.flatMap('subcategories'),
  _.flatMap('name'),
);

export const isFiltered = (filter: string, categoryNames: string[]): boolean => (
  !_.isEmpty(filter) && _.lt(_.indexOf(nameWithoutLineFeed(filter))(categoryNames), 0)
);

export const numberOfEventsFound = (filter: string, events: EventAttributes[]): number => _.flow(
  _.filter(_.isEqual(nameWithoutLineFeed(filter))),
  _.size,
)(_.concat(
  getMajorCategories(_.flatMap('eventCategories')(events)),
  getMinorCategories(_.flatMap('eventCategories')(events)),
));
