import _ from 'lodash/fp';

import countriesJson from '../assets/data/countries.json';
import citiesJson from '../assets/data/cities.json';

type CountryItem = {
  code: string;
  name: string;
}
// @ts-ignore
export const countries = _.flow(_.map((item) => _.capitalize(item.name)), _.uniq)(countriesJson);

const findCountryItem = (country: string): (CountryItem | undefined) => (
  _.isUndefined(country)
    ? undefined
    : _.find(
      (item: CountryItem) => _.isEqual(item.name.toLowerCase(), country.toLowerCase()),
    )(countriesJson)
);

const getCities = (countryItem: CountryItem | undefined): string[] => (
  _.isUndefined(countryItem)
    ? []
    : _.flow(
      _.filter(['country_code', countryItem.code]),
      _.map('city'),
    )(citiesJson)
);

export const cities = (country: string): Array<string> => getCities(findCountryItem(country));
