import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import _ from 'lodash/fp';

import { paths } from '../../../../lib/routing';
import CarPage from '../car-page/CarPage';
import { useStoreActions } from '../../../../store/hooks';
import useNavigation from '../../../../services/hooks/navigation';
import { isValidResponse } from '../../../../api/axios';
import { carTitle, isCarComplete } from '../../../../lib/helpers/car';
import VehicleData from '../vehicle-data/VehicleData';
import ScreenLoader from '../../../../components/loaders/ScreenLoader';
import { emptySharedCarAttributes } from '../../../../store/types/shared';
import Timeline from '../timeline/Timeline';
import Snapshot from '../snapshot/Snapshot';
import { Page } from '../../../../store/types/car';

type RouteParams = {
  id: string;
}

const ServiceCar: FunctionComponent = () => {
  const { id } = useParams<RouteParams>();
  const { show } = useStoreActions((actions) => actions.shared);
  const { flash } = useStoreActions((actions) => actions);
  const [isLoading, setIsLoading] = useState(true);
  const [sharedCar, setSharedCar] = useState(emptySharedCarAttributes);
  const [currentPage, setCurrentPage] = useState<Page>('vehicle-data');
  const navigate = useNavigation();

  useEffect(() => {
    show(id)
      .then((response: AxiosResponse) => {
        if (isValidResponse(response)) {
          setSharedCar(response.data);
          setCurrentPage(isCarComplete(response.data.car) ? 'timeline' : 'vehicle-data');
          setIsLoading(false);
          return;
        }
        flash.set({ value: response.data, type: 'error' });
        navigate(paths.home);
      });
  }, []);

  if (!isLoading && _.isEqual(sharedCar.accessLevel, 'SNAPSHOT')) {
    navigate(paths.snapshot(sharedCar.car.id));
  }

  if (!isLoading && _.isEqual(sharedCar.accessLevel, 'FULL')) {
    navigate(paths.sharedCar(sharedCar.car.id));
  }

  return (
    <>
      {isLoading
        ? <ScreenLoader />
        : (

          <CarPage
            current={currentPage}
            setCurrent={setCurrentPage}
            title={carTitle(sharedCar.car, 33)}
            car={sharedCar.car}
            readonly
          >
            <Timeline car={sharedCar.car} service />
            <VehicleData car={sharedCar.car} />
            <Snapshot car={sharedCar.car} setCurrent={setCurrentPage} />
          </CarPage>
        )}
    </>
  );
};

export default ServiceCar;
