import React from 'react';
import ReactDOM from 'react-dom';

import 'typeface-montserrat';
import 'typeface-open-sans';
import './styles/index.scss';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
