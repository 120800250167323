import React, { FunctionComponent } from 'react';

import icons from '../../../../assets/images/icons';
import ActionButton from '../../../../components/buttons/ActionButton';
import Image from '../../../../components/Image';
import TextContainer from '../../../../components/TextContainer';
import date from '../../../../services/date';
import i18n from '../../../../services/i18n';
import { ViewerAttributes } from '../../../../store/types/viewers';

type Props = {
  viewer: ViewerAttributes;
  onAccept: () => void;
  onReject: () => void;
}

const UserCard: FunctionComponent<Props> = ({ viewer, onAccept, onReject }: Props) => (
  <div className="flex flex-col xs:flex-row xs:items-center border border-primary rounded-lg p-6 w-full">
    <div className="flex items-stretch xs:w-1/2 xs:max-w-1/2 pr-1 mb-4 xs:mb-0">
      <Image src={icons.user.circle} styles="w-12 h-12 mr-2" />
      <div>
        <TextContainer font="opensans-bold" size="text-small">
          {viewer.user.name}
        </TextContainer>
        <TextContainer size="one-line-small">
          {viewer.user.country}
        </TextContainer>
      </div>
    </div>
    {viewer.pending
      ? (
        <div className="flex items-center justify-between xs:w-1/2">
          <ActionButton
            title={i18n.t('reject')}
            onClick={onReject}
            type="outline"
            styles="mr-2"
          />
          <ActionButton
            title={i18n.t('accept')}
            onClick={onAccept}
          />
        </div>
      ) : (
        <div className="flex items-center justify-between w-1/2">
          <TextContainer size="one-line-small" styles="uppercase mr-2">
            {date.format(viewer.since, 'MMM D')}
          </TextContainer>
          <ActionButton
            title={i18n.t('share_panel:stop_access')}
            onClick={onReject}
            type="outline"
          />
        </div>
      )}
  </div>
);

export default UserCard;
