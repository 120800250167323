import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { useStoreActions } from '../../../store/hooks';
import { isValidResponse } from '../../../api/axios';
import TextContainer from '../../../components/TextContainer';
import Flash from '../../../components/forms/Flash';
import Unauthenticated from '../../../components/layout/Unauthenticated';
import EmailInput from '../../../components/forms/EmailInput';
import SubmitLoader from '../../../components/loaders/SubmitLoader';
import SubmitButton from '../../../components/buttons/SubmitButton';
import Card from '../components/Card';
import Back from '../../../components/layout/components/Back';
import { paths } from '../../../lib/routing';

type Inputs = {
  email: string;
}

const NewPassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const { create } = useStoreActions((actions) => actions.passwords);
  const [message, setMessage] = useState({ text: '', success: false });

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('errors:email:valid'))
      .required(t('errors:email:required')),
  });

  const handleOnSubmit = async (data: Inputs): Promise<void> => {
    const response = await create(data.email);
    setMessage({
      text: response.data,
      success: isValidResponse(response),
    });
  };

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: { email: '' },
    onSubmit: handleOnSubmit,
    validationSchema: schema,
    validateOnBlur: true,
  });

  return (
    <Unauthenticated>
      {isSubmitting && <SubmitLoader />}
      <Card border>
        <Back url={paths.signin} styles="absolute top-0 left-0" />
        <TextContainer tag="h1" font="montserrat" size="one-line-large" styles="text-center mb-16">
          {t('passwords:title:new')}
        </TextContainer>
        <form onSubmit={handleSubmit} className="block max-w-full">
          <Flash
            message={message.success ? t('passwords:success') : message.text}
            type={message.success ? 'notice' : 'error'}
          />
          <EmailInput
            name="email"
            value={values.email}
            placeholder={t('session:email')}
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            touched={touched.email}
            errors={errors.email}
          />
          <TextContainer size="one-line-small" styles="px-6 mb-8">
            {t('passwords:forgot_password_message')}
          </TextContainer>
          <SubmitButton
            title={t('send')}
            disabled={isSubmitting || !isValid}
            styles="mx-auto"
          />
        </form>
      </Card>
    </Unauthenticated>
  );
};

export default NewPassword;
