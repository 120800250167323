import React, { FunctionComponent } from 'react';

import Image from '../../../../components/Image';
import TextContainer from '../../../../components/TextContainer';

type Props = {
  title: string;
  icon: string;
}

const SectionTitle: FunctionComponent<Props> = ({ title, icon }: Props) => (
  <div className="flex items-center justify-between relative border-b border-primary w-full max-w-9/10">
    <TextContainer size="one-line-large" font="montserrat-bold">
      {title}
    </TextContainer>
    <Image src={icon} styles="w-24 h-24 ml-4" />
    <div className="absolute top-full left-0 w-16 h-2 bg-primary-dark" />
  </div>
);

export default SectionTitle;
