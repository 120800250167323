import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';

import TextContainer from '../../../../../components/TextContainer';
import useAssistant from '../../../../../services/hooks/assistant';
import assistantQuestions, { Question } from '../../../../../utils/assistant';
import { useStoreState } from '../../../../../store/hooks';
import { EventAttributes, YearEvents } from '../../../../../store/types/event';
import Image from '../../../../../components/Image';
import icons from '../../../../../assets/images/icons';
import ActionButton from '../../../../../components/buttons/ActionButton';
import { assistantEvents } from '../../../../../lib/helpers/timeline';
import Year from '../components/Year';

import QuestionItem from './components/Question';
import AssistantTitle from './components/AssistantTitle';

type Props = {
  carID: number;
  events: EventAttributes[];
  onClickEvent: (event: EventAttributes) => void;
}

const getEventCategoriesIDs = _.flow(
  _.flatMap('eventCategories'),
  _.flatMap('category'),
  _.flatMap('id'),
  _.uniq,
);

const Assistant: FunctionComponent<Props> = ({ carID, events, onClickEvent }: Props) => {
  const { t } = useTranslation();
  const { demoCar } = useStoreState((state) => state.user.user.settings);
  const { onHide, onCompleteCar } = useAssistant(carID);

  const eventCategoriesIDs = getEventCategoriesIDs(events);

  const questions = _.filter((question: Question) => (
    !_.gt(_.indexOf(question.categoryID)(eventCategoriesIDs), 0)
  ))(assistantQuestions);

  return (
    <div className="expand items-center xl:w-1/2 xl:mt-24 xl:border-r border-primary-light xl:relative xl:overflow-y-scroll">
      <div className="w-full flex flex-col items-center xl:absolute xl:top-0 xl:left-0">
        <div className="bg-primary-dark rounded-md py-12 px-4 max-w-screen-xs w-full xl:px-8">
          <AssistantTitle onClose={onHide} />
          <div className="w-full mb-4">
            <div className="full">
              <TextContainer size="one-line-medium" font="montserrat-bold" color="text-white" styles="py-2 border-b-2 border-white">
                {t('assistant:year')}
              </TextContainer>
            </div>
            {_.isEmpty(questions)
              ? (
                <div className="flex">
                  <div className="min-w-24 w-24 max-w-24 border-r border-white" />
                  <div className="pl-24 py-16 w-full relative">
                    <div className="w-48 absolute top-12 -left-24">
                      <Image
                        src={icons.progress.complete}
                        styles="w-32 h-32 mx-auto"
                      />
                    </div>
                    <TextContainer color="text-white" font="montserrat" styles="mb-2">
                      {t('assistant:complete_1')}
                    </TextContainer>
                    <TextContainer color="text-white" font="montserrat" styles="mb-2">
                      {t('assistant:complete_2')}
                    </TextContainer>
                    <TextContainer color="text-white" font="montserrat" styles="mb-4">
                      {t('assistant:complete_3')}
                    </TextContainer>
                    <ActionButton
                      onClick={onCompleteCar}
                      title={t('assistant:ok_thanks')}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {_.map((question: Question) => (
                    <QuestionItem
                      key={question.initialCategory}
                      carID={carID}
                      question={question}
                      isDemoCar={demoCar}
                    />
                  ))(questions)}
                </>
              )}
          </div>
          <div className="w-full">
            {_.map((year: YearEvents) => (
              <Year
                key={year.year}
                year={year}
                onClickEvent={onClickEvent}
                styles="white"
              />
            ))(assistantEvents(events) as YearEvents[])}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assistant;
