import { useFormik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { paths } from '../../../lib/routing';
import useNavigation from '../../../services/hooks/navigation';
import { useStoreActions, useStoreState } from '../../../store/hooks';
import { UserParams } from '../../../store/types/user';
import { DocumentName, emptyBase64 } from '../../../store/types/document';
import { fileInputValue, getDocument, saveDocument } from '../../../lib/helpers/documents';
import Authenticated from '../../../components/layout/Authenticated';
import Image from '../../../components/Image';
import icons from '../../../assets/images/icons';
import TextInput from '../../../components/forms/TextInput';
import { cities, countries } from '../../../utils/location';
import FileInput from '../../../components/forms/FileInput';
import SubmitButton from '../../../components/buttons/SubmitButton';
import ConfirmModal from '../../../components/modals/ConfirmModal';
import InfoModal from '../../../components/modals/InfoModal';
import TextContainer from '../../../components/TextContainer';
import SubmitLoader from '../../../components/loaders/SubmitLoader';

import Form from './components/Form';
import AccountDeletion from './components/AccountDeletion';

const Profile: FunctionComponent = () => {
  const { user, isVerified, isVerificationPending } = useStoreState((state) => state.user);
  const { update } = useStoreActions((actions) => actions.user);
  const { t } = useTranslation();
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [info, setInfo] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [confirmChange, setConfirmChange] = useState(false);
  const navigate = useNavigation();

  const onCloseModal = (): void => {
    setInfo(false);
    if (submitSuccess) navigate(paths.home);
  };

  const onAcceptChange = (): void => {
    setConfirmChange(true);
    setChangeImage(false);
  };

  const handleOnSubmit = async (data: UserParams): Promise<void> => {
    setSubmitSuccess(await update(data));
    setInfo(true);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: {
      email: user.email,
      name: user.name,
      country: user.country,
      city: user.city,
      verifiedDate: user.verifiedDate,
      document: emptyBase64,
    },
    onSubmit: handleOnSubmit,
  });

  const handleDocumentChange = async (
    name: DocumentName,
    value: File | FileList,
  ): Promise<void> => {
    setFieldValue(name, await saveDocument(value as File));
  };

  const setValidationState = (): string => {
    if (isVerified) {
      return t('profile:verified');
    }
    if (isVerificationPending) {
      return t('profile:pending');
    }
    return t('profile:validation_instructions');
  };

  return (
    <Authenticated>
      <Form title={t('authenticated:profile')} icon={icons.user.blue} onSubmit={handleSubmit} state={setValidationState()}>
        {isSubmitting && <SubmitLoader />}
        <ConfirmModal
          isOpen={changeImage}
          message={t('popups:are_you_sure')}
          onAccept={onAcceptChange}
          onReject={() => setChangeImage(false)}
        />
        <InfoModal
          isOpen={info}
          message={submitSuccess ? t('popups:update_success') : t('popups:update_error')}
          onAccept={() => onCloseModal()}
        />
        <TextInput
          name="name"
          placeholder={t('user:attributes:name')}
          value={values.name}
          onChange={handleChange('name')}
        />
        <TextInput
          name="country"
          placeholder={t('user:attributes:country')}
          value={values.country}
          onChange={handleChange('country')}
          options={countries}
        />
        <TextInput
          name="city"
          placeholder={t('user:attributes:city')}
          value={values.city}
          onChange={handleChange('city')}
          options={cities(values.country)}
        />
        <div>
          {isVerified && !confirmChange ? (
            <div className="mx-auto max-w-192">
              <button
                type="button"
                onClick={() => setChangeImage(true)}
                className="block w-full"
              >
                <Image src={getDocument(user.document, 'large')} styles="mx-auto" />
              </button>
            </div>
          ) : (
            <div>
              <FileInput
                name="document"
                placeholder={t('user:attributes:document')}
                value={fileInputValue(values.document)}
                currentValue={user.document}
                handleDocumentChange={handleDocumentChange}
                type="profile"
              />
              <TextContainer size="text-small" styles="max-w-192 w-full mx-auto">
                {t('profile:upload_documentation_message')}
              </TextContainer>
            </div>
          )}
          {dirty && (
            <div className="w-full max-w-192 flex justify-end mt-12 mx-auto">
              <SubmitButton
                title={
                !_.isEmpty(values.document.filename)
                  ? t('profile:send_for_verification')
                  : t('profile:update_profile')
              }
                disabled={isSubmitting}
              />
            </div>
          )}
        </div>
      </Form>
      <AccountDeletion />
    </Authenticated>
  );
};

export default Profile;
