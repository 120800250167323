import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { CategoryFamilyAttributes } from '../../../../../../store/types/category';
import TextContainer from '../../../../../../components/TextContainer';
import SummaryItem from '../../../../components/SummaryItem';

type Props = {
  selection: CategoryFamilyAttributes[];
  title?: boolean;
}

const Summary: FunctionComponent<Props> = ({ selection, title = true }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="mb-8">
      {title && (
        <TextContainer size="one-line-medium" font="opensans-bold" styles="mb-12">
          {t('event:summary')}
        </TextContainer>
      )}
      <div className="w-full">
        {_.map((category: CategoryFamilyAttributes) => (
          <SummaryItem
            key={category.category.id}
            category={category}
            styles="mb-8"
          />
        ))(selection)}
      </div>
    </div>
  );
};

export default Summary;
