import React, { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  children: ReactNode;
  background?: boolean;
  checkbox?: boolean;
  tip?: boolean;
  styles?: string;
}

const FormInput: FunctionComponent<Props> = (
  {
    children,
    background = false,
    checkbox = false,
    tip = false,
    styles = '',
  }: Props,
) => {
  const formInputClass = classNames(`form-input ${styles}`, {
    'with-bg': background,
    checkbox,
    tips: tip,
  });

  return (
    <div className={formInputClass}>
      {children}
    </div>
  );
};

export default FormInput;
