export type CountCategory = {
  id: number;
  name: string;
}

export const majorCategories: CountCategory[] = [
  {
    id: 1,
    name: 'minor_service',
  },
  {
    id: 2,
    name: 'major_service',
  },
  {
    id: 4,
    name: 'restoration',
  },
  {
    id: 6,
    name: 'certification',
  },
];

export const minorCategoriesOne: CountCategory[] = [
  {
    id: 1,
    name: 'oil_change',
  },
  {
    id: 10,
    name: 'distribution_belt',
  },
  {
    id: 28,
    name: 'spark_plugs',
  },
  {
    id: 14,
    name: 'water_pump',
  },
];

export const minorCategoriesTwo: CountCategory[] = [
  {
    id: 5,
    name: 'ownership_change',
  },
  {
    id: 55,
    name: 'concours_delegance',
  },
  {
    id: 54,
    name: 'race',
  },
  {
    id: 58,
    name: 'tour',
  },
];
