import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  children: ReactNode;
}

const Main: FunctionComponent<Props> = ({ children }: Props) => (
  <div id="main-wrapper">{children}</div>
);

export default Main;
