import React, { FunctionComponent, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import _ from 'lodash/fp';

import { useStoreActions } from '../../../../store/hooks';
import { CarAttributes } from '../../../../store/types/car';
import useNavigation from '../../../../services/hooks/navigation';
import { emptyEvent, EventAttributes } from '../../../../store/types/event';
import { isCarComplete } from '../../../../lib/helpers/car';
import { DEMO_CAR_ID } from '../../../../store/model/cars/cars';
import ScreenLoader from '../../../../components/loaders/ScreenLoader';
import { isValidResponse } from '../../../../api/axios';
import { paths } from '../../../../lib/routing';
import UncompletedCar from '../components/UncompletedCar';
import useAssistant from '../../../../services/hooks/assistant';
import { CategoryFamilyAttributes } from '../../../../store/types/category';

import TimelineSection from './timeline-section/TimelineSection';
import SnapshotSection from './snapshot-section/SnapshotSection';
import ShowEvent from './event/ShowEvent';
import Assistant from './assistant/Assistant';
import Filter from './filter/Filter';

type Props = {
  car: CarAttributes;
  readOnly?: boolean;
  service?: boolean;
}

const Timeline: FunctionComponent<Props> = (
  {
    car,
    readOnly = false,
    service = false,
  }: Props,
) => {
  const { index } = useStoreActions((actions) => actions.events);
  const { index: getCategories } = useStoreActions((actions) => actions.categories);
  const { isActive } = useAssistant(car.id);
  const navigate = useNavigation();
  const [events, setEvents] = useState<EventAttributes[]>([]);
  const [categories, setCategories] = useState<CategoryFamilyAttributes[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState<EventAttributes | null>(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    (async () => {
      const eventsResponse: AxiosResponse = await index(isCarComplete(car) ? car.id : DEMO_CAR_ID);
      const categoriesResponse: AxiosResponse = await getCategories();
      if (isValidResponse(eventsResponse) && isValidResponse(categoriesResponse)) {
        setEvents(eventsResponse.data);
        setCategories(categoriesResponse.data);
        setIsLoading(false);
        return;
      }
      navigate(paths.home);
    })();
  }, []);

  const onClickEvent = (event: EventAttributes): void => setSelectedEvent(event);

  const readonlyEventURL = (event: EventAttributes): string => (
    paths.sharedCarEvent(car.id, event.id)
  );

  const serviceEventURL = (event: EventAttributes): string => (
    event.isDraft
      ? paths.serviceCar.events.edit(car.id, event.id)
      : paths.serviceCar.events.show(car.id, event.id)
  );

  const accessURL = (event: EventAttributes): string => (
    service ? serviceEventURL(event) : readonlyEventURL(event)
  );

  const myCarURL = (event: EventAttributes): string => (
    event.isDraft ? paths.events.edit(car.id, event.id) : paths.events.show(car.id, event.id)
  );

  const eventURL = (event: EventAttributes): string => (
    service || readOnly ? accessURL(event) : myCarURL(event)
  );

  const onChangeFilter = (name: string): void => setFilter(name);
  const onOpenFilter = (): void => setFilterOpen(true);
  const onClearFilter = (): void => setFilter('');
  const isAssistantActive = isActive && !readOnly && !service;

  return (
    <div className="expand timeline relative pb-8">
      {!isCarComplete(car) && <UncompletedCar />}
      {isLoading
        ? <ScreenLoader />
        : (
          <div className="flex flex-col flex-1 xl:flex-row-reverse">
            <ShowEvent
              isOpen={!_.isNil(selectedEvent)}
              onClose={() => setSelectedEvent(null)}
              eventURL={_.isNil(selectedEvent) ? '' : eventURL(selectedEvent)}
              // @ts-ignore
              event={_.isNil(selectedEvent) ? emptyEvent : selectedEvent}
            />
            {_.isNil(selectedEvent) && (
              <SnapshotSection
                readOnly={readOnly}
                service={service}
                car={car}
                events={events}
              />
            )}
            {isAssistantActive
              ? (
                <Assistant
                  carID={car.id}
                  events={events}
                  onClickEvent={onClickEvent}
                />
              ) : (
                <TimelineSection
                  carID={car.id}
                  events={events}
                  onClickEvent={onClickEvent}
                  readOnly={readOnly}
                  service={service}
                  onOpenFilter={onOpenFilter}
                  filter={filter}
                  onClearFilter={onClearFilter}
                />
              )}
            <Filter
              isOpen={filterOpen}
              onClose={() => setFilterOpen(false)}
              categories={categories}
              setFilter={onChangeFilter}
            />
          </div>
        )}
    </div>
  );
};

export default Timeline;
