import React, { FunctionComponent, ReactNode } from 'react';
import _ from 'lodash/fp';

import Tag from '../../../../../components/Tag';
import TextContainer from '../../../../../components/TextContainer';

type Props = {
  children: ReactNode;
  title: string;
  entries?: string;
  subtitle?: string;
  styles?: string;
}

const Section: FunctionComponent<Props> = (
  {
    children,
    title,
    entries,
    subtitle,
    styles = '',
  }: Props,
) => (
  <div className={styles}>
    <div className="py-4 border-b border-primary-light mb-12 flex flex-col md:flex-row md:justify-between md:items-center">
      <div className="flex items-center">
        <TextContainer
          tag="h3"
          size="one-line-medium"
          font="montserrat-bold"
          styles="uppercase mr-4"
        >
          {title}
        </TextContainer>
        {!_.isUndefined(entries) && <Tag text={entries} type="dark-blue" />}
      </div>
      {!_.isUndefined(subtitle) && (
        <div>
          <TextContainer
            tag="h6"
            size="one-line-small"
            styles="mr-4"
          >
            {subtitle}
          </TextContainer>
        </div>
      )}
    </div>
    <div className="xl:flex">
      {children}
    </div>
  </div>
);

export default Section;
