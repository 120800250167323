import _ from 'lodash/fp';
import React, { ChangeEvent, FocusEvent, FunctionComponent } from 'react';

import FormInput from '../../../../../../components/forms/components/FormInput';
import InputLabel from '../../../../../../components/forms/components/InputLabel';
import InputError from '../../../../../../components/forms/components/InputError';
import { hasErrors, isTouched } from '../../../../../../lib/helpers/forms';

type Props = {
  name: string;
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLTextAreaElement>) => void;
  touched?: boolean;
  errors?: string;
  styles?: string;
}

const TextareaInput: FunctionComponent<Props> = (
  {
    name,
    value,
    placeholder,
    onChange,
    onBlur,
    touched = false,
    errors = '',
    styles = '',
  }: Props,
) => (
  <FormInput styles={styles}>
    <InputLabel name={name} placeholder={_.isEmpty(value) ? '' : placeholder} />
    <textarea
      name={name}
      id={name}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      style={{}}
    />
    <InputError errors={hasErrors(errors) && isTouched(touched) ? errors : ''} />
  </FormInput>
);

export default TextareaInput;
