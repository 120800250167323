import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { CarAttributes } from '../../../../store/types/car';
import TextContainer from '../../../../components/TextContainer';
import { paths } from '../../../../lib/routing';
import { carName } from '../../../../lib/helpers/car';
import Image from '../../../../components/Image';
import icons from '../../../../assets/images/icons';
import i18n from '../../../../services/i18n';
import { hasPendingRequests, requestPending, viewersNumber } from '../../../../lib/helpers/viewers';

export type Props = {
  car: CarAttributes;
}

const SharedByMeCard: FunctionComponent<Props> = ({ car }: Props) => (
  <Link
    to={paths.sharePanel(car.id)}
    className="flex items-center max-w-192 w-full p-8 border border-primary-x-light rounded-md mb-4"
  >
    <TextContainer font="montserrat" styles="truncate xs:whitespace-normal max-h-24 w-1/3 pr-1">
      {carName(car)}
    </TextContainer>
    <TextContainer size="one-line-small" styles="truncate w-1/3 flex items-center pr-1 justify-center">
      <Image src={icons.viewers.small} styles="h-6 mr-1" />
      {i18n.t('share:viewers', { number: viewersNumber(car.viewers) })}
    </TextContainer>
    {hasPendingRequests(car.viewers) && (
      <TextContainer size="text-small" color="text-red" font="montserrat" styles="truncate xs:whitespace-normal xs:max-h-16 text-right w-1/3">
        {i18n.t('share:requests', { number: requestPending(car.viewers) })}
      </TextContainer>
    )}
  </Link>
);

export default SharedByMeCard;
