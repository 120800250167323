import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { paths } from '../../../../lib/routing';
import CarPage from '../car-page/CarPage';
import { useStoreActions } from '../../../../store/hooks';
import { emptyCar, Page } from '../../../../store/types/car';
import useNavigation from '../../../../services/hooks/navigation';
import { isValidResponse } from '../../../../api/axios';
import { carTitle, isCarComplete } from '../../../../lib/helpers/car';
import VehicleData from '../vehicle-data/VehicleData';
import ScreenLoader from '../../../../components/loaders/ScreenLoader';
import Timeline from '../timeline/Timeline';
import Snapshot from '../snapshot/Snapshot';
import { hasTransferPending } from '../../../../lib/helpers/viewers';

type RouteParams = {
  id: string;
}

const EditCar: FunctionComponent = () => {
  const { id } = useParams<RouteParams>();
  const { show } = useStoreActions((actions) => actions.cars);
  const { flash } = useStoreActions((actions) => actions);
  const [isLoading, setIsLoading] = useState(true);
  const [car, setCar] = useState(emptyCar);
  const [currentPage, setCurrentPage] = useState<Page>('vehicle-data');
  const navigate = useNavigation();

  useEffect(() => {
    show(id)
      .then((response: AxiosResponse) => {
        if (isValidResponse(response)) {
          setCar(response.data);
          setCurrentPage(isCarComplete(response.data) ? 'timeline' : 'vehicle-data');
          setIsLoading(false);
          return;
        }
        flash.set({ value: response.data, type: 'error' });
        navigate(paths.home);
      });
  }, []);

  return (
    <>
      {isLoading
        ? <ScreenLoader />
        : (
          <CarPage
            current={currentPage}
            setCurrent={setCurrentPage}
            title={carTitle(car, 33)}
            car={car}
          >
            <Timeline car={car} readOnly={hasTransferPending(car.viewers)} />
            <VehicleData car={car} readOnly={hasTransferPending(car.viewers)} />
            <Snapshot car={car} setCurrent={setCurrentPage} />
          </CarPage>
        )}

    </>
  );
};

export default EditCar;
