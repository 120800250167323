import { AxiosResponse } from 'axios';
import React, {
  Dispatch,
  FunctionComponent,
  useEffect,
  useState,
  SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';

import { carImage, isCarComplete } from '../../../../lib/helpers/car';
import { useStoreActions } from '../../../../store/hooks';
import { DEMO_CAR_ID } from '../../../../store/model/cars/cars';
import { CarAttributes, Page } from '../../../../store/types/car';
import { EventAttributes } from '../../../../store/types/event';
import { isValidResponse } from '../../../../api/axios';
import useNavigation from '../../../../services/hooks/navigation';
import { paths } from '../../../../lib/routing';
import CarPicture from '../../../../components/CarPicture';
import TextContainer from '../../../../components/TextContainer';
import ScreenLoader from '../../../../components/loaders/ScreenLoader';
import Image from '../../../../components/Image';
import icons from '../../../../assets/images/icons';
import { CountCategory, majorCategories } from '../../../../utils/snapshot';
import MajorCategoryCounter from '../categories/MajorCategoryCounter';
import { categorySize } from '../../../../lib/helpers/event';
import { nameWithoutLineFeed } from '../../../../lib/helpers/categories';
import UncompletedCar from '../components/UncompletedCar';

import SnapshotEvent from './components/SnapshotEvent';
import Gallery from './components/Gallery';

type Props = {
  car: CarAttributes;
  setCurrent?: Dispatch<SetStateAction<Page>>;
  currentEvents?: EventAttributes[];
}

const numberOfDocuments = _.flow(
  _.flatMap('documents'),
  _.size,
);

const filterEvents = _.flow(
  _.reject('isDraft'),
  _.orderBy(['id'], ['asc']),
);

const lastEvents = _.flow(
  _.orderBy(['date'], ['desc']),
  _.take(2),
);

const Snapshot: FunctionComponent<Props> = ({ car, setCurrent, currentEvents = [] }: Props) => {
  const { index } = useStoreActions((actions) => actions.events);
  const { t } = useTranslation();
  const [events, setEvents] = useState<EventAttributes[]>(currentEvents);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigation();

  useEffect(() => {
    if (_.isEmpty(events)) {
      index(isCarComplete(car) ? car.id : DEMO_CAR_ID)
        .then((response: AxiosResponse) => {
          if (isValidResponse(response)) {
          // @ts-ignore
            setEvents(filterEvents(response.data));
            setIsLoading(false);
            return;
          }
          navigate(paths.home);
        });
      return;
    }
    setIsLoading(false);
  }, []);

  return (
    <div className="relative w-full max-w-screen-lg mx-auto expand snapshot">
      {!isCarComplete(car) && <UncompletedCar />}
      {isLoading
        ? <ScreenLoader />
        : (
          <>
            <div className="py-16 mb-16 border-b border-primary md:flex">
              <div className="flex flex-col items-center justify-center w-full mb-8 md:w-1/2 md:mb-0 xl:border-r xl:border-primary md:px-8">
                <CarPicture src={carImage(car.carImages, 'large')} />
              </div>
              <div className="flex flex-col items-center justify-center w-full md:w-1/2">
                <div
                  className={`w-9/10 md:max-w-screen-initial ${!_.isUndefined(setCurrent) ? 'cursor-pointer' : ''}`}
                  onClick={!_.isUndefined(setCurrent) ? () => setCurrent('vehicle-data') : undefined}
                >
                  <TextContainer font="montserrat-bold" size="text-medium">
                    {`${car.carMake} ${car.modelName} - ${car.manufactureYear}`}
                  </TextContainer>
                  <TextContainer>
                    {t('snapshot:total_events', { number: _.size(events) })}
                  </TextContainer>
                  <TextContainer styles="flex items-center">
                    {t('snapshot:pictures', { number: numberOfDocuments(events) })}
                    <Image src={icons.picture} styles="w-6 h-6 ml-2" />
                  </TextContainer>
                  <TextContainer>
                    {_.flow(
                      _.split('|'),
                      _.map((line: string) => (
                        <span className="block" key={line}>{line}</span>
                      )),
                    )(car.intro)}
                  </TextContainer>
                </div>
              </div>
            </div>
            <Gallery car={car} />
            <div
              className={`py-8 mb-16 border-b border-primary ${!_.isUndefined(setCurrent) ? 'cursor-pointer' : ''}`}
              onClick={!_.isUndefined(setCurrent) ? () => setCurrent('timeline') : undefined}
            >
              <TextContainer font="montserrat" styles="mb-8" size="one-line-medium">
                {t('snapshot:last_events')}
              </TextContainer>
              <div className="justify-around md:flex">
                {_.map((event: EventAttributes) => (
                  <SnapshotEvent
                    key={event.id}
                    event={event}
                  />
                  // @ts-ignore
                ))(lastEvents(events))}
              </div>
            </div>
            <div className="flex flex-wrap justify-between w-full py-16 mb-16 border-b border-primary lg:flex-nowrap">
              {_.map((category: CountCategory) => (
                <MajorCategoryCounter
                  key={category.id}
                  number={categorySize(category.id, events)}
                  name={nameWithoutLineFeed(category.name)}
                  styles="w-9/20 lg:w-1/5"
                />
              ))(majorCategories)}
            </div>
          </>
        )}
    </div>
  );
};

export default Snapshot;
