import React, { FunctionComponent } from 'react';

import icons from '../../../../../../assets/images/icons';
import Image from '../../../../../../components/Image';
import TextContainer from '../../../../../../components/TextContainer';
import i18n from '../../../../../../services/i18n';

type Props = {
  onClose: () => void;
}

const AssistantTitle: FunctionComponent<Props> = (
  {
    onClose,
  }: Props,
) => (
  <div className="flex justify-end w-full">
    <div className="w-1/2">
      <TextContainer size="text-small" styles="flex items-center mb-2 justify-end" color="text-white">
        <Image src={icons.assistant.white} styles="w-6 h-6 mr-2" />
        {i18n.t('timeline:assistant_mode')}
        <button type="button" className="w-6 h-6" onClick={onClose}>
          <Image src={icons.cross.white} styles="w-6 h-6 ml-2" />
        </button>
      </TextContainer>
      <TextContainer color="text-white" size="text-small" styles="text-right">
        {i18n.t('assistant:assistant_description')}
      </TextContainer>
    </div>
  </div>
);

export default AssistantTitle;
