import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import { useStoreActions, useStoreState } from '../store/hooks';
import icons from '../assets/images/icons';

import Image from './Image';
import TextContainer from './TextContainer';

const FlashMessage: FunctionComponent = () => {
  const { unSet } = useStoreActions((actions) => actions.flash);
  const { flash } = useStoreState((state) => state.flash);

  const content = classnames('w-192 flex px-8 py-6 shadow-xl rounded-sm', {
    'bg-red': _.isEqual(flash.type, 'error'),
    'bg-green': _.isEqual(flash.type, 'success'),
  });

  if (_.isEmpty(flash.value)) {
    return null;
  }

  if (!_.isEmpty(flash.value)) {
    setTimeout(unSet, 3000);
  }

  return (
    <div className="fixed z-60 w-full flex flex-col justify-center items-center top-16 left-0">
      <div className="wrap flex-1 flex justify-end">
        <div className={content}>
          <TextContainer color="text-white" styles="flex-1 mr-4">
            {flash.value}
          </TextContainer>
          <button type="button" className="block" onClick={() => unSet()}>
            <Image src={icons.cross.white} styles="w-8 h-8" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlashMessage;
