import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  children: ReactNode;
}

const Body: FunctionComponent<Props> = ({ children }: Props) => (
  <main className="expand">
    {children}
  </main>
);

export default Body;
