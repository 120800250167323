import React, { FunctionComponent } from 'react';

import Balloon from '../../../../components/Balloon';
import i18n from '../../../../services/i18n';

const UncompletedCar: FunctionComponent = () => (
  <div className="bg-background-blue absolute w-full h-full pt-32 z-5 rounded-sm">
    <Balloon
      message={i18n.t('car:incomplete_car')}
      direction="top-center"
      styles="mx-auto"
    />
  </div>
);

export default UncompletedCar;
