import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import Unauthenticated from '../../../../components/layout/Unauthenticated';
import TextContainer from '../../../../components/TextContainer';
import useQuery from '../../../../services/hooks/query';
import i18n from '../../../../services/i18n';
import Card from '../../../unauthenticated/components/Card';

const PaymentCheckout: FunctionComponent = () => {
  const query = useQuery();

  let response = i18n.t('payments:congratulations');

  if (!_.isNil(query.get('response'))) {
    response = i18n.t(`payments:${query.get('response')}`);
  }

  return (
    <Unauthenticated>
      <Card border>
        <TextContainer tag="h2" font="montserrat" size="text-medium" styles="text-center">
          {response}
        </TextContainer>
      </Card>
    </Unauthenticated>
  );
};

export default PaymentCheckout;
