import React, { FunctionComponent, ReactNode } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { paths } from '../lib/routing';
import { useStoreState } from '../store/hooks';

type Props = {
  exact?: boolean;
  path: string;
  children: ReactNode;
}

const PublicScreen: FunctionComponent<Props> = (
  {
    exact = false,
    path,
    children,
  }: Props,
) => {
  const { isLoggedIn } = useStoreState((state) => state.session);

  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) => (
        isLoggedIn
          ? (
            <Redirect
              to={{
                pathname: paths.home,
                state: { from: location },
              }}
            />
          )
          : children
      )}
    />
  );
};

export default PublicScreen;
