import React, { FunctionComponent } from 'react';

import icons from '../../../../../assets/images/icons';
import Image from '../../../../../components/Image';

type Props = {
  onClick?: () => void;
  firstPage: boolean;
}

const PrevArrow: FunctionComponent<Props> = ({ onClick, firstPage }: Props) => (
  <button
    type="button"
    className={`block ${firstPage ? 'opacity-50' : null}`}
    onClick={onClick}
  >
    <Image src={icons.arrow.left} styles="w-12" />
  </button>
);

export default PrevArrow;
