import React, { FunctionComponent, ReactNode } from 'react';

import Image from '../Image';
import graphics from '../../assets/images/graphics';

import RightGraphic from './components/RightGraphic';
import Body from './components/Body';
import Header from './components/Header';
import Main from './components/Main';
import Content from './components/Content';
import Footer from './components/Footer';

export type Props = {
  children: ReactNode;
}

const Unauthenticated: FunctionComponent<Props> = ({ children }: Props) => (
  <Main>
    <Header />
    <Body>
      <Content>
        <div className="expand lg:flex-row lg:justify-start">
          <div className="expand lg:w-1/2 items-center lg:flex-none">
            {children}
            <div className="xl:hidden">
              <a href={process.env.REACT_APP_TMC_ITUNES_URL} className="block" target="_blank" rel="noreferrer">
                <Image
                  src={graphics.appStore}
                  alt="The Motor Chain | iTunes"
                  styles="mb-12 h-24"
                />
              </a>
            </div>
            <Footer />
          </div>
          <RightGraphic />
        </div>
      </Content>
      <RightGraphic />
    </Body>
  </Main>
);

export default Unauthenticated;
