import React, { FunctionComponent, useState, useEffect } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import icons from '../../../../assets/images/icons';
import usePagination from '../../../../services/hooks/pagination';
import Pagination from '../pagination/Pagination';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import SharedWithMeCard from '../cards/SharedWithMeCard';
import { SharedCarAttributes } from '../../../../store/types/shared';
import ScreenLoader from '../../../../components/loaders/ScreenLoader';
import { paths } from '../../../../lib/routing';

import HomeSection from './HomeSection';
import EmptyMessage from './EmptyMessage';

const CARS_PER_PAGE = 3;

const SharedWithMe: FunctionComponent = () => {
  const { t } = useTranslation();
  const { index } = useStoreActions((actions) => actions.shared);
  const { sharedCars } = useStoreState((state) => state.shared);
  const { settings } = useStoreState((state) => state.user.user);
  const [page, next, prev] = usePagination(sharedCars, CARS_PER_PAGE);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => index(settings.demoCar).then((result: boolean) => setIsLoading(!result)), []);

  return (
    <HomeSection icon={icons.sharedWithMe} title={t('home:shared_with_me')} viewAll={!_.isEmpty(sharedCars) ? paths.sharedWithMeCars : ''}>
      {isLoading
        ? <ScreenLoader />
        : (
          <>
            {_.gt(_.size(sharedCars), CARS_PER_PAGE) && (
            <Pagination next={next} prev={prev} />
            )}
            {_.isEmpty(sharedCars) && <EmptyMessage message={t('home:empty_shared_by_me')} />}
            {_.map((sharedWithMeCar: SharedCarAttributes) => (
              <SharedWithMeCard
                key={sharedWithMeCar.car.id}
                car={sharedWithMeCar.car}
                accessLevel={sharedWithMeCar.accessLevel}
                pending={sharedWithMeCar.pending}
              />
            ))(page)}
          </>
        )}
    </HomeSection>
  );
};

export default SharedWithMe;
