import { useParams } from 'react-router-dom';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';

import { useStoreActions } from '../../../../store/hooks';
import useNavigation from '../../../../services/hooks/navigation';
import { emptyEvent } from '../../../../store/types/event';
import { isValidResponse } from '../../../../api/axios';
import { paths } from '../../../../lib/routing';
import AppLoader from '../../../../components/loaders/AppLoader';
import Authenticated from '../../../../components/layout/Authenticated';
import TextContainer from '../../../../components/TextContainer';
import date from '../../../../services/date';
import Back from '../../../../components/layout/components/Back';
import PopupGallery from '../../../../components/PopupGallery';
import Image from '../../../../components/Image';
import icons from '../../../../assets/images/icons';
import Serviced from '../timeline/event/components/Serviced';

import Summary from './info/components/Summary';

type RouteParams = {
  carID: string;
  id: string;
}

type Props = {
  service?: boolean;
  readonly?: boolean;
}

const MAX_NOTES_CHARACTERES = 200;

const ShowEvent: FunctionComponent<Props> = ({ service = false, readonly = false }: Props) => {
  const { carID, id } = useParams<RouteParams>();
  const { show: edit } = useStoreActions((actions) => actions.events);
  const { flash } = useStoreActions((actions) => actions);
  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState(emptyEvent);
  const navigate = useNavigation();
  const { t } = useTranslation();
  const carIDnumber : number = parseInt(carID, 10);
  const [readMore, setReadMore] = useState(false);

  useEffect(() => {
    (async () => {
      const eventResponse: AxiosResponse = await edit({ carID, id });
      if (isValidResponse(eventResponse)) {
        setEvent(eventResponse.data);
        setIsLoading(false);
        return;
      }
      if (!isValidResponse(eventResponse)) flash.set({ value: eventResponse.data, type: 'error' });
      navigate(paths.home);
    })();
  }, []);

  const serviceCar = (): string => paths.serviceCar.edit(carIDnumber);
  const readonlyCar = (): string => paths.sharedCar(carIDnumber);
  const accessCar = (): string => (service ? serviceCar() : readonlyCar());
  const myCar = (): string => paths.car.edit(carIDnumber);
  const backURL = (): string => (service || readonly ? accessCar() : myCar());

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <Authenticated>
      <Back url={backURL()} />
      <div className="py-16">
        {!_.isEmpty(event.servicedBy.name) && (
        <Serviced servicedBy={event.servicedBy} />
        )}
        <div className="flex items-baseline">
          {event.isHighlighted && <Image src={icons.star.filled} styles="h-16 w-16 mr-4" />}
          <TextContainer tag="h1" size="one-line-large" font="montserrat-bold">
            {event.title}
          </TextContainer>
        </div>
        <TextContainer styles={event.isHighlighted ? 'ml-4 pl-16' : ''}>
          {date.format(event.date)}
        </TextContainer>
        {!_.isEqual(event.cost, 0) && <TextContainer>{`${t('event:cost')} ${event.cost}`}</TextContainer>}
        {!_.isEqual(event.mileage, 0) && <TextContainer>{`${t('event:mileage')} ${event.mileage}`}</TextContainer>}
        <TextContainer>
          {readMore
            ? event.notes
            : _.truncate({ length: MAX_NOTES_CHARACTERES, omission: '[...]' })(event.notes)}
          {_.gt(event.notes.length, MAX_NOTES_CHARACTERES) && (
            <button type="button" className="text-primary underline" onClick={() => setReadMore(!readMore)}>
              {readMore ? t('read_less') : t('read_more')}
            </button>
          )}
        </TextContainer>
        <div className="pt-12 lg:flex border-t border-primary">
          <div className="mb-12 lg:w-screen-initial lg:mb-0 lg:px-2">
            <Summary selection={event.eventCategories || []} title={false} />
          </div>
          <div className="mb-12 lg:flex-1 lg:mb-0">
            <PopupGallery
              slides={event.documents || []}
              styles="show-thumbs"
              thumbSize="large"
            />
          </div>
        </div>
      </div>
    </Authenticated>
  );
};

export default ShowEvent;
