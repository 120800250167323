import _ from 'lodash/fp';

import { ViewerAttributes } from '../../store/types/viewers';

export const transfersPending = (viewers: ViewerAttributes[]): number => _.flow(
  _.filter((viewer: ViewerAttributes) => (
    _.isEqual(viewer.accessLevel, 'TRANSFER')
  )),
  _.size,
)(viewers);

export const viewersNumber = (viewers: ViewerAttributes[]): number => _.flow(
  _.filter((viewer: ViewerAttributes) => (
    _.isEqual(viewer.accessLevel, 'FULL') || _.isEqual(viewer.accessLevel, 'GARAGE')
  )),
  _.size,
)(viewers);

export const requestPending = (viewers: ViewerAttributes[]): number => _.flow(
  _.filter((viewer: ViewerAttributes) => viewer.pending),
  _.size,
)(viewers);

export const hasPendingRequests = (viewers: ViewerAttributes[]): boolean => (
  _.gt(requestPending(viewers), 0)
);

export const hasTransferPending = (viewers: ViewerAttributes[]): boolean => (
  _.gt(transfersPending(viewers), 0)
);
