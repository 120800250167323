import { useFormik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';

import SubmitButton from '../../../../components/buttons/SubmitButton';
import PasswordInput from '../../../../components/forms/PasswordInput';
import UnautorizedModal from '../../../../components/modals/UnauthorizedModal';
import TextContainer from '../../../../components/TextContainer';
import { useStoreActions } from '../../../../store/hooks';
import { AccountDelete } from '../../../../store/types/user';

const AccountDeletion: FunctionComponent = () => {
  const { destroy } = useStoreActions((actions) => actions.user);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const { t } = useTranslation();
  const [message, setMessage] = useState('');

  const handleOnSubmit = async (values: AccountDelete): Promise<void> => {
    const response = await destroy(values);
    setMessage(response);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: { password: '' },
    onSubmit: handleOnSubmit,
  });

  return (
    <>
      <div className="flex justify-center mb-12">
        <div onClick={(): void => setConfirmCancel(true)} className="w-full hover:opacity-50 cursor-pointer ml-4 py-2">
          <TextContainer color="text-red" size="one-line-regular" font="montserrat" styles="text-center">
            {t('profile:delete_account')}
          </TextContainer>
        </div>
      </div>
      <UnautorizedModal isOpen={confirmCancel} onClose={() => setConfirmCancel(false)}>
        <form onSubmit={handleSubmit} className="block p-16 w-full max-w-full">
          <TextContainer font="montserrat" styles="mb-6" color="text-red">
            {t('popups:sure_cancel_account')}
          </TextContainer>
          <PasswordInput
            name="password"
            value={values.password}
            placeholder={t('session:password')}
            onChange={handleChange('password')}
          />
          {!_.isEmpty(message) && (
          <TextContainer font="montserrat" styles="mb-6" color="text-red">
            {message}
          </TextContainer>
          )}

          <SubmitButton
            title={t('profile:delete_account')}
            disabled={isSubmitting}
          />
        </form>
      </UnautorizedModal>
    </>
  );
};

export default AccountDeletion;
