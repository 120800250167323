import React, { FunctionComponent } from 'react';

import icons from '../../../../assets/images/icons';
import Image from '../../../../components/Image';
import TextContainer from '../../../../components/TextContainer';
import i18n from '../../../../services/i18n';
import { CategoryAttributes } from '../../../../store/types/category';

type Props = {
  category: CategoryAttributes;
  onClick: () => void;
  isSelected: boolean;
  isActive: boolean;
}

const MajorCategory: FunctionComponent<Props> = (
  {
    category,
    onClick,
    isSelected,
    isActive,
  }: Props,
) => (
  <div
    className="flex items-center justify-between p-4 border border-primary-x-light rounded-md mb-4 cursor-pointer"
    onClick={onClick}
  >
    <div className="flex items-center">
      <div className="w-24 h-24 rounded-full bg-primary-x-light flex items-center justify-center relative mr-4">
        <Image src={category.icon} styles="w-12 h-12" />
        {isSelected && <Image src={icons.verified.blue} styles="absolute w-6 h-6 bottom-0 right-0" />}
      </div>
      <TextContainer color="text-primary" size="one-line-medium">
        {i18n.t(`categories:${category.name}`)}
      </TextContainer>
    </div>
    <div className="flex items-center">
      <div className={`min-w-2 h-12 mr-4 ${isActive && 'bg-red'}`} />
      <Image src={icons.angleRight.blue} styles={`w-6 h-6 ${isActive ? 'opacity-1' : 'opacity-0'}`} />
    </div>
  </div>
);

export default MajorCategory;
