import React, { FunctionComponent } from 'react';

import {
  ButtonSize,
  ButtonType,
} from '../../lib/style/button';

export type Props = {
  title: string;
  type?: ButtonType;
  size?: ButtonSize;
  styles?: string;
  disabled?: boolean;
}

const SubmitButton: FunctionComponent<Props> = (
  {
    title,
    type = 'regular',
    size = 'large',
    styles = '',
    disabled = false,
  }: Props,
) => (
  <button
    type="submit"
    className={`btn-${type} btn-${size} ${styles}`}
    disabled={disabled}
  >
    {title}
  </button>
);

export default SubmitButton;
