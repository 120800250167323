import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, Redirect } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import Main from '../components/layout/components/Main';
import { useStoreActions, useStoreState } from '../store/hooks';
import Header from '../components/layout/components/Header';
import { isValidResponse } from '../api/axios';
import useNavigation from '../services/hooks/navigation';
import { paths } from '../lib/routing';
import AppLoader from '../components/loaders/AppLoader';
import { carTitle } from '../lib/helpers/car';
import Content from '../components/layout/components/Content';
import FlashMessage from '../components/FlashMessage';
import Sidebar from '../components/layout/components/Sidebar';
import Footer from '../components/layout/components/Footer';
import ActionButton from '../components/buttons/ActionButton';
import Back from '../components/layout/components/Back';
import { emptySnapshotAttributes } from '../store/types/viewers';
import InfoModal from '../components/modals/InfoModal';

import PageHeader from './authenticated/components/PageHeader';
import Snapshot from './authenticated/car/snapshot/Snapshot';

type RouteParams = {
  id: string;
  token: string;
}

const SnapshotCarShare: FunctionComponent = () => {
  const { id, token } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState(true);
  const { show, create } = useStoreActions((actions) => actions.viewers);
  const { flash, user } = useStoreActions((actions) => actions);
  const { isLoggedIn } = useStoreState((state) => state.session);
  const { isVerified } = useStoreState((state) => state.user);
  const [snapshot, setSnapshot] = useState(emptySnapshotAttributes);
  const [isOpen, setIsOpen] = useState(false);
  const [fakeRedirect, setFakeRedirect] = useState(false);
  const location = useLocation();
  const navigate = useNavigation();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (isLoggedIn) await user.index();
      const response: AxiosResponse = await show({ carID: parseInt(id, 10), token });
      if (isValidResponse(response)) {
        setSnapshot(response.data);
        setIsLoading(false);
        return;
      }
      navigate(isLoggedIn ? paths.home : paths.root);
      flash.set({ type: 'error', value: response.data });
    })();
  }, []);

  const onCreate = async (): Promise<void> => {
    create({
      carID: parseInt(id, 10),
      accessLevel: 'SNAPSHOT',
      pending: true,
      token,
    }).then((response: AxiosResponse) => {
      if (isValidResponse(response)) {
        navigate(paths.snapshot(snapshot.car.id));
        return;
      }
      flash.set({ type: 'error', value: response.data });
    });
  };

  const onAccessHistory = (): void => {
    if (!isLoggedIn) {
      setFakeRedirect(true);
      return;
    }
    if (!isVerified) {
      setIsOpen(true);
      return;
    }

    onCreate();
  };

  if (isLoading) {
    return <AppLoader />;
  }

  const title = (
    <PageHeader
      title={carTitle(snapshot.car)}
      graphic="car"
    />
  );

  return (
    <Main>
      <Header authenticated={isLoggedIn} title={title} />
      <Content>
        <FlashMessage />
        <Back url={isLoggedIn ? paths.home : paths.root} />
        <InfoModal
          isOpen={isOpen}
          onAccept={() => navigate(paths.user.profile)}
          onReject={() => setIsOpen(false)}
          message={t('popups:needs_verification')}
          onAcceptMessage={t('authenticated:profile')}
        />
        {!isLoggedIn && fakeRedirect && (
          <Redirect
            to={{
              pathname: paths.signin,
              state: { from: location },
            }}
          />
        )}
        <Snapshot car={snapshot.car} currentEvents={snapshot.events} />
        <div className="max-w-screen-lg mx-auto w-full flex flex-col items-center justify-between py-8">
          <ActionButton
            size="medium"
            title={t('share:request_full_access')}
            onClick={onAccessHistory}
            styles="mb-4"
          />
          <ActionButton
            size="medium"
            type="outline"
            title={t('share:dismiss')}
            onClick={isLoggedIn ? () => navigate(paths.home) : () => navigate(paths.root)}
          />
        </div>
      </Content>
      {isLoggedIn && <Sidebar />}
      <Footer styles="mb-8" />
    </Main>
  );
};

export default SnapshotCarShare;
