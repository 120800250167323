import React, { useState, FunctionComponent } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';

import icons from '../assets/images/icons';

import Image from './Image';
import ActionButton from './buttons/ActionButton';

type Props = {
  value: string;
  openValue: string;
  deleteDocument?: () => void;
}

const PopupPreview: FunctionComponent<Props> = (
  {
    value,
    openValue,
    deleteDocument,
  }: Props,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const popupClass = classnames(
    'fixed z-50 w-full h-screen bg-background top-0 left-0 bg-white', {
      hidden: !isOpen,
    },
  );

  return (
    <>
      <div
        className="gallery"
        onClick={() => setIsOpen(true)}
        style={{ backgroundImage: `url(${value})` }}
      />
      <div className={popupClass}>
        <div className="absolute wrap h-9/10 max-h-9/10 top-1/20 left-1/20 flex flex-col" onClick={() => setIsOpen(false)}>
          <div className="absolute top-0 right-0 z-10">
            <Image src={icons.cross.blue} styles="w-12 h-12 ml-auto" />
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
            <Image src={openValue} styles="mx-auto mb-2" />
            {!_.isUndefined(deleteDocument) && (
              <ActionButton
                type="alert"
                title={t('delete')}
                onClick={deleteDocument}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupPreview;
