import React, { FunctionComponent, ReactNode } from 'react';

import {
  TextColor,
  TextFont,
  TextSize,
  TextTag,
} from '../lib/style/typography';

export type Props = {
  children: ReactNode;
  tag?: TextTag;
  size?: TextSize;
  color?: TextColor;
  font?: TextFont;
  styles?: string;
}

const TextContainer: FunctionComponent<Props> = (
  {
    children,
    tag: Tag = 'p',
    size = 'text-regular',
    color = 'text-primary-dark',
    font = 'opensans',
    styles = '',
  }: Props,
) => (
  <Tag className={`${size} ${color} ${font} ${styles}`}>
    {children}
  </Tag>
);

export default TextContainer;
