import { FormikErrors } from 'formik';
import React, {
  useState,
  FunctionComponent,
  ChangeEvent,
  useEffect,
  useRef,
} from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { CategoryFamilyAttributes, SubcategoryAttributes } from '../../../../../store/types/category';
import { EventParams } from '../../../../../store/types/event';
import {
  deleteMajorCategory,
  groupSubcategories,
  nameWithoutLineFeed,
  onChangeCategory,
} from '../../../../../lib/helpers/categories';
import MajorCategory from '../../categories/MajorCategory';
import MinorCategory from '../../categories/MinorCategory';
import TextContainer from '../../../../../components/TextContainer';
import Tag from '../../../../../components/Tag';
import icons from '../../../../../assets/images/icons';
import ActionButton from '../../../../../components/buttons/ActionButton';
import useQuery from '../../../../../services/hooks/query';

type Props = {
  categories: CategoryFamilyAttributes[];
  selection: CategoryFamilyAttributes[];
  setFieldValue: (
    field: string,
    value: CategoryFamilyAttributes[],
    shouldValidate?: boolean | undefined
    ) => Promise<void> | Promise<FormikErrors<EventParams>>;
  goForward: () => void;
}

const Categories: FunctionComponent<Props> = (
  {
    categories,
    selection,
    setFieldValue,
    goForward,
  }: Props,
) => {
  const query = useQuery();
  const [selected, setSelected] = useState<CategoryFamilyAttributes>(categories[0]);
  const { t } = useTranslation();
  const subcategoriesRef = useRef(null);

  useEffect(() => {
    const categoryIndex: string | null = query.get('category');
    if (!_.isNil(categoryIndex)) {
      setSelected(categories[parseInt(categoryIndex, 10)]);
    }
  }, []);

  const onSelectCategory = (categoryFamily: CategoryFamilyAttributes): void => {
    setSelected(categoryFamily);
    // @ts-ignore
    subcategoriesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const onClickCategory = (e: ChangeEvent<HTMLInputElement>): void => {
    setFieldValue('eventCategories', onChangeCategory(e, selection, categories));
  };

  const onDeleteMajorCategory = (id: number): void => {
    setFieldValue('eventCategories', deleteMajorCategory(id, selection));
  };

  // @ts-ignore
  const isMajorSelected = (id: number): boolean => _.flow(
    _.flatMap('category'),
    _.findIndex(['id', id]),
    _.gte(_, 0),
  )(selection);

  // @ts-ignore
  const isMinorSelected = (id: number): boolean => _.flow(
    _.flatMap('subcategories'),
    _.findIndex(['id', id]),
    _.gte(_, 0),
  )(selection);

  return (
    <div className="expand categories xl:pr-2 hd:pr-0">
      <div className="xl:flex">
        <div className="xl:w-screen-initial xl:px-2">
          <TextContainer tag="h3" size="one-line-medium" font="montserrat" styles="mb-16">
            {t('add_event:select_categories')}
          </TextContainer>
          {_.map((categoryFamily: CategoryFamilyAttributes) => (
            <MajorCategory
              key={categoryFamily.category.id}
              category={categoryFamily.category}
              onClick={() => onSelectCategory(categoryFamily)}
              isSelected={isMajorSelected(categoryFamily.category.id)}
              isActive={_.isEqual(categoryFamily.category.id, selected.category.id)}
            />
          ))(categories)}
        </div>
        <div className="xl:flex-1 xl:px-2" ref={subcategoriesRef}>
          <TextContainer font="opensans-bold" styles="mb-16">
            {t('add_event:category_options', { category: t(`categories:${selected.category.name}`) })}
          </TextContainer>
          <div className="flex flex-wrap">
            {_.map((subcategory: SubcategoryAttributes) => (
              <MinorCategory
                key={subcategory.id}
                categoryID={selected.category.id}
                subcategory={subcategory}
                onChange={onClickCategory}
                isSelected={isMinorSelected(subcategory.id)}
              />
            ))(selected.subcategories)}
          </div>
        </div>
        <div className="xl:w-128 xl:px-2 flex flex-col justify-between">
          <div className="">
            {_.map((category: CategoryFamilyAttributes) => (
              <div key={category.category.id} className="mb-4">
                <Tag
                  text={nameWithoutLineFeed(category.category.name)}
                  type="dark-blue"
                  icon={icons.cross.white}
                  action={() => onDeleteMajorCategory(category.category.id)}
                  styles="mb-4"
                />
                <TextContainer size="text-small">
                  {groupSubcategories(category.subcategories)}
                </TextContainer>
              </div>
            ))(_.reverse(selection))}
          </div>
          <div className="mt-4 flex justify-end w-full">
            <ActionButton
              title={t('continue')}
              size="large"
              onClick={_.isEmpty(selection) ? undefined : goForward}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
