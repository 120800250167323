import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  children: ReactNode;
}

const Section: FunctionComponent<Props> = ({ children }: Props) => (
  <div className="border-t border-primary md:flex md:items-stretch py-16">
    {children}
  </div>
);

export default Section;
