import React, { FunctionComponent } from 'react';

import TextContainer from '../../../../components/TextContainer';

type Props = {
  number: number;
  name: string;
  styles: string;
}

const MajorCategoryCounter: FunctionComponent<Props> = ({ number, name, styles }: Props) => (
  <div className={styles}>
    <TextContainer size="one-line-medium" font="montserrat-bold" styles="w-full border-b border-primary-light relative text-center py-4">
      {number}
      <span className="absolute bottom-0 left-0 h-2 w-24 bg-primary-dark" />
    </TextContainer>
    <TextContainer color="text-primary" styles="text-center py-4">
      {name}
    </TextContainer>
  </div>
);

export default MajorCategoryCounter;
