import React, { FunctionComponent } from 'react';

import { CategoryFamilyAttributes } from '../../../store/types/category';
import Image from '../../../components/Image';
import TextContainer from '../../../components/TextContainer';
import { groupSubcategories, nameWithoutLineFeed } from '../../../lib/helpers/categories';
import { TextSize } from '../../../lib/style/typography';

type Props = {
  category: CategoryFamilyAttributes;
  styles?: string;
  textStyles?: string;
  subcategoriesSize?: TextSize;
}

const SummaryItem: FunctionComponent<Props> = (
  {
    category,
    styles,
    textStyles,
    subcategoriesSize = 'one-line-small',
  }: Props,
) => (
  <div className={`flex ${styles}`}>
    <div className="min-w-16 max-w-16 w-16 h-16 rounded-full bg-primary-x-light flex items-center justify-center mr-2">
      <Image src={category.category.icon} styles="h-8" />
    </div>
    <div className={textStyles}>
      <TextContainer size="one-line-regular" font="montserrat">
        {nameWithoutLineFeed(category.category.name)}
      </TextContainer>
      <TextContainer size={subcategoriesSize}>
        {groupSubcategories(category.subcategories)}
      </TextContainer>
    </div>
  </div>
);

export default SummaryItem;
