import { createStore, EasyPeasyConfig, Store } from 'easy-peasy';
import _ from 'lodash/fp';

import * as sessionService from '../api/services/session';
import * as userService from '../api/services/user';
import * as settingsService from '../api/services/settings';
import * as passwordsService from '../api/services/passwords';
import * as carsService from '../api/services/cars';
import * as eventsService from '../api/services/events';
import * as categoriesService from '../api/services/categories';
import * as documentsService from '../api/services/documents';
import * as sharedService from '../api/services/shared';
import * as viewersService from '../api/services/viewers';
import * as paymentsService from '../api/services/payments';

import model from './model';

export const buildStore = (overrideConfig: EasyPeasyConfig = {}): Store => {
  const defaultConfig = {
    injections: {
      sessionService,
      userService,
      settingsService,
      passwordsService,
      carsService,
      eventsService,
      categoriesService,
      documentsService,
      sharedService,
      viewersService,
      paymentsService,
    },
  };

  const config = _.defaultsDeep(defaultConfig, overrideConfig);

  if (process.env.NODE_ENV === 'development') {
    return createStore(model, {
      devTools: true,
      ...config,
    });
  }

  return createStore(model, config);
};

export default buildStore;
