import React, { FunctionComponent } from 'react';

import Card from '../components/Card';
import Image from '../../../components/Image';
import graphics from '../../../assets/images/graphics';
import TextContainer from '../../../components/TextContainer';
import i18n from '../../../services/i18n';
import LinkButton from '../../../components/buttons/LinkButton';
import { paths } from '../../../lib/routing';
import Unauthenticated from '../../../components/layout/Unauthenticated';

import OnboardingSlider from './components/OnboardingSlider';

const Onboarding: FunctionComponent = () => (
  <Unauthenticated>
    <Card>
      <Image
        src={graphics.logo}
        alt="The Motor Chain | TMC"
        styles="mb-12 h-24"
      />
      <TextContainer
        tag="h1"
        size="text-large"
        color="text-black"
        font="montserrat-bold"
        styles="uppercase text-center max-w-screen-initial"
      >
        {i18n.t('onboarding:welcome_to_the_motor_chain')}
      </TextContainer>
      <OnboardingSlider />
      <div className="pt-24 flex flex-col">
        <LinkButton
          title={i18n.t('unauthenticated:login')}
          to={paths.signin}
          styles="mb-6"
        />
        <LinkButton
          title={i18n.t('unauthenticated:signup')}
          to={paths.signup}
          type="outline"
        />
      </div>
    </Card>
  </Unauthenticated>
);

export default Onboarding;
