import React, { FunctionComponent } from 'react';
import Loader from 'react-loaders';

import 'loaders.css/loaders.css';

const SubmitLoader: FunctionComponent = () => (
  <div className="fixed bg-background w-full h-full flex justify-center items-center z-50 top-0 left-0">
    <div className="wrap flex flex-col items-center">
      <Loader
        type="line-scale-pulse-out"
        innerClassName="loader-screen"
        active
      />
    </div>
  </div>
);

export default SubmitLoader;
