import React, { useState, FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import _ from 'lodash/fp';

import { useStoreActions } from '../../../../store/hooks';
import { emptySharedCarAttributes } from '../../../../store/types/shared';
import useNavigation from '../../../../services/hooks/navigation';
import { isValidResponse } from '../../../../api/axios';
import { paths } from '../../../../lib/routing';
import Authenticated from '../../../../components/layout/Authenticated';
import PageHeader from '../../components/PageHeader';
import { carTitle } from '../../../../lib/helpers/car';
import Snapshot from '../snapshot/Snapshot';
import AppLoader from '../../../../components/loaders/AppLoader';
import Back from '../../../../components/layout/components/Back';

type RouteParams = {
  id: string;
}

const SnapshotCar: FunctionComponent = () => {
  const { id } = useParams<RouteParams>();
  const { show } = useStoreActions((actions) => actions.shared);
  const { flash } = useStoreActions((actions) => actions);
  const [isLoading, setIsLoading] = useState(true);
  const [sharedCar, setSharedCar] = useState(emptySharedCarAttributes);
  const navigate = useNavigation();

  useEffect(() => {
    show(id)
      .then((response: AxiosResponse) => {
        if (isValidResponse(response)) {
          setSharedCar(response.data);
          setIsLoading(false);
          return;
        }
        flash.set({ type: 'error', value: response.data });
        navigate(paths.home);
      });
  }, []);

  if (!isLoading && _.isEqual(sharedCar.accessLevel, 'GARAGE')) {
    navigate(paths.serviceCar.edit(sharedCar.car.id));
  }

  if (!isLoading && _.isEqual(sharedCar.accessLevel, 'FULL')) {
    navigate(paths.sharedCar(sharedCar.car.id));
  }

  if (isLoading) {
    return <AppLoader />;
  }

  const title = (
    <PageHeader
      title={carTitle(sharedCar.car, 33)}
      graphic="car"
    />
  );

  return (
    <Authenticated title={title}>
      <Back url={paths.home} />
      <Snapshot car={sharedCar.car} />
    </Authenticated>
  );
};

export default SnapshotCar;
