import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import i18n from '../../../../../../services/i18n';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  eventURL: string;
}

const Container: FunctionComponent<Props> = ({
  isOpen, children, onClose, eventURL,
}: Props) => (
  <div className={`${isOpen ? 'flex' : 'hidden'} show-event-container`}>
    <div className="wrap">
      <div className="show-event-card">
        <div className="p-6">
          {children}
        </div>
        <div className="flex items-center justify-end p-6">
          <Link to={eventURL} className="block mr-8 text-primary">
            {i18n.t('timeline:open')}
          </Link>
          <button type="button" className="text-primary" onClick={onClose}>
            {i18n.t('close')}
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default Container;
