import React, { FunctionComponent, useState } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { EventAttributes, YearEvents } from '../../../../../store/types/event';
import Year from '../components/Year';
import { eventsByYear } from '../../../../../lib/helpers/timeline';
import useAssistant from '../../../../../services/hooks/assistant';
import Image from '../../../../../components/Image';
import icons from '../../../../../assets/images/icons';
import Tag from '../../../../../components/Tag';
import ConfirmModal from '../../../../../components/modals/ConfirmModal';
import TextContainer from '../../../../../components/TextContainer';
import { numberOfEventsFound } from '../../../../../lib/helpers/categories';

type Props = {
  carID: number;
  events: EventAttributes[];
  onClickEvent: (event: EventAttributes) => void;
  readOnly: boolean;
  service: boolean;
  onOpenFilter: () => void;
  filter: string;
  onClearFilter: () => void;
}

const TimelineSection: FunctionComponent<Props> = (
  {
    carID,
    events,
    onClickEvent,
    readOnly,
    service,
    onOpenFilter,
    filter,
    onClearFilter,
  }: Props,
) => {
  const { t } = useTranslation();
  const {
    isHide,
    onClose,
    onShow,
  } = useAssistant(carID);
  const [isOpen, setIsOpen] = useState(false);
  const isAssistantTag = isHide && !readOnly && !service;

  return (
    <div className="expand items-center xl:w-1/2 xl:mt-12 xl:border-r border-primary-light relative xl:overflow-y-scroll">
      <div className="flex justify-end w-full xs:max-w-screen-xs mb-4">
        {!_.isEmpty(filter) && (
          <Tag
            text={`${t(`categories:${filter}`)}(${numberOfEventsFound(filter, events)})`}
            type="dark-blue"
            icon={icons.cross.white}
            action={onClearFilter}
          />
        )}
        <div onClick={onOpenFilter} className="ml-2">
          <TextContainer font="montserrat" styles="cursor-pointer">
            {t('timeline:search')}
          </TextContainer>
        </div>
      </div>
      {isAssistantTag && (
        <div className="absolute z-1 right-0 top-16 w-full">
          <div className="flex items-center justify-end w-full max-w-screen-xs mx-auto">
            <div className="cursor-pointer flex items-center">
              <Image src={icons.assistant.blue} styles="w-12 h-12" />
              <Tag type="blue" text={t('timeline:assistant_mode')} action={onShow} />
            </div>
            <button type="button" onClick={() => setIsOpen(true)} className="block ml-4">
              <Image src={icons.cross.blue} styles="w-8 h-8" />
            </button>
            <ConfirmModal
              isOpen={isOpen}
              message={t('popups:closing_assistant_advice')}
              onAccept={onClose}
              onAcceptMessage={t('popups:remove_assistant')}
              onReject={() => setIsOpen(false)}
              onRejectMessage={t('popups:keep_assistant')}
            />
          </div>
        </div>
      )}
      <div className="w-full flex flex-col items-center xl:absolute xl:top-12 xl:left-0">
        {_.map((year: YearEvents) => (
          <Year
            key={year.year}
            year={year}
            onClickEvent={onClickEvent}
            filter={filter}
          />
        ))(eventsByYear(events) as YearEvents[])}
      </div>
    </div>
  );
};

export default TimelineSection;
