import _ from 'lodash/fp';

import date from '../../services/date';
import { CarAttributes } from '../../store/types/car';
import { DocumentURL } from '../../store/types/document';
import { EventAttributes } from '../../store/types/event';

import { getDocument, isPDFDocument, isPDFURL } from './documents';

const allCategories = _.flow(
  _.flatMap('eventCategories'),
  _.flatMap('category'),
);

const allSubcategories = _.flow(
  _.flatMap('eventCategories'),
  _.flatMap('subcategories'),
);

export const eventParams = [
  'id',
  'title',
  'notes',
  'cost',
  'mileage',
  'isHighlighted',
  'isDraft',
];

export const isNewEvent = (id: number): boolean => (
  _.lt(id, 0)
);

export const minimumEventDate = (car: CarAttributes): Date | string => (
  !date.isNullDate(car.firstRegistration)
    ? car.firstRegistration
    : date.yearToDate(car.manufactureYear)
);

export const setFirstImage = (documents: DocumentURL[]): string => {
  const documentImages = _.filter((document: DocumentURL) => (
    !isPDFDocument(document)
  ))(documents);

  return _.isEmpty(documentImages) ? '' : getDocument(documentImages[0], 'thumb');
};

export const categorySize = (id: number, events: EventAttributes[]): number => _.flow(
  _.filter(['id', id]),
  _.size,
)(allCategories(events));

export const subcategorySize = (id: number, events: EventAttributes[]): number => _.flow(
  _.filter(['id', id]),
  _.size,
)(allSubcategories(events));

export const documentURLs = (documents: DocumentURL[]): string[] => _.flow(
  _.filter((document: DocumentURL) => !isPDFURL(document.full)),
  _.map('full'),
)(documents);
