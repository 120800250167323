import React, { FunctionComponent, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import { routes } from '../lib/routing';

import PublicScreen from './PublicScreen';
import Onboarding from './unauthenticated/onboarding/Onboarding';
import NewPassword from './unauthenticated/password/NewPassword';
import Signin from './unauthenticated/Signin';
import Signup from './unauthenticated/Signup';
import EditPassword from './unauthenticated/password/EditPassword';
import PrivateScreen from './PrivateScreen';
import Home from './authenticated/home/Home';
import NewCar from './authenticated/car/my-car/NewCar';
import EditCar from './authenticated/car/my-car/EditCar';
import ViewCar from './authenticated/car/shared-with-me/ViewCar';
import Profile from './authenticated/user/Profile';
import Settings from './authenticated/user/Settings';
import Invitations from './authenticated/user/Invitations';
import SharePanel from './authenticated/share-panel/SharePanel';
import ServiceCar from './authenticated/car/shared-with-me/ServiceCar';
import NewEvent from './authenticated/car/event/NewEvent';
import EditEvent from './authenticated/car/event/EditEvent';
import Payment from './authenticated/car/payments/Payment';
import MyCars from './authenticated/home/my-cars/MyCars';
import ShowEvent from './authenticated/car/event/ShowEvent';
import ServiceCarShare from './authenticated/share/ServiceCarShare';
import TransferCarShare from './authenticated/share/TransferCarShare';
import SnapshotCarShare from './SnapshotCarShare';
import SnapshotCar from './authenticated/car/shared-with-me/SnapshotCar';
import SharedWithMeCars from './authenticated/home/shared-with-me/SharedWithMeCars';
import MobilePayment from './authenticated/car/payments/MobilePayment';
import PaymentCheckout from './authenticated/car/payments/PaymentCheckout';
import Subscriptions from './authenticated/subscription/Subscription';

const Routing: FunctionComponent = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);
  return (
    <Switch>
      <PublicScreen exact path={routes.root}>
        <Onboarding />
      </PublicScreen>
      <PublicScreen exact path={routes.signin}>
        <Signin />
      </PublicScreen>
      <PublicScreen exact path={routes.signup}>
        <Signup />
      </PublicScreen>
      <PublicScreen exact path={routes.password.new}>
        <NewPassword />
      </PublicScreen>
      <PublicScreen exact path="/reset/:email/:token">
        <EditPassword />
      </PublicScreen>

      <PrivateScreen exact path={routes.home}>
        <Home />
      </PrivateScreen>
      <PrivateScreen exact path={routes.subscriptions}>
        <Subscriptions />
      </PrivateScreen>
      <PrivateScreen exact path={routes.myCars}>
        <MyCars />
      </PrivateScreen>
      <PrivateScreen exact path={routes.sharedWithMeCars}>
        <SharedWithMeCars />
      </PrivateScreen>

      <PrivateScreen exact path={routes.car.new}>
        <NewCar />
      </PrivateScreen>
      <PrivateScreen exact path={routes.car.edit}>
        <EditCar />
      </PrivateScreen>
      <PrivateScreen exact path={routes.events.new}>
        <NewEvent />
      </PrivateScreen>
      <PrivateScreen exact path={routes.events.edit}>
        <EditEvent />
      </PrivateScreen>
      <PrivateScreen exact path={routes.events.show}>
        <ShowEvent />
      </PrivateScreen>

      <PrivateScreen exact path={routes.car.subscribe}>
        <Payment />
      </PrivateScreen>
      <PrivateScreen exact path={routes.mobileSubscribe}>
        <MobilePayment />
      </PrivateScreen>
      <PublicScreen exact path={routes.paymentCheckout}>
        <PaymentCheckout />
      </PublicScreen>

      <PrivateScreen exact path={routes.sharedCar}>
        <ViewCar />
      </PrivateScreen>
      <PrivateScreen exact path={routes.sharedCarEvent}>
        <ShowEvent readonly />
      </PrivateScreen>

      <PrivateScreen exact path={routes.serviceCar.edit}>
        <ServiceCar />
      </PrivateScreen>
      <PrivateScreen exact path={routes.serviceCar.events.new}>
        <NewEvent service />
      </PrivateScreen>
      <PrivateScreen exact path={routes.serviceCar.events.edit}>
        <EditEvent service />
      </PrivateScreen>
      <PrivateScreen exact path={routes.serviceCar.events.show}>
        <ShowEvent service />
      </PrivateScreen>

      <PrivateScreen exact path={routes.sharePanel}>
        <SharePanel />
      </PrivateScreen>

      <PrivateScreen exact path={routes.user.profile}>
        <Profile />
      </PrivateScreen>
      <PrivateScreen exact path={routes.user.settings}>
        <Settings />
      </PrivateScreen>
      <PrivateScreen exact path={routes.user.invitations}>
        <Invitations />
      </PrivateScreen>

      <PrivateScreen exact path={routes.snapshot}>
        <SnapshotCar />
      </PrivateScreen>
      <PrivateScreen exact path={routes.share.service}>
        <ServiceCarShare />
      </PrivateScreen>
      <PrivateScreen exact path={routes.share.transfer}>
        <TransferCarShare />
      </PrivateScreen>

      <Route exact path={routes.share.snapshot} component={SnapshotCarShare} />
    </Switch>
  );
};

export default Routing;
