import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import { EventAttributes, MonthEvents } from '../../../../../store/types/event';
import TextContainer from '../../../../../components/TextContainer';
import date from '../../../../../services/date/index';
import {
  isFiltered,
  getMinorCategories,
  getMajorCategories,
} from '../../../../../lib/helpers/categories';

import Event from './Event';

type Props = {
  month: MonthEvents;
  onClickEvent: (event: EventAttributes) => void;
  filter: string;
}

const minorCategories = _.flow(
  _.flatMap('eventCategories'),
  getMinorCategories,
);

const majorCategories = _.flow(
  _.flatMap('eventCategories'),
  getMajorCategories,
);

const orderByDate = _.flow(
  _.sortBy(['date']),
  _.reverse,
);

const joinCategories = (month: MonthEvents): string[] => (
  _.concat(minorCategories(month.events), majorCategories(month.events))
);

const Month: FunctionComponent<Props> = ({ month, onClickEvent, filter }: Props) => (
  <div className={`month ${isFiltered(filter, joinCategories(month)) ? 'filtered' : ''}`}>
    <div className="month-title">
      <TextContainer styles="uppercase py-4" font="opensans-bold" size="one-line-regular">
        {date.month(month.month)}
      </TextContainer>
    </div>
    <div className="pl-8 py-8 w-full">
      {_.map((event: EventAttributes) => (
        <Event
          key={event.id}
          event={event}
          onClickEvent={onClickEvent}
          filter={filter}
        />
        // @ts-ignore
      ))(orderByDate(month.events))}
    </div>
  </div>
);

export default Month;
