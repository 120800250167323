import _ from 'lodash/fp';
import React, { ChangeEvent, FunctionComponent } from 'react';

import icons from '../../assets/images/icons';
import { getDocument } from '../../lib/helpers/documents';
import { onChangeFile } from '../../lib/helpers/forms';
import { DocumentName, DocumentURL } from '../../store/types/document';
import Badge from '../Badge';
import Image from '../Image';
import PopupPreview from '../PopupPreview';

import FormInput from './components/FormInput';
import InputError from './components/InputError';
import InputLabel from './components/InputLabel';

type Props = {
  name: DocumentName;
  value: string;
  currentValue: DocumentURL;
  placeholder?: string;
  handleDocumentChange: (name: DocumentName, value: File | FileList) => Promise<void>;
  deleteDocument?: (name: DocumentName, value: string) => void;
  type: 'part-number' | 'profile';
  errors?: string;
  readonly?: boolean;
  required?: boolean;
}

const FileInput: FunctionComponent<Props> = (
  {
    name,
    value,
    currentValue,
    placeholder = '',
    handleDocumentChange,
    deleteDocument,
    type,
    errors = '',
    readonly = false,
    required = false,
  }: Props,
) => (
  <FormInput styles={type}>
    <InputLabel name={name} placeholder={placeholder} />
    <input
      type="file"
      name={name}
      id={name}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const result = onChangeFile(e);
        if (!_.isUndefined(result)) {
          handleDocumentChange(name, result);
        }
      }}
      readOnly={readonly}
      disabled={readonly}
      className="hidden"
      accept="image/png, image/jpeg"
    />
    {!_.isEmpty(value) && (
      <PopupPreview
        value={value}
        openValue={value}
        deleteDocument={
          !_.isUndefined(deleteDocument)
            ? () => deleteDocument(name, value)
            : undefined
        }
      />
    )}
    {_.isEmpty(value) && !_.isEmpty(currentValue.full) && (
      <PopupPreview
        value={getDocument(currentValue, 'thumb')}
        openValue={currentValue.full}
      />
    )}
    {!readonly && (
      <label htmlFor={name} className="icon">
        {required && (_.isEmpty(value) && _.isEmpty(currentValue.full)) && (
          <Badge type="red" styles="badge" />
        )}
        <Image src={icons.camera} styles="camera" />
      </label>
    )}
    <InputError errors={errors} />
  </FormInput>
);

export default FileInput;
