import React, { FunctionComponent, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import _ from 'lodash/fp';

import Authenticated from '../../../../components/layout/Authenticated';
import PageHeader from '../../components/PageHeader';
import { useStoreActions } from '../../../../store/hooks';
import { isValidResponse } from '../../../../api/axios';
import Image from '../../../../components/Image';
import graphics from '../../../../assets/images/graphics';
import TextContainer from '../../../../components/TextContainer';
import icons from '../../../../assets/images/icons';
import { PaymentData, PaymentParams, PaymentSchema } from '../../../../store/types/payments';
import useNavigation from '../../../../services/hooks/navigation';
import { paths } from '../../../../lib/routing';
import useQuery from '../../../../services/hooks/query';
import TextInput from '../../../../components/forms/TextInput';
import ActionButton from '../../../../components/buttons/ActionButton';
import SubmitButton from '../../../../components/buttons/SubmitButton';
import SubmitLoader from '../../../../components/loaders/SubmitLoader';

type RouteParams = {
  id: string;
}

const loadStripePromise = loadStripe(process.env.REACT_APP_TMC_STRIPE_PUBLIC_KEY as string);

const setCurrentcy = (discountType: string): string => (
  _.isEqual('percent', discountType) ? '%' : '€'
);

const MobilePayment: FunctionComponent = () => {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation();
  const { create, promotionalCode } = useStoreActions((actions) => actions.payments);
  const { destroy } = useStoreActions((actions) => actions.session);
  const { flash } = useStoreActions((actions) => actions);
  const [message, setMessage] = useState('');
  const navigate = useNavigation();
  const query = useQuery();

  const onStripe = async (data: PaymentSchema): Promise<void> => {
    const stripe = await loadStripePromise;
    const result = await stripe?.redirectToCheckout({ sessionId: data.sessionID });
    if (result?.error) {
      flash.set({ value: result.error.message || '', type: 'error' });
    }
  };

  const handleResponse = async (data: PaymentSchema): Promise<void> => {
    if (data.free) {
      await destroy();
      navigate(paths.paymentCheckout);
      return;
    }
    await onStripe(data);
  };

  const handleOnSubmit = async (data: PaymentData): Promise<void> => {
    const params: PaymentParams = {
      type: 'subscribe',
      carID: id,
      promotionalCode: data.promotionalCode,
      from: 'mobile',
      influencerCode: data.influencerCode,
    };

    const response: AxiosResponse = await create(params);
    if (!isValidResponse(response)) {
      flash.set({ value: response.data, type: 'error' });
      return;
    }
    handleResponse(response.data);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      promotionalCode: '',
      influencerCode: '',
    },
    onSubmit: handleOnSubmit,
  });

  const checkPrmotionalCode = async (): Promise<void> => {
    const response: AxiosResponse = await promotionalCode(values.promotionalCode);
    if (!isValidResponse(response)) {
      setMessage(response.data);
      return;
    }

    const { type, quantity } = response.data;

    if (_.isEmpty(type)) {
      setMessage(t('payments:not_valid'));
      return;
    }
    setMessage(t('payments:discount', {
      number: quantity,
      symbol: setCurrentcy(type),
    }));
  };

  return (
    <Authenticated
      title={(
        <PageHeader
          title={t('payments:subscribe_my_car')}
          graphic="car"
        />
      )}
    >
      {isSubmitting && <SubmitLoader />}
      <div className="wrap">
        <form onSubmit={handleSubmit}>
          <p>{query.get('type')}</p>
          <div className="flex flex-col items-center py-8 my-24 border-t border-b border-primary md:flex-row md:items-stretch">
            <div className="w-full mb-4 md:mb-0 md:w-1/2 xl:border-r border-primary">
              <Image src={graphics.manDocument} styles="w-128 h-128 mx-auto xl:w-192 xl:h-192" />
            </div>
            <div className="w-full md:w-1/2 md:flex md:flex-col md:justify-center">
              <div className="w-full p-8 mx-auto border rounded-md max-w-screen-xs border-primary lg:py-12 xl:py-16 xl:px-12">
                <div className="flex">
                  <div className="flex-1">
                    <TextContainer font="montserrat" styles="mb-4">
                      {t('payments:subscribe_car')}
                    </TextContainer>
                    <TextContainer size="text-small" color="text-gray-3">
                      {t('payments:subscribe_car_description')}
                    </TextContainer>
                  </div>
                  <div className="w-12">
                    <Image src={icons.payments} styles="w-12 h-12" />
                  </div>
                </div>
                <TextInput
                  name="promotionalCode"
                  value={values.promotionalCode}
                  placeholder={t('payments:discount_code')}
                  onChange={handleChange('coupon')}
                />
                <ActionButton
                  type="outline"
                  title={t('payments:check_coupon')}
                  onClick={checkPrmotionalCode}
                />
              </div>
              <div className="w-full p-8 mx-auto border rounded-md max-w-screen-xs border-primary lg:py-12 xl:py-16 xl:px-12">
                <div className="flex">
                  <div className="flex-1">
                    <TextContainer font="montserrat" styles="mb-4">
                      {t('payments:influencer')}
                    </TextContainer>
                    <TextContainer size="text-small" color="text-gray-3">
                      {t('payments:influencer_description')}
                    </TextContainer>
                  </div>
                </div>
                <TextInput
                  name="influencerCode"
                  value={values.influencerCode}
                  placeholder={t('payments:discount_code')}
                  onChange={handleChange('influencerCode')}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center py-8 my-16 border-b border-primary md:flex-row">
            <div className="w-full mb-4 md:mb-0 md:w-1/2">
              {!_.isEmpty(message) && (
                <TextContainer color="text-red" font="montserrat-bold" size="text-medium">
                  {message}
                </TextContainer>
              )}
            </div>
            <div className="flex justify-end w-full md:w-1/2">
              <SubmitButton
                title={t('payments:subscribe_my_car')}
                disabled={isSubmitting}
              />
            </div>
          </div>
        </form>
      </div>
    </Authenticated>
  );
};

export default MobilePayment;
