import React, { FunctionComponent, ReactNode } from 'react';

import FlashMessage from '../FlashMessage';

import Content from './components/Content';
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './components/Main';
import Sidebar from './components/Sidebar';

export type Props = {
  children: ReactNode;
  title?: ReactNode;
}

const Authenticated: FunctionComponent<Props> = ({ children, title }: Props) => (
  <Main>
    <Header authenticated title={title} />
    <Content>
      <FlashMessage />
      {children}
    </Content>
    <Sidebar />
    <Footer styles="mb-8" />
  </Main>
);

export default Authenticated;
