import { CarAttributes, emptyCar } from './car';
import { emptyUser, UserAttributes } from './user';

export type SharedCarAttributes = {
  car: CarAttributes;
  accessLevel: string;
  pending: boolean;
  user: UserAttributes;
}

export const emptySharedCarAttributes: SharedCarAttributes = {
  car: emptyCar,
  accessLevel: 'SNAPSHOT',
  pending: false,
  user: emptyUser,
};
