import React, { ChangeEvent, FunctionComponent } from 'react';
import _ from 'lodash/fp';

import TextContainer from '../../../components/TextContainer';
import Image from '../../../components/Image';
import graphics, { PageGraphic } from '../../../assets/images/graphics';
import Switch from '../../../components/Switch';
import i18n from '../../../services/i18n';

export type Props = {
  title: string;
  graphic: PageGraphic
  state?: boolean;
  setState?: (e: ChangeEvent<HTMLInputElement>) => void;
  deletion?: () => void;
  deletionText?: string;
}

const graphicObject = {
  hand: graphics.carHand,
  car: graphics.carMan,
  document: graphics.manDocument,
  assistant: graphics.assistant,
  data: graphics.carData,
};

const PageHeader: FunctionComponent<Props> = (
  {
    title,
    graphic,
    state,
    setState,
    deletion,
    deletionText,
  }: Props,
) => (
  <div className="flex-1 pr-24 ml-2 md:pr-48 md:pt-12">
    <div className="page-header">
      <TextContainer
        tag="h2"
        size="one-line-medium"
        font="montserrat"
      >
        {title}
      </TextContainer>
      <div className="absolute top-full left-0 w-24 h-2 bg-primary-dark" />
      <Image src={_.get(graphic)(graphicObject)} styles="graphic" />
    </div>
    <div className="sm:flex items-center md:w-9/10 ml-auto max-w-256">
      {(!_.isUndefined(state) && !_.isUndefined(setState)) && (
      <Switch
        value={state}
        placeholder={i18n.t('add_event:draft_mode')}
        onChange={setState}
        styles="draft-mode"
        reverse
      />
      )}
      {!_.isUndefined(deletion) && (
      <div onClick={deletion} className="w-full hover:opacity-50 cursor-pointer ml-4 py-2">
        <TextContainer color="text-red" size="one-line-regular" font="montserrat" styles="text-right md:text-left">
          {deletionText}
        </TextContainer>
      </div>
      )}
    </div>
  </div>
);

export default PageHeader;
