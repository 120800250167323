import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash/fp';

import graphics from '../../../../../assets/images/graphics';

type Props = {
  url: string;
  thumbnail?: string;
  children: ReactNode;
}

const setThumbnail = (thumbnail: string): string => (
  _.isEmpty(thumbnail) ? graphics.defaultCarThumb : thumbnail
);

const BaseCard: FunctionComponent<Props> = ({ url, thumbnail = '', children }: Props) => (
  <Link to={url} className="flex items-stretch w-full max-w-192 h-48 rounded-md shadow-2xl mb-12">
    <div
      className="has-bg-image rounded-md h-48 w-48 min-w-48"
      style={{ backgroundImage: `url(${setThumbnail(thumbnail)})` }}
    />
    <div className="expand py-8 px-6 ">
      {children}
    </div>
  </Link>
);

export default BaseCard;
