import _ from 'lodash/fp';

import {
  DocumentBase64,
  DocumentSize,
  DocumentURL,
} from '../../store/types/document';

export const MAX_EVENT_DOCUMENTS = 10;

const getExtension = (name: string): string => (
  _.join('')(_.slice(_.lastIndexOf('.')(name) + 1, name.length)(name))
);

const getDataType = (value: string): string => (
  _.join('')(
    _.slice(
      _.indexOf('/')(value) + 1,
      _.indexOf(';')(value),
    )(value),
  )
);

const URLExtension = (name: string): string => (
  _.join('')(_.slice(_.lastIndexOf('.')(name) + 1, _.lastIndexOf('.')(name) + 4)(name))
);

export const isPdfBase64 = (value: string): boolean => (
  _.isEqual(getDataType(value), 'pdf')
);

export const isPDFURL = (value: string): boolean => (
  _.isEqual(URLExtension(value), 'pdf')
);

export const isPDFDocument = (document: DocumentURL): boolean => (
  _.isEqual(URLExtension(document.full), 'pdf')
);

export const isPDF = (base64Doc: DocumentBase64): boolean => (
  _.isEqual(getExtension(base64Doc.filename), 'pdf')
);

export const getBase64ImageSRC = (base64Doc: DocumentBase64): string => (
  `data:image/${getExtension(base64Doc.filename)};base64,${base64Doc.base64Data}`
);

export const getBase64Image = (image: string): string => {
  const index = _.indexOf(',')(image) + 1;
  const base64 = _.slice(index, image.length)(image);
  return _.join('')(base64);
};

export const saveDocument = async (file: File): Promise<DocumentBase64> => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = reject;
    reader.onloadend = () => {
      resolve({
        filename: file.name,
        base64Data: getBase64Image(reader.result as string),
      });
    };
  })
);

export const saveDocuments = async (files: FileList): Promise<DocumentBase64[]> => (
  Promise.all(Array.from(files).map(async (file: File) => saveDocument(file)))
);

export const deleteDocument = (value: string, selection: DocumentBase64[]): DocumentBase64[] => (
  _.filter((document: DocumentBase64) => (
    getBase64ImageSRC(document) !== value
  ))(selection)
);

export const deleteSavedDocument = (id: number, selection: DocumentURL[]): DocumentURL[] => (
  _.filter((document: DocumentURL) => (
    document.id !== id
  ))(selection)
);

export const getDocument = (document: DocumentURL, size: DocumentSize): string => (
  _.isEmpty(_.get(`${size}`)(document)) ? document.full : _.get(`${size}`)(document)
);

export const fileInputValue = (value: DocumentBase64): string => (
  !_.isEmpty(value.base64Data) ? getBase64ImageSRC(value) : ''
);

export const fileInputValues = (values: DocumentBase64[]): string[] => (
  !_.isEmpty(values)
    ? _.map((value: DocumentBase64) => getBase64ImageSRC(value))(values)
    : []
);

export const joinImages = (values: string[], current: DocumentURL[]): string[] => (
  _.concat(_.map((document: DocumentURL) => getDocument(document, 'full'))(current), values)
);

export const reachedMaxImages = (
  values: string[],
  current: DocumentURL[],
  max: number,
): boolean => (
  _.gt(_.size(joinImages(values, current)), max)
);

export const reachedWithNewImages = (
  values: string[],
  current: DocumentURL[],
  news: FileList,
  max: number,
): boolean => (
  _.gt(_.size(joinImages(values, current)) + +_.size(news), max)
);
