import React, { FunctionComponent, useEffect, useState } from 'react';
import { StoreProvider } from 'easy-peasy';
import { I18nextProvider } from 'react-i18next';
import { CookiesProvider, useCookies } from 'react-cookie';
import { AxiosResponse } from 'axios';
import _ from 'lodash/fp';
import { BrowserRouter as Router } from 'react-router-dom';

import { buildStore } from './store';
import i18n from './services/i18n';
import client from './api/axios';
import { useStoreActions, useStoreState } from './store/hooks';
import date from './services/date';
import { INFINITE_DATE, getToken, getCalendlyCookie } from './services/cookies';
import handleUnauthorized from './services/authorization';
import Routing from './screens/Routing';
import AppLoader from './components/loaders/AppLoader';

const store = buildStore();

const getLocale = _.flow(
  _.take(2),
  _.join(''),
);

client.interceptors.response.use(
  (response: AxiosResponse) => response,
  handleUnauthorized(store),
);

const Session: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookies] = useCookies(['lang']);
  const { pasetoToken } = useStoreState((state) => state.session);
  const { setToken: loadSession, setCalendly } = useStoreActions((actions) => actions.session);

  const restoreSession = async (): Promise<void> => {
    setIsLoading(true);

    if (pasetoToken) {
      setIsLoading(false);
      return;
    }

    if (!_.isUndefined(getToken())) {
      loadSession(getToken());
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
  };

  const initializeLocale = (): void => {
    let currentLang = cookies.lang;
    if (_.isUndefined(currentLang)) currentLang = 'en-GB';
    i18n.changeLanguage(getLocale(currentLang));
    date.init(getLocale(currentLang));
    setCookies('lang', currentLang, {
      expires: INFINITE_DATE,
      sameSite: true,
      path: '/',
    });
    client.defaults.headers.common['Accept-Language'] = currentLang;
  };

  useEffect(() => {
    (async (): Promise<void> => {
      const calendlyCookie = getCalendlyCookie();
      setCalendly(_.isUndefined(calendlyCookie));
      initializeLocale();
      await restoreSession();
    })();
  }, [pasetoToken]);

  if (isLoading) {
    return <AppLoader />;
  }

  return <Routing />;
};

const App: FunctionComponent = () => (
  <StoreProvider store={store}>
    <I18nextProvider i18n={i18n}>
      <CookiesProvider>
        <Router>
          <Session />
        </Router>
      </CookiesProvider>
    </I18nextProvider>
  </StoreProvider>
);

export default App;
