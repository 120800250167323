import moment, { Moment } from 'moment';
import _ from 'lodash/fp';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/it';

const date = {
  init: (locale: string): void => {
    moment.locale(locale);
  },

  format: (currentDate: Date | string, format = 'YYYY-MMM-DD'): string => (
    moment(currentDate).format(format)
  ),

  month: (month: string): string => (
    moment().month(parseInt(month, 10) - 1).format('MMM')
  ),

  isNullDate: (checkDate: string): boolean => (
    !_.gt(parseInt(moment(checkDate).format('YYYY'), 10), 1) || _.isEmpty(checkDate)
  ),

  yearToDate: (year: string): Date => moment(`${year}-01-01`).toDate(),

  yearToMoment: (year: string): Moment => moment(`${year}-01-01`),

  ignoreTimeZone: (currentDate: string, format:string): string => (
    moment.utc(currentDate).format(format)
  ),

  toDate: (currentDate: string): Date => (
    _.isEmpty(currentDate) ? new Date() : moment.utc(currentDate).toDate()
  ),

  locale: (): string => moment.locale(),

  isAfter: (selected: Date): boolean => (
    moment(selected).isAfter(moment(new Date()))
  ),

  isBefore: (selected: Date, first:string): boolean => (
    moment(selected).isBefore(date.yearToMoment(first))
  ),

  sanitizeDate: (currentDate: string): string => (
    date.isNullDate(currentDate) ? '' : date.format(currentDate, 'YYYY-MM-DD')
  ),

  add7Days: (currentDate: Date): Date => (
    moment(currentDate).add(7, 'days').toDate()
  ),
};

export default date;
