import _ from 'lodash/fp';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import Image from '../Image';
import Tag from '../Tag';

export type Props = {
  to: string;
  icon: string;
  title: string;
  text?: string;
  styles?: string;
}

const LinkIcon: FunctionComponent<Props> = (
  {
    to,
    icon,
    title,
    text = '',
    styles = '',
  }: Props,
) => (
  <Link to={to} className={`btn-icon ${styles}`}>
    <Image src={icon} alt={_.isEmpty(text) ? title : text} />
    {!_.isEmpty(text) && (
      <Tag type="light-blue" text={text} styles="mx-auto" />
    )}
  </Link>
);

export default LinkIcon;
