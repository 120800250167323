import React, { FunctionComponent } from 'react';

import TextContainer from '../../../../components/TextContainer';
import { paths } from '../../../../lib/routing';
import i18n from '../../../../services/i18n';

import BaseCard from './components/BaseCard';

const FirstCarCard: FunctionComponent = () => (
  <BaseCard url={paths.car.new}>
    <div className="flex flex-col flex-1 justify-between">
      <TextContainer size="text-small">
        {i18n.t('car:first_car_message')}
      </TextContainer>
      <TextContainer font="montserrat" size="one-line-small" styles="text-right">
        {i18n.t('car:add_first_car')}
      </TextContainer>
    </div>
  </BaseCard>
);

export default FirstCarCard;
