import React, { ChangeEvent, FocusEvent, FunctionComponent } from 'react';
import _ from 'lodash/fp';

import { hasErrors, isTouched } from '../../lib/helpers/forms';

import FormInput from './components/FormInput';
import InputLabel from './components/InputLabel';
import InputError from './components/InputError';

export type Props = {
  name: string;
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
  readonly?: boolean;
}

const EmailInput: FunctionComponent<Props> = (
  {
    name,
    value,
    placeholder,
    onChange,
    onBlur = undefined,
    touched = false,
    errors = '',
    readonly = false,
  }: Props,
) => (
  <FormInput background>
    <InputLabel name={name} placeholder={_.isEmpty(value) ? '' : placeholder} />
    <input
      type="email"
      name={name}
      id={name}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      value={value}
      readOnly={readonly}
    />
    <InputError errors={hasErrors(errors) && isTouched(touched) ? errors : ''} />
  </FormInput>
);

export default EmailInput;
