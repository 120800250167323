export type DocumentURL = {
  id: number;
  thumb: string;
  medium: string;
  large: string;
  full: string;
};

export type DocumentBase64 = {
  filename: string;
  base64Data: string;
};

export type DocumentName = 'images' | 'engineFile' | 'gearboxFile' | 'chassisFile' | 'document' | 'documents';

export type DocumentSize = 'thumb' | 'medium' | 'large' | 'full';

export const emptyBase64: DocumentBase64 = {
  filename: '',
  base64Data: '',
};
