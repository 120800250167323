import { Thunk, thunk } from 'easy-peasy';

export interface DocumentsModel {
  destroy: Thunk<DocumentsModel, number>;
}

const documentsModel: DocumentsModel = {
  destroy: thunk(async (actions, id, { injections }) => {
    const { documentsService } = injections;
    const response = await documentsService.destroy(id);

    return response;
  }),
};

export default documentsModel;
