export type Question = {
  question: string;
  description: string;
  initialCategory: number;
  categoryID: number;
};

const assistantQuestions: Question[] = [
  {
    question: 'assistant:buy_car',
    description: 'assistant:buy_car_description',
    initialCategory: 4,
    categoryID: 5,
  },
  {
    question: 'assistant:major_service',
    description: 'assistant:major_service_description',
    initialCategory: 1,
    categoryID: 2,
  },
  {
    question: 'assistant:certification',
    description: 'assistant:certification_description',
    initialCategory: 5,
    categoryID: 6,
  },
  {
    question: 'assistant:technical_inspection',
    description: 'assistant:technical_inspection_description',
    initialCategory: 6,
    categoryID: 7,
  },
  {
    question: 'assistant:restoration',
    description: 'assistant:restoration_description',
    initialCategory: 3,
    categoryID: 4,
  },
  {
    question: 'assistant:minor_service',
    description: 'assistant:minor_service_description',
    initialCategory: 0,
    categoryID: 1,
  },
];

export default assistantQuestions;
