import React, {
  FunctionComponent, ReactNode, useEffect, useState,
} from 'react';
import { Route, Redirect } from 'react-router-dom';
import _ from 'lodash/fp';

import { useStoreActions, useStoreState } from '../store/hooks';
import { paths } from '../lib/routing';
import AppLoader from '../components/loaders/AppLoader';
import useQuery from '../services/hooks/query';

type Props = {
  exact?: boolean;
  path: string;
  children: ReactNode;
}

const PrivateScreen: FunctionComponent<Props> = (
  {
    exact = false,
    path,
    children,
  }: Props,
) => {
  const { index } = useStoreActions((actions) => actions.user);
  const { setToken: loadSession } = useStoreActions((actions) => actions.session);
  const { user } = useStoreState((state) => state.user);
  const { isLoggedIn } = useStoreState((state) => state.session);
  const [isLoading, setIsLoading] = useState(true);
  const query = useQuery();
  const token: string | null = query.get('token');

  useEffect(() => {
    if (!_.isNull(token)) {
      loadSession(token);
    }

    if (isLoggedIn || _.isUndefined(user)) {
      index().then(() => setIsLoading(false));
      return;
    }

    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <Route
      exact={exact}
      path={path}
      render={({ location }) => (
        !isLoggedIn
          ? (
            <Redirect
              to={{
                pathname: paths.signin,
                state: { from: location },
              }}
            />
          )
          : children
      )}
    />
  );
};

export default PrivateScreen;
