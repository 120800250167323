import { AxiosResponse } from 'axios';

import { CarParams } from '../../store/types/car';
import client, { request } from '../axios';

export const index = async (): Promise<AxiosResponse> => request(client.get('/cars'));

export const show = async (id: string): Promise<AxiosResponse> => (
  request(client.get(`/cars/${id}`))
);

export const create = async (params: CarParams): Promise<AxiosResponse> => (
  request(client.post('/cars', { ...params }))
);

export const update = async (params: CarParams): Promise<AxiosResponse> => (
  request(client.put(`/cars/${params.id}`, { ...params }))
);

export const destroy = async (id: string): Promise<AxiosResponse> => (
  request(client.delete(`/cars/${id}`))
);
