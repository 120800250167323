import assistant from './graphics/assistant.svg';
import carData from './graphics/car-data.svg';
import carMan from './graphics/car-man-point-sky.svg';
import defaultCarThumb from './graphics/default-car-thumb.svg';
import carHand from './graphics/hand-car-man.svg';
import logo from './graphics/logo.svg';
import manDocument from './graphics/man-document.svg';
import service from './graphics/service.png';
import textLogo from './graphics/text-logo.svg';
import draft from './graphics/draft.png';
import appStore from './app-store.png';
import utopia from './utopia.jpg';

export type PageGraphic = 'hand' | 'car' | 'document' | 'assistant' | 'data';

const graphics = {
  appStore,
  utopia,
  assistant,
  carData,
  carMan,
  defaultCarThumb,
  carHand,
  logo,
  manDocument,
  service,
  textLogo,
  draft,
};

export default graphics;
