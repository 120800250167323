import React, { FunctionComponent, ChangeEvent, FocusEvent } from 'react';
import _ from 'lodash/fp';
import { Hint } from 'react-autocomplete-hint';

import { hasErrors, isTouched } from '../../lib/helpers/forms';

import FormInput from './components/FormInput';
import InputLabel from './components/InputLabel';
import InputError from './components/InputError';

export type Props = {
  name: string;
  value: string;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
  readonly?: boolean;
  options?: string[];
  styles?: string;
}

const TextInput: FunctionComponent<Props> = (
  {
    name,
    value,
    placeholder,
    onChange,
    onBlur = undefined,
    touched = false,
    errors = '',
    readonly = false,
    options = [],
    styles = '',
  }: Props,
) => {
  const input = (
    <input
      type="text"
      name={name}
      id={name}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      value={value}
      readOnly={readonly}
    />
  );

  return (
    <FormInput styles={styles}>
      <InputLabel name={name} placeholder={_.isEmpty(value) ? '' : placeholder} />
      {!_.isEmpty(options)
        ? <Hint options={options} allowTabFill>{input}</Hint>
        : input}
      <InputError errors={hasErrors(errors) && isTouched(touched) ? errors : ''} />
    </FormInput>
  );
};

export default TextInput;
