import React, { FunctionComponent, ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  children: ReactNode;
  border?: boolean;
  center?: boolean;
}

const SectionBlock: FunctionComponent<Props> = (
  {
    children,
    border = false,
    center = false,
  }: Props,
) => {
  const className = classNames(
    'flex flex-col items-center md:w-1/2 mb-12 md:mb-0 md:px-6', {
      'md:border-r border-primary': border,
      'justify-center': center,
    },
  );

  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default SectionBlock;
