import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import { TextColor } from '../lib/style/typography';

export type Props = {
  to: string;
  text: string;
  styles?: string;
  color?: TextColor;
}

const NavigationLink: FunctionComponent<Props> = (
  {
    to,
    text,
    styles = '',
    color = 'text-primary',
  }: Props,
) => (
  <Link to={to} className={`one-line-small opensans ${styles} ${color}`}>
    {text}
  </Link>
);

export default NavigationLink;
