export type Assistant = {
  hide: boolean;
  active: boolean;
  completedCars: string;
};

type Tips = {
  event: boolean;
  vehicle: boolean;
  draft: boolean;
  engine: boolean;
};

type Profile = {
  date: string;
  active: boolean;
}

export type SettingsAttributes = {
  demoCar: boolean;
  profile: Profile;
  assistant: Assistant;
  tips: Tips;
}

export type DontShowAgain = {
  active: boolean;
  isCheck: boolean;
  onChange: (() => void)| null;
};

const emptySettings: SettingsAttributes = {
  demoCar: true,
  profile: {
    date: new Date().toString(),
    active: false,
  },
  assistant: {
    hide: false,
    active: true,
    completedCars: '',
  },
  tips: {
    event: true,
    vehicle: true,
    draft: true,
    engine: true,
  },
};

export default emptySettings;
