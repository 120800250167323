import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { PASSWORD_REGEX } from '../../store/model/user/user';
import { useStoreActions } from '../../store/hooks';
import useNavigation from '../../services/hooks/navigation';
import { NewUserParams } from '../../store/types/user';
import { isValidResponse } from '../../api/axios';
import TextContainer from '../../components/TextContainer';
import SubmitButton from '../../components/buttons/SubmitButton';
import NavigationLink from '../../components/NavigationLink';
import { paths } from '../../lib/routing';
import Checkbox from '../../components/forms/Checkbox';
import Unauthenticated from '../../components/layout/Unauthenticated';
import EmailInput from '../../components/forms/EmailInput';
import Flash from '../../components/forms/Flash';
import PasswordInput from '../../components/forms/PasswordInput';
import SubmitLoader from '../../components/loaders/SubmitLoader';
import Back from '../../components/layout/components/Back';

import Card from './components/Card';

type Inputs = NewUserParams & {
  passwordConfirmation: string;
  switch: boolean;
}

const Signup: FunctionComponent = () => {
  const { t } = useTranslation();
  const { create } = useStoreActions((actions) => actions.user);
  const [message, setMessage] = useState('');
  const navigation = useNavigation();

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('errors:email:valid'))
      .required(t('errors:email:required')),
    password: yup.string()
      .required(t('errors:required'))
      .matches(PASSWORD_REGEX, t('signup:password_hint')),
    passwordConfirmation: yup.string()
      .required(t('errors:required'))
      .oneOf([yup.ref('password'), null], t('errors:password')),
    switch: yup.boolean()
      .required(t('errors:required'))
      .oneOf([true], t('errors:acceptances:legal_terms')),
  });

  const handleOnSubmit = async (data: Inputs): Promise<void> => {
    const response = await create({ password: data.password, email: data.email.toLowerCase() });
    if (isValidResponse(response)) {
      navigation(`${paths.signin}?email=${data.email.toLowerCase()}&password=${data.password}`);
      return;
    }
    setMessage(response.data);
  };

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
    isValid,
    setFieldValue,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordConfirmation: '',
      switch: false,
    },
    onSubmit: handleOnSubmit,
    validationSchema: schema,
    validateOnBlur: true,
  });

  return (
    <Unauthenticated>
      {isSubmitting && <SubmitLoader />}
      <Card border>
        <Back url={paths.root} styles="absolute top-0 left-0" />
        <TextContainer
          tag="h1"
          font="montserrat"
          size="one-line-large"
          styles="text-center mb-16"
        >
          {t('signup:title')}
        </TextContainer>
        <form onSubmit={handleSubmit} className="block max-w-full">
          <Flash message={message} />
          <EmailInput
            name="email"
            value={values.email}
            placeholder={t('session:email')}
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            touched={touched.email}
            errors={errors.email}
          />
          <PasswordInput
            name="password"
            value={values.password}
            placeholder={t('session:password')}
            onChange={handleChange('password')}
            hint={t('signup:password_hint')}
            onBlur={handleBlur('password')}
            touched={touched.password}
            errors={errors.password}
          />
          <PasswordInput
            name="passwordConfirmation"
            value={values.passwordConfirmation}
            placeholder={t('session:password_confirmation')}
            onChange={handleChange('passwordConfirmation')}
            onBlur={handleBlur('passwordConfirmation')}
            touched={touched.passwordConfirmation}
            errors={errors.passwordConfirmation}
          />
          <Checkbox
            name="switch"
            value={values.switch}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('switch', e.target.checked)}
            onBlur={handleBlur('switch')}
            touched={touched.switch}
            errors={errors.switch}
            checkPosition="right"
          >
            <TextContainer tag="p" color="text-primary" size="one-line-small">
              {`${t('accept')} `}
              <a href="https://themotorchain.com/en/terms-of-use/" className="underline" target="_blank" rel="noreferrer">
                {t('signup:legal_terms')}
              </a>
            </TextContainer>
          </Checkbox>
          <SubmitButton
            title={t('unauthenticated:signup')}
            type="outline"
            disabled={isSubmitting || !isValid}
            styles="mx-auto mb-16"
          />
          <NavigationLink
            to={paths.signin}
            text={t('signup:do_have_account')}
            styles="block mb-24 text-center"
          />
        </form>
      </Card>
    </Unauthenticated>
  );
};

export default Signup;
