import React, {
  FunctionComponent,
  ReactNode,
  SetStateAction,
  Dispatch,
} from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { CarAttributes, Page } from '../../../../store/types/car';
import PageHeader from '../../components/PageHeader';
import { PageGraphic } from '../../../../assets/images/graphics';
import { isCarComplete } from '../../../../lib/helpers/car';
import useAssistant from '../../../../services/hooks/assistant';
import Authenticated from '../../../../components/layout/Authenticated';
import Back from '../../../../components/layout/components/Back';
import { paths } from '../../../../lib/routing';

import Tabs from './tabs/Tabs';

type Props = {
  current: Page;
  setCurrent: Dispatch<SetStateAction<Page>>;
  title: string;
  car: CarAttributes
  children: ReactNode;
  readonly?: boolean;
}

const setGraphic = (current: Page, active: boolean): PageGraphic => {
  if (_.isEqual(current, 'timeline') && active) return 'assistant';
  if (_.isEqual(current, 'vehicle-data')) return 'document';
  if (_.isEqual(current, 'timeline')) return 'data';
  return 'car';
};

const CarPage: FunctionComponent<Props> = (
  {
    current,
    setCurrent,
    title,
    car,
    children,
    readonly = false,
  }: Props,
) => {
  const { t } = useTranslation();
  const { isActive } = useAssistant(car.id);

  const canHaveAssistant = (
    isActive && _.isEqual(current, 'timeline') && !readonly
  );

  const layoutTitle = (
    <PageHeader
      title={canHaveAssistant ? t('timeline:assistant_mode') : title}
      graphic={setGraphic(current, isActive)}
    />
  );

  return (
    <Authenticated title={layoutTitle}>
      <Back url={paths.home} />
      <Tabs current={current} setCurrent={setCurrent} isComplete={isCarComplete(car)} />
      <div className={`expand car-page ${current}`}>
        {children}
      </div>
    </Authenticated>
  );
};

export default CarPage;
