import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import { CarAttributes } from '../../../../../store/types/car';
import TextContainer from '../../../../../components/TextContainer';
import i18n from '../../../../../services/i18n';
import PopupGallery from '../../../../../components/PopupGallery';

type Props = {
  car: CarAttributes;
}

const Title: FunctionComponent = () => (
  <TextContainer
    font="montserrat-bold"
    size="text-medium"
    styles="relative uppercase border-b border-primary pl-4 max-w-128 mb-8"
  >
    {i18n.t('snapshot:gallery')}
    <span className="absolute top-full right-0 w-12 h-2 bg-primary-dark" />
  </TextContainer>
);

const Gallery: FunctionComponent<Props> = ({ car }: Props) => (
  <div className="py-16 mb-4 border-b border-primary">
    <Title />
    <PopupGallery
      slides={_.toArray(car.carImages)}
      styles="snapshot-thumbs"
      thumbSize="medium"
    />
  </div>
);

export default Gallery;
