import {
  Action,
  action,
  Thunk,
  thunk,
} from 'easy-peasy';
import _ from 'lodash/fp';

import { CarAttributes, CarParams } from '../../types/car';
import { isValidResponse } from '../../../api/axios';

export interface CarsModel {
  cars: CarAttributes[];

  init: Action<CarsModel, CarAttributes[]>;

  index: Thunk<CarsModel>;

  show: Thunk<CarsModel, string>;

  create: Thunk<CarsModel, CarParams>;

  update: Thunk<CarsModel, CarParams>;

  destroy: Thunk<CarsModel, string>;
}

const sanitizeParams = (params: CarAttributes[]): CarAttributes[] => (
  _.isEmpty(params) ? [] : params
);

export const DEMO_CAR_ID = 0;

const carsModel: CarsModel = {
  cars: [],

  init: action((state, params) => {
    state.cars = [...sanitizeParams(params)];
  }),

  index: thunk(async (actions, _params, { injections }) => {
    const { carsService } = injections;
    const response = await carsService.index();

    if (isValidResponse(response)) {
      actions.init(response.data);
    }

    return isValidResponse(response);
  }),

  show: thunk(async (actions, id, { injections }) => {
    const { carsService } = injections;
    return carsService.show(id);
  }),

  create: thunk(async (actions, params, { injections }) => {
    const { carsService } = injections;
    return carsService.create(params);
  }),

  update: thunk(async (actions, params, { injections }) => {
    const { carsService } = injections;
    return carsService.update(params);
  }),

  destroy: thunk(async (actions, params, { injections }) => {
    const { carsService } = injections;
    return carsService.destroy(params);
  }),
};

export default carsModel;
