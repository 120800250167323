import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  children: ReactNode;
}

const Options: FunctionComponent<Props> = ({ children }: Props) => (
  <div className="flex justify-end items-center max-w-screen-xs w-full border-b border-primary-light pb-4 mb-8">
    <div className="flex-1 h-1 bg-primary-light relative">
      <div className="absolute top-0 left-0 h-2 w-24 bg-primary-dark" />
    </div>
    {children}
  </div>
);

export default Options;
