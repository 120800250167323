export type FlashMessage = {
  value: string;
  type: 'error' | 'success';
}

const flashMessage: FlashMessage = {
  value: '',
  type: 'error',
};

export default flashMessage;
