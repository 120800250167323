import React, { FunctionComponent } from 'react';

export type Props = {
  src: string;
}

const CarPicture: FunctionComponent<Props> = ({ src }: Props) => (
  <div
    className="w-full max-w-screen-xs h-128 rounded-md has-bg-image relative"
    style={{ backgroundImage: `url(${src})` }}
  />
);

export default CarPicture;
