import React, { FormEvent, FunctionComponent, ReactNode } from 'react';

import Back from '../../../../components/layout/components/Back';
import { paths } from '../../../../lib/routing';

import FormHeader from './FormHeader';

type Props = {
  title: string;
  icon: string;
  onSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void
  children: ReactNode;
  state?: string;
}

const Form: FunctionComponent<Props> = ({
  title,
  icon,
  onSubmit,
  children,
  state = '',
}: Props) => (
  <form onSubmit={onSubmit} className="user-form">
    <Back url={paths.home} />
    <FormHeader title={title} icon={icon} state={state} />
    {children}
  </form>
);

export default Form;
