import React, { FunctionComponent } from 'react';

import icons from '../../../../../../assets/images/icons';
import Image from '../../../../../../components/Image';
import TextContainer from '../../../../../../components/TextContainer';
import i18n from '../../../../../../services/i18n';
import { UserAttributes } from '../../../../../../store/types/user';

type Props = {
  servicedBy: UserAttributes;
}

const Serviced: FunctionComponent<Props> = ({ servicedBy }: Props) => (
  <div className="flex bg-primary-x-light rounded-r-2xl py-4 px-4 justify-between mb-4 relative max-w-128">
    <div className="absolute h-full w-6 top-0 right-full bg-primary-x-light" />
    <TextContainer font="montserrat" size="one-line-regular">
      {i18n.t('event:serviced_by')}
    </TextContainer>
    <div className="flex">
      <Image src={icons.mechanic} styles="w-8 h-8 mr-1" />
      <div>
        <TextContainer font="opensans-bold" size="one-line-small">
          {servicedBy.name}
        </TextContainer>
        <TextContainer size="one-line-small" styles="capitalize">
          {servicedBy.country}
        </TextContainer>
      </div>
    </div>
  </div>
);

export default Serviced;
