import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import i18n from '../services/i18n';

type Props = {
  src: string;
  alt?: string;
  styles?: string;
}

const defaultText = (alt: string): string => (_.isEmpty(alt) ? i18n.t('icon') : alt);

const Image: FunctionComponent<Props> = ({ src, alt = '', styles = '' }: Props) => (
  <img
    src={src}
    alt={defaultText(alt)}
    title={defaultText(alt)}
    className={styles}
  />
);

export default Image;
