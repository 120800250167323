import classNames from 'classnames';
import React, { ChangeEvent, FunctionComponent } from 'react';

type Props = {
  value: boolean;
  placeholder: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  reverse?: boolean;
  border?: boolean;
  styles?: string;
}

const Switch: FunctionComponent<Props> = (
  {
    value,
    placeholder,
    onChange,
    reverse = false,
    border = false,
    styles = '',
  }: Props,
) => {
  const switchClass = classNames(`switch-input ${styles}`, {
    'flex-row-reverse': reverse,
    'border-b border-primary-dark': border,
  });
  return (
    <div className={switchClass}>
      <label className="placeholder capitalize">
        {placeholder}
      </label>
      <label className="switch">
        <input
          type="checkbox"
          className="w-0 h-0 opacity-0"
          checked={value}
          onChange={onChange}
        />
        <span className="slider" />
      </label>
    </div>
  );
};

export default Switch;
