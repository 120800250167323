import * as yup from 'yup';
import React, { FunctionComponent, useState } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { AxiosResponse } from 'axios';

import { CarAttributes, CarParams } from '../../../../store/types/car';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import useNavigation from '../../../../services/hooks/navigation';
import { useCheckTip } from '../../../../services/hooks/settings';
import { isValidResponse } from '../../../../api/axios';
import { paths } from '../../../../lib/routing';
import {
  carParamsFields,
  extraDetailsEntries,
  isCarComplete,
  isCarProgressComplete,
  isNewCar,
  isProgressComplete,
  minimumCarRegistration,
} from '../../../../lib/helpers/car';
import { DocumentName, emptyBase64 } from '../../../../store/types/document';
import {
  deleteDocument,
  deleteSavedDocument,
  fileInputValue,
  fileInputValues,
  joinImages,
  reachedMaxImages,
  reachedWithNewImages,
  saveDocument,
  saveDocuments,
} from '../../../../lib/helpers/documents';
import InfoModal from '../../../../components/modals/InfoModal';
import Balloon from '../../../../components/Balloon';
import icons from '../../../../assets/images/icons';
import TextInput from '../../../../components/forms/TextInput';
import { bodyTypes, makers, models } from '../../../../utils/brands';
import FileInput from '../../../../components/forms/FileInput';
import DateInput from '../../../../components/forms/DateInput';
import SubmitButton from '../../../../components/buttons/SubmitButton';
import SubmitLoader from '../../../../components/loaders/SubmitLoader';
import MultipleFileInput from '../../../../components/forms/MultipleFileInput';
import Image from '../../../../components/Image';
import date from '../../../../services/date';
import ActionButton from '../../../../components/buttons/ActionButton';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import Badge from '../../../../components/Badge';

import VehicleInput from './components/VehicleInput';
import Section from './components/Section';
import Progress from './progress/Progress';
import RouteLeavingGuard from './components/RouteLeavingGuard';

type Props = {
  car: CarAttributes;
  readOnly?: boolean;
}

const MAX_CAR_IMAGES = 9;

const VehicleData: FunctionComponent<Props> = ({ car, readOnly = false }: Props) => {
  const { t } = useTranslation();
  const { settings } = useStoreState((state) => state.user.user);
  const navigate = useNavigation();
  const { create, update, destroy: destroyCar } = useStoreActions((actions) => actions.cars);
  const { flash } = useStoreActions((actions) => actions);
  const { destroy } = useStoreActions((actions) => actions.documents);
  const [completionBallon, setCompletionBallon] = useState(true);
  const [maxImages, setMaxImages] = useState(false);
  const [imagesMessage, setImagesMessage] = useState('');
  const [confirmDraft, setConfirmDraft] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [carImages, setCarImages] = useState(car.carImages);
  const [imagesChanged, setImagesChanged] = useState(false);
  const backTip = _.get('tips.vehicle')(settings);
  const draftTip = _.get('tips.draft')(settings);
  const checkTip = useCheckTip('draft');

  const submitForm = async (data: CarParams): Promise<void> => {
    data.id = car.id;
    const response: AxiosResponse = isNewCar(data.id) ? await create(data) : await update(data);
    if (isValidResponse(response)) {
      flash.set({ value: t('flash:success:car'), type: 'success' });
      navigate(paths.home);
      if (isCarComplete(car)) navigate(paths.car.edit(car.id));
      return;
    }
    flash.set({ value: t('flash:error:car'), type: 'error' });
  };

  const onDestroyCar = async (): Promise<void> => {
    const response = await destroyCar(`${car.id}`);
    if (isValidResponse(response)) {
      setIsDeleteModal(false);
      navigate(paths.home);
      return;
    }
    flash.set({ value: response.data, type: 'error' });
    setIsDeleteModal(false);
  };

  const handleOnSubmit = (data: CarParams): void => {
    if (draftTip && !isCarProgressComplete(data, car)) {
      setConfirmDraft(true);
      return;
    }
    submitForm(data);
  };

  const schema = yup.object().shape({
    carMake: yup.string()
      .required(t('errors:required')),
    modelName: yup.string()
      .required(t('errors:required')),
    manufactureYear: yup.number()
      .min(1900, t('errors:date:year_min'))
      .max(new Date().getFullYear(), t('errors:date:year_max')),
  });

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setSubmitting,
    setFieldValue,
    isSubmitting,
    isValid,
    dirty,
  } = useFormik({
    // @ts-ignore
    initialValues: {
      ..._.pick(carParamsFields)(car),
      images: [],
      engineFile: emptyBase64,
      gearboxFile: emptyBase64,
      chassisFile: emptyBase64,
    },
    onSubmit: handleOnSubmit,
    validationSchema: schema,
  });

  const onCloseConfirm = (forceClose = false): void => {
    checkTip(isCheck);
    if (!forceClose) {
      submitForm(values);
      setConfirmDraft(false);
      return;
    }
    setConfirmDraft(false);
    setSubmitting(false);
  };

  const handleDocumentChange = async (
    name: DocumentName,
    value: File | FileList,
  ): Promise<void> => {
    if (_.isEqual(_.size(value), 0)) {
      setFieldValue(name, await saveDocument(value as File));
      return;
    }
    if (
      !reachedWithNewImages(
        fileInputValues(values.images),
        carImages,
        value as FileList,
        MAX_CAR_IMAGES,
      )
    ) {
      setFieldValue(name, [
        ...values.images,
        ...await saveDocuments(value as FileList),
      ]);
      return;
    }
    setImagesMessage(t('errors:max_images_reached'));
    setMaxImages(true);
  };

  const onDeleteDocument = (name: DocumentName, value: string): void => {
    setFieldValue(name, [
      ...deleteDocument(value, values.images),
    ]);
  };

  const onDeletePreviousDocument = async (id: number): Promise<void> => {
    const response = await destroy(id);
    if (isValidResponse(response)) {
      setCarImages([...deleteSavedDocument(id, carImages)]);
      setImagesChanged(true);
    }
  };

  const buttonTitle = (): string => {
    if (isCarComplete(car)) return t('save');
    if (isProgressComplete(values, car)) return t('vehicle_data:save_complete');
    return t('vehicle_data:save_draft');
  };

  const canSave = !readOnly && (dirty || imagesChanged);

  return (
    <>
      <RouteLeavingGuard when={dirty && !isSubmitting && backTip} />
      <div className="expand vehicle-data">
        {isSubmitting && !confirmDraft && <SubmitLoader />}
        <InfoModal
          isOpen={confirmDraft}
          message={t('popups:draft_tip')}
          onAcceptMessage={t('popups:got_it')}
          onAccept={(): void => onCloseConfirm()}
          onReject={(): void => onCloseConfirm(true)}
          isCheck={isCheck}
          onChangeCheck={(): void => setIsCheck(!isCheck)}
        />
        <InfoModal
          isOpen={maxImages}
          onAccept={() => setMaxImages(false)}
          message={imagesMessage}
        />
        <ConfirmModal
          isOpen={isDeleteModal}
          message={t('vehicle_data:delete_message')}
          onAccept={onDestroyCar}
          onAcceptMessage={t('vehicle_data:delete_acceptance')}
          onReject={() => setIsDeleteModal(false)}
          onRejectMessage={t('vehicle_data:delete_keep')}
          alert
        />
        {!isCarComplete(car) && (
        <Progress
          values={values}
          car={car}
        />
        )}
        <form onSubmit={handleSubmit} noValidate className="my-12 max-w-512 mx-auto w-full">
          <div className="xl:flex justify-between items-end">
            {!isCarComplete(car) && completionBallon && (
              <Balloon
                message={isProgressComplete(values, car) ? t('vehicle_data:balloon_success') : t('vehicle_data:balloon')}
                onClose={(): void => setCompletionBallon(false)}
                direction="bottom-left"
                color={isProgressComplete(values, car) ? 'bg-green' : 'bg-primary-dark'}
              />
            )}
            {canSave && (
              <div className="justify-end hidden xl:flex flex-1">
                <SubmitButton
                  title={buttonTitle()}
                  disabled={!isValid || isSubmitting}
                />
              </div>
            )}
          </div>
          <Section title={t('vehicle_data:required_info')} styles="my-12">
            <div className="xl:w-1/2 flex flex-col items-center">
              <VehicleInput value={values.carMake} icon={icons.vehicle.make}>
                <TextInput
                  name="carMake"
                  value={values.carMake}
                  placeholder={t('car:attributes:make')}
                  onChange={handleChange('carMake')}
                  onBlur={handleBlur('carMake')}
                  touched={touched.carMake}
                  errors={errors.carMake}
                  readonly={readOnly || isCarComplete(car)}
                  options={makers}
                />
              </VehicleInput>
              <VehicleInput value={values.modelName} icon={icons.vehicle.model}>
                <TextInput
                  name="modelName"
                  value={values.modelName}
                  placeholder={t('car:attributes:model')}
                  onChange={handleChange('modelName')}
                  onBlur={handleBlur('modelName')}
                  touched={touched.modelName}
                  errors={errors.modelName}
                  readonly={readOnly || isCarComplete(car)}
                  options={models(values.carMake)}
                />
              </VehicleInput>
              <VehicleInput value={values.bodyType} icon={icons.vehicle.body}>
                <TextInput
                  name="bodyType"
                  value={values.bodyType}
                  placeholder={t('car:attributes:body_type')}
                  onChange={handleChange('bodyType')}
                  onBlur={handleBlur('bodyType')}
                  touched={touched.bodyType}
                  errors={errors.bodyType}
                  readonly={readOnly || isCarComplete(car)}
                  options={bodyTypes}
                />
              </VehicleInput>
              <VehicleInput value={values.manufactureYear} icon={icons.vehicle.manufacture}>
                <TextInput
                  name="manufactureYear"
                  value={values.manufactureYear}
                  placeholder={t('car:attributes:manufacture_year')}
                  onChange={handleChange('manufactureYear')}
                  onBlur={handleBlur('manufactureYear')}
                  touched={touched.manufactureYear}
                  errors={errors.manufactureYear}
                  readonly={readOnly || isCarComplete(car)}
                />
              </VehicleInput>
            </div>
            <div className="xl:w-1/2 flex flex-col items-center">
              <VehicleInput value={values.chassis} icon={icons.vehicle.chassis.number}>
                <TextInput
                  name="chassis"
                  value={values.chassis}
                  placeholder={t('car:attributes:chassis_number')}
                  onChange={handleChange('chassis')}
                  onBlur={handleBlur('chassis')}
                  touched={touched.chassis}
                  errors={errors.chassis}
                  readonly={readOnly}
                />
                <FileInput
                  name="chassisFile"
                  value={fileInputValue(values.chassisFile)}
                  currentValue={car.chassisImage}
                  handleDocumentChange={handleDocumentChange}
                  deleteDocument={onDeleteDocument}
                  type="part-number"
                  readonly={readOnly}
                  required
                />
              </VehicleInput>
              <VehicleInput value={values.engine} icon={icons.vehicle.engine} type="yellow">
                <TextInput
                  name="engine"
                  value={values.engine}
                  placeholder={t('car:attributes:engine_number')}
                  onChange={handleChange('engine')}
                  onBlur={handleBlur('engine')}
                  touched={touched.engine}
                  errors={errors.engine}
                  readonly={readOnly}
                />
                <FileInput
                  name="engineFile"
                  value={fileInputValue(values.engineFile)}
                  currentValue={car.engineImage}
                  handleDocumentChange={handleDocumentChange}
                  deleteDocument={onDeleteDocument}
                  type="part-number"
                  readonly={readOnly}
                />
              </VehicleInput>
              <VehicleInput value={values.gearbox} icon={icons.vehicle.gearbox} type="yellow">
                <TextInput
                  name="gearbox"
                  value={values.gearbox}
                  placeholder={t('car:attributes:gearbox_number')}
                  onChange={handleChange('gearbox')}
                  onBlur={handleBlur('gearbox')}
                  touched={touched.gearbox}
                  errors={errors.gearbox}
                  readonly={readOnly}
                />
                <FileInput
                  name="gearboxFile"
                  value={fileInputValue(values.gearboxFile)}
                  currentValue={car.gearboxImage}
                  handleDocumentChange={handleDocumentChange}
                  deleteDocument={onDeleteDocument}
                  type="part-number"
                  readonly={readOnly}
                />
              </VehicleInput>
              <VehicleInput
                value={_.gte(_.size(joinImages(fileInputValues(values.images), carImages)), 1) ? '1' : ''}
                icon={icons.camera}
              >
                <MultipleFileInput
                  name="images"
                  values={fileInputValues(values.images)}
                  currentValues={carImages}
                  placeholder={t('car:attributes:thumbnail', { number: _.size(joinImages(fileInputValues(values.images), carImages)) })}
                  handleDocumentChange={handleDocumentChange}
                  deleteDocument={onDeleteDocument}
                  deletePrevious={onDeletePreviousDocument}
                  readonly={readOnly}
                  disabled={
                    reachedMaxImages(fileInputValues(values.images), carImages, MAX_CAR_IMAGES)
                  }
                />
                <label htmlFor="images" className="w-32 flex justify-end cursor-pointer relative">
                  <Image src={icons.camera} styles="w-16 h-16" />
                  {_.isEmpty(values.images) && _.isEmpty(car.carImages) && (
                    <Badge type="red" styles="absolute top-4 right-0" />
                  )}
                </label>
              </VehicleInput>
            </div>
          </Section>
          <Section
            title={t('vehicle_data:extra_details')}
            entries={t('vehicle_data:extra_details_entries', { number: extraDetailsEntries(values) })}
            subtitle={t('vehicle_data:extra_details_description')}
          >
            <div className="xl:w-1/2 flex flex-col items-center">
              <VehicleInput>
                <TextInput
                  name="nickName"
                  value={values.nickName}
                  placeholder={t('car:attributes:nick_name')}
                  onChange={handleChange('nickName')}
                  onBlur={handleBlur('nickName')}
                  touched={touched.nickName}
                  errors={errors.nickName}
                  readonly={readOnly}
                />
              </VehicleInput>
              <VehicleInput>
                <TextInput
                  name="intro"
                  value={values.intro}
                  placeholder={t('car:attributes:intro')}
                  onChange={handleChange('intro')}
                  onBlur={handleBlur('intro')}
                  touched={touched.intro}
                  errors={errors.intro}
                  readonly={readOnly}
                />
              </VehicleInput>
              <VehicleInput>
                <TextInput
                  name="licensePlate"
                  value={values.licensePlate}
                  placeholder={t('car:attributes:license_plate')}
                  onChange={handleChange('licensePlate')}
                  onBlur={handleBlur('licensePlate')}
                  touched={touched.licensePlate}
                  errors={errors.licensePlate}
                  readonly={readOnly}
                />
              </VehicleInput>
              <VehicleInput>
                <TextInput
                  name="color"
                  value={values.color}
                  placeholder={t('car:attributes:color')}
                  onChange={handleChange('color')}
                  onBlur={handleBlur('color')}
                  touched={touched.color}
                  errors={errors.color}
                  readonly={readOnly}
                />
              </VehicleInput>
            </div>
            <div className="xl:w-1/2 flex flex-col items-center">
              <VehicleInput>
                <TextInput
                  name="seatsColor"
                  value={values.seatsColor}
                  placeholder={t('car:attributes:seats_color')}
                  onChange={handleChange('seatsColor')}
                  onBlur={handleBlur('seatsColor')}
                  touched={touched.seatsColor}
                  errors={errors.seatsColor}
                  readonly={readOnly}
                />
              </VehicleInput>
              <VehicleInput>
                <TextInput
                  name="upholsteryColor"
                  value={values.upholsteryColor}
                  placeholder={t('car:attributes:carpets_color')}
                  onChange={handleChange('upholsteryColor')}
                  onBlur={handleBlur('upholsteryColor')}
                  touched={touched.upholsteryColor}
                  errors={errors.upholsteryColor}
                  readonly={readOnly}
                />
              </VehicleInput>
              <VehicleInput>
                <TextInput
                  name="dashboard"
                  value={values.dashboard}
                  placeholder={t('car:attributes:dashboard')}
                  onChange={handleChange('dashboard')}
                  onBlur={handleBlur('dashboard')}
                  touched={touched.dashboard}
                  errors={errors.dashboard}
                  readonly={readOnly}
                />
              </VehicleInput>
              <VehicleInput>
                <DateInput
                  name="firstRegistration"
                  value={
                    !date.isNullDate(values.firstRegistration)
                      ? date.format(values.firstRegistration, 'yyyy-MM-DD')
                      : ''
                  }
                  placeholder={t('car:attributes:first_registration')}
                  onChange={handleChange('firstRegistration')}
                  onBlur={handleBlur('firstRegistration')}
                  min={minimumCarRegistration(values.manufactureYear, car.manufactureYear)}
                  max={new Date()}
                  touched={touched.firstRegistration}
                  errors={errors.firstRegistration}
                  readonly={readOnly}
                />
              </VehicleInput>
            </div>
          </Section>
          {canSave && (
          <div className="flex justify-end xl:hidden">
            <SubmitButton
              title={buttonTitle()}
              disabled={!isValid || isSubmitting}
            />
          </div>
          )}
          {!isCarComplete(car) && _.gte(car.id, 0) && (
            <div className="flex justify-center py-4 max-w-192 mx-auto xl:ml-auto xl:mr-0 xl:justify-end">
              <ActionButton
                type="alert"
                title={t('vehicle_data:delete')}
                onClick={() => setIsDeleteModal(true)}
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default VehicleData;
