import React, { FunctionComponent, useState } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import CarPicture from '../../../../../components/CarPicture';
import { carImage, carName, carTitle } from '../../../../../lib/helpers/car';
import { CarAttributes } from '../../../../../store/types/car';
import { EventAttributes } from '../../../../../store/types/event';
import {
  CountCategory,
  majorCategories,
} from '../../../../../utils/snapshot';
import { categorySize } from '../../../../../lib/helpers/event';
import { nameWithoutLineFeed } from '../../../../../lib/helpers/categories';
import MajorCategoryCounter from '../../categories/MajorCategoryCounter';
import date from '../../../../../services/date';
import { paths } from '../../../../../lib/routing';
import TextContainer from '../../../../../components/TextContainer';
import icons from '../../../../../assets/images/icons';
import Image from '../../../../../components/Image';
import Tag from '../../../../../components/Tag';
import UnautorizedModal from '../../../../../components/modals/UnauthorizedModal';
import ActionButton from '../../../../../components/buttons/ActionButton';
import { useStoreActions, useStoreState } from '../../../../../store/hooks';
import { DEEPLINK_BASE } from '../../../../../api/axios';
import { encodeToken } from '../../../../../lib/helpers/timeline';
import LinkButton from '../../../../../components/buttons/LinkButton';

import AddEvent from './components/AddEvent';
import Options from './components/Options';

type Props = {
  readOnly: boolean;
  service: boolean;
  car: CarAttributes;
  events: EventAttributes[];
}

const MAX_UNSUBSCRIBED_EVENTS = 3;

const isCarSubscribed = (car: CarAttributes): boolean => (
  date.isAfter(date.toDate(car.endSubscription))
);

const setPathForAddEvent = (
  car: CarAttributes,
  events: number,
): string => {
  if (_.gt(events, MAX_UNSUBSCRIBED_EVENTS) && !isCarSubscribed(car)) {
    if (!_.isEmpty(car.coupon)) {
      return `${paths.car.subscribe(car.id)}?coupon=${car.coupon}`;
    }
    return paths.car.subscribe(car.id);
  }

  return paths.events.new(car.id);
};

const isMobileDevice = (): boolean => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};

const SnapshotSection: FunctionComponent<Props> = (
  {
    readOnly,
    service,
    car,
    events,
  }: Props,
) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { flash } = useStoreActions((actions) => actions);
  const { pasetoToken } = useStoreState((state) => state.session);
  const { isVerified } = useStoreState((state) => state.user);

  const deeplinkURL = (endURL: string): string => (
    `${DEEPLINK_BASE}${endURL}/${encodeToken(pasetoToken)}`
  );

  const mobileShare = async (endURL: string): Promise<void> => {
    try {
      await navigator.share({
        title: t('check_my', carName(car)),
        text: t('timeline:sharing_message', { name: carTitle(car) }),
        url: deeplinkURL(endURL),
      });
    } catch (error) {
      flash.set({ type: 'error', value: error as string });
    }
  };

  const copyOnClipboard = (endURL: string): void => {
    navigator.clipboard.writeText(deeplinkURL(endURL));
    flash.set({ type: 'success', value: t('flash:success:copy_to_clipboard') });
  };

  const onClickShare = (endURL: string): (void | Promise<void>) => (
    isMobileDevice() ? mobileShare(endURL) : copyOnClipboard(endURL)
  );

  return (
    <div className="flex flex-col items-center xl:w-1/2 xl:pt-2">
      <div className="w-full flex flex-col items-center">
        {!readOnly && (
        <Options>
          {!service && (
            <div className="btn-icon mt-8 cursor-pointer" onClick={() => setIsOpen(true)}>
              <Image src={icons.share} />
              <Tag type="light-blue" text={t('timeline:share')} styles="mx-auto" />
            </div>
          )}
          {!service && <AddEvent to={setPathForAddEvent(car, _.size(events))} />}
          {service && isCarSubscribed(car) && <AddEvent to={paths.serviceCar.events.new(car.id)} />}
        </Options>
        )}
        {service && !isCarSubscribed(car) && (
        <div className="w-full max-w-screen-xs py-16 border-b border-primary mb-8">
          <TextContainer color="text-red" font="montserrat" size="one-line-regular">
            {t('timeline:unsubscribed_car')}
          </TextContainer>
        </div>
        )}
        <div className="hidden xl:flex flex-col items-center w-full mt-24">
          <CarPicture src={carImage(car.carImages, 'large')} />
          <div className="flex justify-between w-full max-w-screen-xs mb-4">
            {_.map((category: CountCategory) => (
              <MajorCategoryCounter
                key={category.id}
                number={categorySize(category.id, events)}
                name={nameWithoutLineFeed(category.name)}
                styles="w-1/5"
              />
            ))(majorCategories)}
          </div>
        </div>
      </div>
      <UnautorizedModal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {isVerified ? (
          <div className="max-w-128 mx-auto py-4">
            <TextContainer font="montserrat" styles="text-center mb-1" size="one-line-medium">
              {t('timeline:share_options')}
            </TextContainer>
            <TextContainer size="text-small" styles="text-center mb-4">
              {t('timeline:share_instructions')}
            </TextContainer>

            <ActionButton
              size="medium"
              title={t('timeline:show_car')}
              onClick={() => onClickShare(`/shared-car/${car.id}`)}
              styles="mx-auto mb-1"
            />
            <TextContainer size="text-small" color="text-black" styles="mb-4">
              {t('timeline:show_car_info')}
            </TextContainer>

            <ActionButton
              size="medium"
              title={t('timeline:send_car')}
              onClick={() => onClickShare(`/shared-service/${car.id}`)}
              styles="mx-auto mb-1"
            />
            <TextContainer size="text-small" color="text-black" styles="mb-4">
              {t('timeline:send_car_info')}
            </TextContainer>

            <ActionButton
              size="medium"
              title={t('timeline:transfer_car')}
              onClick={() => onClickShare(`/transfer/${car.id}`)}
              styles="mx-auto mb-1"
            />
            <TextContainer size="text-small" color="text-black" styles="mb-4">
              {t('timeline:transfer_car_info')}
            </TextContainer>
          </div>
        ) : (
          <div className="max-w-128 mx-auto py-4">
            <TextContainer font="montserrat" styles="text-center mb-4" size="one-line-medium">
              {t('timeline:finish_register')}
            </TextContainer>
            <TextContainer size="one-line-regular" styles="flex items-center justify-center mb-12">
              <Image src={icons.pencil} styles="w-12 mr-2" />
              {t('timeline:verify')}
            </TextContainer>
            <TextContainer size="one-line-regular" font="montserrat" styles="mb-4 text-center">
              {t('timeline:advantage')}
            </TextContainer>
            <TextContainer size="one-line-regular" styles="flex items-center justify-center mb-12">
              <Image src={icons.share} styles="w-12 mr-2" />
              <span className="text-center">{t('timeline:share_others')}</span>
            </TextContainer>
            <LinkButton
              size="medium"
              title={t('timeline:verify')}
              to={paths.user.profile}
              styles="mx-auto mb-1"
            />
          </div>
        )}

      </UnautorizedModal>
    </div>
  );
};

export default SnapshotSection;
