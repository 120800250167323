import React, { FunctionComponent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import icons from '../../../assets/images/icons';
import { paths } from '../../../lib/routing';
import { useStoreActions, useStoreState } from '../../../store/hooks';
import Badge from '../../Badge';
import Image from '../../Image';

import LanguageSelector from './LanguageSelector';

type ToggleProps = {
  onClick: () => void;
}

type DecorationProps = {
  bgColor: string;
  width: string;
}

const BADGE_STYLES = 'absolute top-0 right-0';

const setBadge = (isVerificationPending: boolean): ReactNode => (
  isVerificationPending
    ? <Badge type="yellow" styles={BADGE_STYLES} />
    : <Badge type="red" styles={BADGE_STYLES} />
);

const Toggle: FunctionComponent<ToggleProps> = ({ onClick }: ToggleProps) => (
  <button
    type="button"
    className="toggle"
    onClick={onClick}
  >
    <Image src={icons.menu} styles="w-16 h-16 min-w-16" />
  </button>
);

const DecorationBar: FunctionComponent<DecorationProps> = ({ bgColor, width }: DecorationProps) => (
  <div className={`${bgColor} ${width}`} />
);

const Sidebar: FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { isVerified, isVerificationPending } = useStoreState((state) => state.user);
  const { destroy } = useStoreActions((actions) => actions.session);

  const logout = async (): Promise<void> => {
    history.replace(paths.home);
    await destroy();
  };

  return (
    <div className={`sidebar xl:right-0 ${open ? 'right-0' : '-right-32'}`}>
      <Toggle onClick={(): void => setOpen(!open)} />
      <DecorationBar bgColor="bg-red" width="w-6" />
      <DecorationBar bgColor="bg-white" width="w-2" />
      <div className="bg-primary-dark pt-24 w-24 flex flex-col items-center">
        <LanguageSelector authenticated />
        <Link to={paths.user.profile} className="my-6 relative" title={t('authenticated:profile')}>
          {!isVerified && setBadge(isVerificationPending)}
          <Image src={icons.user.white} styles="w-12" alt={t('authenticated:profile')} />
        </Link>
        <Link to={paths.user.invitations} className="my-6" title={t('authenticated:recomendations')}>
          <Image src={icons.bubble} styles="w-12" alt={t('authenticated:recomendations')} />
        </Link>
        <Link to={paths.user.settings} className="my-6" title={t('authenticated:settings')}>
          <Image src={icons.cog.white} styles="w-12" alt={t('authenticated:settings')} />
        </Link>
        <Link to={paths.subscriptions} className="my-6" title={t('subscriptions:subscriptions')}>
          <Image src={icons.credit} styles="w-12" alt={t('authenticated:settings')} />
        </Link>
        <button
          type="button"
          className="my-6"
          onClick={logout}
          title={t('authenticated:logout')}
        >
          <Image src={icons.logout} styles="w-12" alt={t('authenticated:logout')} />
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
