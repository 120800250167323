import React, { FunctionComponent, useState, useEffect } from 'react';
import { Location } from 'history';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useNavigation from '../../../../../services/hooks/navigation';
import ConfirmModal from '../../../../../components/modals/ConfirmModal';
import { useCheckTip } from '../../../../../services/hooks/settings';

type Props = {
  when: boolean;
}

const RouteLeavingGuard: FunctionComponent<Props> = ({ when }: Props) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLasLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const navigate = useNavigation();
  const checkTip = useCheckTip('vehicle');

  const closeModal = () => {
    checkTip(isCheck);
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && when) {
      setModalVisible(true);
      setLasLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    checkTip(isCheck);
    setModalVisible(true);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmModal
        isOpen={modalVisible}
        message={t('popups:go_back_tip')}
        onAcceptMessage={t('popups:reject_go_back')}
        onRejectMessage={t('popups:accept_go_back')}
        onReject={closeModal}
        onAccept={handleConfirmNavigationClick}
        isCheck={isCheck}
        onChangeCheck={() => setIsCheck(!isCheck)}
      />
    </>
  );
};

export default RouteLeavingGuard;
