import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import { EventAttributes } from '../../../../../store/types/event';
import { CategoryFamilyAttributes } from '../../../../../store/types/category';
import TextContainer from '../../../../../components/TextContainer';
import { groupSubcategories, nameWithoutLineFeed } from '../../../../../lib/helpers/categories';
import Image from '../../../../../components/Image';
import { setFirstImage } from '../../../../../lib/helpers/event';
import { DocumentURL } from '../../../../../store/types/document';
import date from '../../../../../services/date';

type EventCategoryProps = {
  eventCategory: CategoryFamilyAttributes;
}

type EventImageProps = {
  documents: DocumentURL[];
}

type Props = {
  event: EventAttributes;
}

const selectEventCategory = _.flow(
  _.orderBy(['category.id'], ['desc']),
  _.head,
);

const EventCategory: FunctionComponent<EventCategoryProps> = (
  {
    eventCategory,
  }: EventCategoryProps,
) => (
  <div key={eventCategory.category.id} className="flex items-center">
    <div className="min-w-16 min-h-16 rounded-full bg-primary-x-light flex items-center justify-center">
      <Image src={eventCategory.category.icon} styles="w-8 h-8" />
    </div>
    <div className="ml-2">
      <TextContainer font="montserrat" styles="mb-1" size="one-line-regular">
        {nameWithoutLineFeed(eventCategory.category.name)}
      </TextContainer>
      <TextContainer size="one-line-small">
        {_.truncate({ length: 20, omission: '...' })(groupSubcategories(eventCategory.subcategories))}
      </TextContainer>
    </div>
  </div>
);

const EventImage: FunctionComponent<EventImageProps> = ({ documents }: EventImageProps) => (
  <div className="w-1/2 flex justify-end">
    {(!_.isNil(documents) && !_.isEmpty(setFirstImage(documents))) && (
      <div
        className="has-bg-image w-16 h-16 rounded"
        style={{ backgroundImage: `url(${setFirstImage(documents)})` }}
      />
    )}
  </div>
);

const SnapshotEvent: FunctionComponent<Props> = ({ event }: Props) => (
  <div className="p-4 border border-primary rounded-md w-full mb-6 max-w-192">
    <div className="w-full flex items-center">
      <div className="w-2/3">
        {!_.isEmpty(event.eventCategories) && (
          <>
            {/* @ts-ignore */}
            <EventCategory eventCategory={selectEventCategory(event.eventCategories)} />
          </>
        )}
      </div>
      <div className="w-1/3 flex items-center">
        <div className="w-1/2">
          <TextContainer size="text-small" styles="uppercase text-center">
            {date.format(event.date, 'DD MMM')}
          </TextContainer>
        </div>
        <EventImage documents={event.documents} />
      </div>
    </div>
  </div>
);

export default SnapshotEvent;
