import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import TextContainer from '../TextContainer';

export type Props = {
  message: string;
  type?: 'error' | 'notice';
  styles?: string;
}

const Flash: FunctionComponent<Props> = (
  {
    message,
    type = 'error',
    styles = '',
  }: Props,
) => (
  <TextContainer
    tag="p"
    color={_.isEqual(type, 'error') ? 'text-red' : 'text-primary-dark'}
    size="one-line-small"
    styles={`min-h-6 mb-4 ${styles}`}
  >
    {message}
  </TextContainer>
);

export default Flash;
