import _ from 'lodash/fp';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import Image from '../../../../../components/Image';
import TextContainer from '../../../../../components/TextContainer';
import date from '../../../../../services/date';
import { EventAttributes } from '../../../../../store/types/event';
import { CategoryFamilyAttributes } from '../../../../../store/types/category';
import { nameWithoutLineFeed } from '../../../../../lib/helpers/categories';

import Field from './components/Field';
import Container from './components/Container';
import Serviced from './components/Serviced';
import DocumentsGallery from './components/DocumentsGallery';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  event: EventAttributes;
  eventURL?: string;
}

const ShowEvent: FunctionComponent<Props> = (
  {
    isOpen,
    onClose,
    event,
    eventURL = '',
  }: Props,
) => {
  const { t } = useTranslation();

  return (
    <Container isOpen={isOpen} onClose={onClose} eventURL={eventURL}>
      <div className="flex justify-between items-baseline mb-4">
        <TextContainer font="montserrat" size="one-line-regular" styles="mb-4">
          {event.title}
        </TextContainer>
        <TextContainer styles="uppercase" size="one-line-regular" font="montserrat">
          {date.format(event.date, 'DD/MMM/YYYY')}
        </TextContainer>
      </div>
      <div className="flex flex-wrap mb-4">
        {_.map((eventCategory: CategoryFamilyAttributes) => (
          <div key={eventCategory.category.id} className="min-w-16 max-w-16 w-16 h-16 rounded-full bg-primary-x-light flex items-center justify-center mr-2">
            <Image src={eventCategory.category.icon} styles="h-8" alt={nameWithoutLineFeed(eventCategory.category.name)} />
          </div>
        ))(event.eventCategories)}
      </div>
      {!_.isEmpty(event.documents) && <DocumentsGallery documents={event.documents} />}
      {!_.isEmpty(event.notes) && <Field content={event.notes} />}
      {!_.isEqual(event.cost, 0) && <Field title={t('event:cost')} content={event.cost} />}
      {!_.isEqual(event.mileage, 0) && <Field title={t('event:mileage')} content={event.mileage} />}
      {!_.isEmpty(event.servicedBy.name) && <Serviced servicedBy={event.servicedBy} />}
    </Container>
  );
};

export default ShowEvent;
