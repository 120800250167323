const handleUnauthorized = (easyPeasyStore) => (
  (error) => {
    if (error.response?.status === 401) {
      easyPeasyStore.getActions().session.logout();
    }

    return Promise.reject(error);
  }
);

export default handleUnauthorized;
