import _ from 'lodash/fp';
import React, { ChangeEvent, FunctionComponent } from 'react';

import icons from '../../assets/images/icons';
import {
  getDocument,
  isPdfBase64,
  isPDFURL,
} from '../../lib/helpers/documents';
import { onChangeFiles } from '../../lib/helpers/forms';
import { DocumentName, DocumentURL } from '../../store/types/document';
import PopupGallery from '../PopupGallery';
import PopupPreview from '../PopupPreview';

import FormInput from './components/FormInput';
import InputError from './components/InputError';
import InputLabel from './components/InputLabel';

type Props = {
  name: DocumentName;
  values: string[];
  currentValues: DocumentURL[];
  placeholder: string;
  handleDocumentChange: (name: DocumentName, value: File | FileList) => Promise<void>;
  deleteDocument: (name: DocumentName, value: string) => void;
  deletePrevious: (id: number) => void;
  errors?: string;
  readonly?: boolean;
  disabled?: boolean;
  accept?: 'image/png, image/jpeg' | 'image/png, image/jpeg, application/pdf';
}

const MultipleFileInput: FunctionComponent<Props> = (
  {
    name,
    values,
    currentValues,
    placeholder,
    handleDocumentChange,
    deleteDocument,
    deletePrevious,
    errors = '',
    readonly = false,
    disabled = false,
    accept = 'image/png, image/jpeg',
  }: Props,
) => (
  <FormInput styles="multiple">
    <InputLabel name={name} placeholder={placeholder} />
    <input
      type="file"
      name={name}
      id={name}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const result = onChangeFiles(e);
        if (!_.isUndefined(result)) {
          handleDocumentChange(name, result);
        }
      }}
      readOnly={readonly}
      disabled={disabled}
      multiple
      className="hidden"
      accept={accept}
    />
    {!readonly ? (
      <div className="flex-1 flex flex-wrap">
        {_.map((currentValue: DocumentURL) => (
          <PopupPreview
            key={currentValue.id}
            value={isPDFURL(currentValue.full) ? icons.pdf : getDocument(currentValue, 'thumb')}
            openValue={isPDFURL(currentValue.full) ? icons.pdf : getDocument(currentValue, 'full')}
            deleteDocument={!readonly ? () => deletePrevious(currentValue.id) : undefined}
          />
        ))(currentValues)}
        {_.map((value: string) => (
          <PopupPreview
            key={value}
            value={isPdfBase64(value) ? icons.pdf : value}
            openValue={isPdfBase64(value) ? icons.pdf : value}
            deleteDocument={!readonly ? () => deleteDocument(name, value) : undefined}
          />
        ))(values)}
      </div>
    ) : (
      <PopupGallery
        slides={_.toArray(currentValues)}
      />
    )}
    <InputError errors={errors} />
  </FormInput>
);

export default MultipleFileInput;
