import React, { FunctionComponent } from 'react';

import icons from '../../../assets/images/icons';
import Image from '../../Image';

type Props = {
  active: boolean;
  setActive: () => void;
}

const InputIcon: FunctionComponent<Props> = ({ active, setActive }: Props) => (
  <button type="button" onClick={setActive}>
    {active
      ? <Image src={icons.eye.regular} styles="w-12 h-12 absolute right-8 top-8" />
      : <Image src={icons.eye.slash} styles="w-12 h-12 absolute right-8 top-8" />}
  </button>
);

export default InputIcon;
