import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  children: ReactNode;
}

const RequestPanel: FunctionComponent<Props> = ({ children }: Props) => (
  <div className="border rounded-md border-primary expand justify-center px-12 w-full max-w-screen-xs">
    {children}
  </div>
);

export default RequestPanel;
