import _ from 'lodash/fp';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import graphics from '../../../../assets/images/graphics';
import icons from '../../../../assets/images/icons';
import LinkIcon from '../../../../components/buttons/LinkIcon';
import Authenticated from '../../../../components/layout/Authenticated';
import Back from '../../../../components/layout/components/Back';
import AppLoader from '../../../../components/loaders/AppLoader';
import TextContainer from '../../../../components/TextContainer';
import { carImage, carTitle, isCarComplete } from '../../../../lib/helpers/car';
import { hasTransferPending } from '../../../../lib/helpers/viewers';
import { paths } from '../../../../lib/routing';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import { CarAttributes } from '../../../../store/types/car';

const setImage = (thumbnail: string): string => (
  _.isEmpty(thumbnail) ? graphics.defaultCarThumb : thumbnail
);

const MyCars: FunctionComponent = () => {
  const { index } = useStoreActions((actions) => actions.cars);
  const { cars } = useStoreState((state) => state.cars);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => index().then((result: boolean) => setIsLoading(!result)), []);

  if (isLoading) return <AppLoader />;

  return (
    <Authenticated>
      <div className="py-8 flex items-center">
        <Back url={paths.home} styles="mr-4" />
        <TextContainer tag="h1" size="one-line-large" font="montserrat-bold" styles="uppercase flex items-center">
          <img src={icons.myCars} className="h-24 mr-4" alt={t('icon')} />
          {t('home:my_cars')}
        </TextContainer>
      </div>
      <div className="flex flex-wrap justify-center md:justify-start">
        {_.map((car: CarAttributes) => (
          <Link
            to={paths.car.edit(car.id)}
            key={car.id}
            className="w-full max-w-screen-initial flex flex-col items-center mb-8"
          >
            <div
              className="has-bg-image w-128 h-128 rounded-xl"
              style={{ backgroundImage: `url(${setImage(carImage(car.carImages, 'large'))})` }}
            />
            <div className="py-4 max-w-128">
              <TextContainer tag="h5" font="montserrat" styles="max-h-24">
                {carTitle(car)}
              </TextContainer>
              {!isCarComplete(car) && (
              <TextContainer size="one-line-small" color="text-gray-2" styles="text-right">
                {t('car:draft')}
              </TextContainer>
              )}
              {isCarComplete(car) && hasTransferPending(car.viewers) && (
              <TextContainer size="one-line-small" color="text-gray-2" styles="text-right">
                {t('share:transfer_pending')}
              </TextContainer>
              )}
            </div>
          </Link>
        ))(cars)}
        <div className="w-full max-w-screen-initial flex flex-col items-center justify-center mb-8">
          <LinkIcon
            to={paths.car.new}
            icon={icons.add.empty}
            title={t('car:new_car')}
          />
        </div>
      </div>
    </Authenticated>
  );
};

export default MyCars;
