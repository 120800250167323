import React, {
  ChangeEvent,
  FocusEvent,
  FunctionComponent,
  ReactNode,
} from 'react';

import { hasErrors, isTouched } from '../../lib/helpers/forms';

import FormInput from './components/FormInput';
import InputError from './components/InputError';

export type Props = {
  name: string;
  value: boolean;
  children: ReactNode;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  touched?: boolean;
  errors?: string;
  checkPosition?: 'left' | 'right'
  simple?: boolean;
  tip?: boolean
}

const Checkbox: FunctionComponent<Props> = (
  {
    name,
    value,
    children,
    onChange,
    onBlur = undefined,
    touched = false,
    errors = '',
    checkPosition = 'left',
    simple = false,
    tip = false,
  }: Props,
) => (
  <FormInput checkbox tip={tip}>
    <div className={checkPosition}>
      <label htmlFor={name}>{children}</label>
      <input
        type="checkbox"
        name={name}
        id={name}
        onChange={onChange}
        onBlur={onBlur}
        checked={value}
      />
    </div>
    {!simple && (
      <InputError errors={hasErrors(errors) && isTouched(touched) ? errors : ''} />
    )}
  </FormInput>
);

export default Checkbox;
