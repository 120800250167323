import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import TextContainer from '../../../../../components/TextContainer';
import { EventAttributes, MonthEvents, YearEvents } from '../../../../../store/types/event';
import {
  getMajorCategories,
  getMinorCategories,
  isFiltered,
} from '../../../../../lib/helpers/categories';

import Month from './Month';

type Props = {
  year: YearEvents;
  onClickEvent: (event: EventAttributes) => void;
  styles?: 'regular' | 'white'
  filter?: string;
}

const majorCategories = _.flow(
  _.flatMap('events'),
  _.flatMap('eventCategories'),
  getMajorCategories,
);

const minorCategories = _.flow(
  _.flatMap('events'),
  _.flatMap('eventCategories'),
  getMinorCategories,
);

const joinCategories = (year: YearEvents): string[] => (
  _.concat(minorCategories(year.eventsByMonth), majorCategories(year.eventsByMonth))
);

const Year: FunctionComponent<Props> = ({
  year, onClickEvent, styles = 'regular', filter = '',
}: Props) => (
  <div className={`year ${styles} w-full max-w-screen-xs mb-4 ${isFiltered(filter, joinCategories(year)) ? 'filtered' : ''}`}>
    <div className="w-full">
      <TextContainer size="one-line-medium" font="montserrat-bold" styles="year-title">
        {year.year}
      </TextContainer>
    </div>
    {_.map((month: MonthEvents) => (
      <Month
        key={month.month}
        month={month}
        onClickEvent={onClickEvent}
        filter={filter}
      />
    ))(year.eventsByMonth)}
  </div>
);

export default Year;
