import { FormikErrors, FormikTouched } from 'formik';
import React, {
  FunctionComponent,
  SetStateAction,
  useState,
  Dispatch,
  ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';

import { DocumentName } from '../../../../../store/types/document';
import { EventAttributes, EventParams } from '../../../../../store/types/event';
import {
  deleteDocument,
  deleteSavedDocument,
  fileInputValues,
  joinImages,
  reachedMaxImages,
  reachedWithNewImages,
  saveDocuments,
} from '../../../../../lib/helpers/documents';
import { useStoreActions } from '../../../../../store/hooks';
import { isValidResponse } from '../../../../../api/axios';
import { useCheckTip } from '../../../../../services/hooks/settings';
import InfoModal from '../../../../../components/modals/InfoModal';
import ConfirmModal from '../../../../../components/modals/ConfirmModal';
import TextInput from '../../../../../components/forms/TextInput';
import MultipleFileInput from '../../../../../components/forms/MultipleFileInput';
import SubmitButton from '../../../../../components/buttons/SubmitButton';
import { CategoryFamilyAttributes } from '../../../../../store/types/category';
import DateInput from '../../../../../components/forms/DateInput';
import { CarAttributes } from '../../../../../store/types/car';
import { minimumEventDate } from '../../../../../lib/helpers/event';
import icons from '../../../../../assets/images/icons';
import Image from '../../../../../components/Image';

import Summary from './components/Summary';
import TextareaInput from './components/TextAreaInput';
import HighlightInput from './components/HighlightInput';

type Props = {
  car: CarAttributes
  event: EventAttributes;
  values: EventParams;
  errors: FormikErrors<EventParams>;
  touched: FormikTouched<EventParams>;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  /* eslint-disable max-len */
  handleChange: { (e: React.ChangeEvent<any>): void; <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void; };
  handleBlur: { (e: React.FocusEvent<any>): void; <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void; }
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<EventParams>>
  /* eslint-enable @typescript-eslint/no-explicit-any */
  /* eslint-enable max-len */
  setSubmitting: (isSubmitting: boolean) => void;
  isSubmitting: boolean;
  isValid: boolean;
  handleSubmittion: (data: EventParams) => Promise<void>
  isConfirm: boolean;
  setIsConfirm: Dispatch<SetStateAction<boolean>>;
}

const MAX_EVENT_DOCUMENTS = 10;

const Info: FunctionComponent<Props> = (
  {
    car,
    event,
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setSubmitting,
    isSubmitting,
    isValid,
    handleSubmittion,
    isConfirm,
    setIsConfirm,
  }: Props,
) => {
  const { t } = useTranslation();
  const { destroy } = useStoreActions((actions) => actions.documents);
  const [isMaxDocs, setIsMaxDocs] = useState(false);
  const [documents, setDocuments] = useState(event.documents);
  const [check, setChek] = useState(false);
  const checkTip = useCheckTip('event');

  const handleDocumentChange = async (
    name: DocumentName,
    value: File | FileList,
  ): Promise<void> => {
    if (
      !reachedWithNewImages(
        fileInputValues(values.documents),
        documents,
        value as FileList,
        MAX_EVENT_DOCUMENTS,
      )
    ) {
      setFieldValue(name, [
        ...values.documents,
        ...await saveDocuments(value as FileList),
      ]);
      return;
    }

    setIsMaxDocs(true);
  };

  const onDeleteDocument = (name: DocumentName, value: string): void => {
    setFieldValue(name, [
      ...deleteDocument(value, values.documents),
    ]);
  };

  const onDeletePreviousDocument = async (id: number): Promise<void> => {
    const response = await destroy(id);
    if (isValidResponse(response)) {
      setDocuments([...deleteSavedDocument(id, documents)]);
    }
  };

  const setNumberField = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (_.isEmpty(value)) {
      setFieldValue(name, 0);
      return;
    }

    const number = _.isEqual(name, 'cost') ? parseFloat(value) : parseInt(value, 10);
    if (!_.isNaN(number)) {
      setFieldValue(name, number);
    }
  };

  const setHighlight = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = e.target;
    setFieldValue(name, checked);
  };

  const onAccept = (data: EventParams): void => {
    checkTip(check);
    setIsConfirm(false);
    handleSubmittion(data);
  };

  const onReject = (): void => {
    checkTip(check);
    setIsConfirm(false);
    setSubmitting(false);
  };

  return (
    <div className="expand info max-w-screen-lg mx-auto">
      <InfoModal
        isOpen={isMaxDocs}
        onAccept={() => setIsMaxDocs(false)}
        message={t('errors:max_images_reached')}
      />
      <ConfirmModal
        isOpen={isConfirm}
        message={t('popups:new_event_advice')}
        onAccept={() => onAccept(values)}
        onReject={onReject}
        onAcceptMessage={t('popups:event_to_timeline')}
        onRejectMessage={t('popups:review_event')}
        isCheck={check}
        onChangeCheck={() => setChek(!check)}
      />
      <div className="relative md:flex md:w-full mb-16">
        <div className="flex items-baseline mb-4">
          <label htmlFor="title">
            <Image src={icons.pencil} styles="w-12 h-12 mr-4" />
          </label>
          <TextInput
            name="title"
            value={values.title}
            placeholder={t('event:attributes:title')}
            onChange={handleChange('title')}
            onBlur={handleBlur('title')}
            touched={touched.title}
            errors={errors.title}
            styles="add-title"
          />
          <div className="w-full absolute top-6 right-full border-b border-primary-light mr-12" />
        </div>
        <div className="hidden md:block flex-1">
          <SubmitButton
            title={values.isDraft ? t('add_event:save_draft') : t('add_event:add_event_timeline')}
            disabled={!isValid || isSubmitting}
            type={values.isDraft ? 'regular' : 'alert'}
            styles="ml-auto"
          />
        </div>
      </div>
      <div className="xs:max-w-192 xs:mx-auto md:flex md:max-w-none md:justify-between md:w-full md:mx-0">
        <div className="md:max-192 md:w-192 flex flex-col justify-between">
          <Summary selection={values.eventCategories as CategoryFamilyAttributes[]} />
        </div>
        <div className="md:max-w-192 md:w-192">
          <DateInput
            name="date"
            value={values.date}
            placeholder={t('event:attributes:date')}
            onChange={handleChange('date')}
            onBlur={handleBlur('date')}
            touched={touched.date}
            errors={errors.date}
            styles="event-input"
            min={minimumEventDate(car)}
            max={new Date()}
            red
          />
          <TextareaInput
            name="notes"
            value={values.notes}
            placeholder={t('event:attributes:notes')}
            onChange={handleChange('notes')}
            onBlur={handleBlur('notes')}
            touched={touched.notes}
            errors={errors.notes}
            styles="event-input"
          />
          <MultipleFileInput
            name="documents"
            values={fileInputValues(values.documents)}
            currentValues={documents}
            placeholder={
              t('event:attributes:attach_files', {
                number: _.size(joinImages(fileInputValues(values.documents), documents)),
              })
            }
            handleDocumentChange={handleDocumentChange}
            deleteDocument={onDeleteDocument}
            deletePrevious={onDeletePreviousDocument}
            errors={
              reachedMaxImages(
                fileInputValues(values.documents),
                documents,
                MAX_EVENT_DOCUMENTS,
              )
                ? t('errors:max_images_reached')
                : ''
            }
            disabled={
              reachedMaxImages(
                fileInputValues(values.documents),
                documents,
                MAX_EVENT_DOCUMENTS,
              )
            }
            accept="image/png, image/jpeg, application/pdf"
          />
          <TextInput
            name="cost"
            value={`${values.cost}`}
            placeholder={t('event:attributes:cost')}
            onChange={setNumberField}
            onBlur={handleBlur('cost')}
            touched={touched.cost}
            errors={errors.cost}
            styles="event-input"
          />
          <TextInput
            name="mileage"
            value={`${values.mileage}`}
            placeholder={t('event:attributes:mileage')}
            onChange={setNumberField}
            onBlur={handleBlur('mileage')}
            touched={touched.mileage}
            errors={errors.mileage}
            styles="event-input"
          />
          <HighlightInput
            name="isHighlighted"
            value={values.isHighlighted}
            onChange={setHighlight}
          />
          <div className="text-right md:hidden">
            <SubmitButton
              title={values.isDraft ? t('add_event:save_draft') : t('add_event:add_event_timeline')}
              disabled={!isValid || isSubmitting}
              type={values.isDraft ? 'regular' : 'alert'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
