import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  children: ReactNode;
}

const ModalBackground: FunctionComponent<Props> = ({ isOpen, children }: Props) => (
  <div className={`modal-background ${isOpen ? 'top-0' : 'top-full'}`}>
    {children}
  </div>
);

export default ModalBackground;
