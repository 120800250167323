import _ from 'lodash/fp';

import json from '../assets/data/make-model.json';

const { data } = json;

export const makers: string[] = _.flow(_.map('make'), _.uniq)(data);

export const models = (make: string): string[] => (
  _.isEmpty(make)
    ? []
    : _.flow(
      _.filter(['make', make]),
      _.map('model'),
    )(data)
);

export const bodyTypes: string[] = [
  'Baquet',
  'Hatchback',
  'Sedan',
  'SUV',
  'Coupe',
  'Convertible',
  'Targa',
  'Wagon',
  'Van',
  '4X4',
  'Bus',
  'Trailer',
  'Tractor',
  'Single Seater',
  'Racing Sport',
  'Sport Protoype',
  'Truck',
];
