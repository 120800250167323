import React, { Fragment, FunctionComponent, useState } from 'react';
import _ from 'lodash/fp';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import classnames from 'classnames';

import icons from '../assets/images/icons';
import { getDocument, isPDFURL } from '../lib/helpers/documents';
import { DocumentSize, DocumentURL } from '../store/types/document';

import Image from './Image';

type Props = {
  slides: DocumentURL[];
  styles?: string;
  thumbSize?: DocumentSize;
}

const PopupGallery: FunctionComponent<Props> = (
  {
    slides,
    styles = 'gallery-thumbs',
    thumbSize = 'thumb',
  }: Props,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);

  const popupClass = classnames('fixed z-50 w-full h-screen bg-white top-0 left-0', {
    hidden: !isOpen,
  });

  const imageSlides = _.flow(
    _.filter((slide: DocumentURL) => !isPDFURL(slide.full)),
    _.map((slide: DocumentURL) => (
      <Image key={slide.id} src={slide.full} styles="carousel-image" />
    )),
  )(slides);

  const onOpenGallery = (index: number) => {
    setSelectedItem(index);
    setIsOpen(true);
  };

  return (
    <>
      <div className={styles}>
        {slides.map((slide: DocumentURL, index: number) => (
          <Fragment key={slide.id}>
            {isPDFURL(slide.full) ? (
              <a className="gallery-thumb block" href={slide.full} target="_blank" rel="noreferrer">
                <Image src={icons.pdf} />
              </a>
            ) : (
              <div
                className="gallery-thumb cursor-pointer"
                onClick={() => onOpenGallery(index)}
                style={{ backgroundImage: `url(${getDocument(slide, thumbSize)})` }}
              />
            )}
          </Fragment>
        ))}
      </div>
      <div className={popupClass}>
        <div className="wrap">
          <button
            type="button"
            onClick={() => setIsOpen(false)}
            className="flex justify-end items-center h-16 w-full mb-8 mt-8"
          >
            <Image src={icons.cross.blue} styles="w-12 h-12" />
          </button>
          <Carousel
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
            selectedItem={selectedItem}
            interval={999999}
            swipeScrollTolerance={150}
            preventMovementUntilSwipeScrollTolerance
          >
            {imageSlides}
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default PopupGallery;
