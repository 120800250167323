import { AxiosResponse } from 'axios';

import { SnapshotViewParams, ViewerCreationParams, ViewerParams } from '../../store/types/viewers';
import client, { request } from '../axios';

export const show = async (params: SnapshotViewParams): Promise<AxiosResponse> => (
  request(client.get(`/snapshot/${params.carID}/${params.token}`))
);

export const create = async (params: ViewerCreationParams): Promise<AxiosResponse> => (
  request(client.post(
    `/cars/${params.carID}/viewers/${params.token}`, {
      accessLevel: params.accessLevel,
      pending: params.pending,
    },
  ))
);

export const update = async (params: ViewerParams): Promise<AxiosResponse> => (
  request(client.put(`/cars/${params.carID}/viewers/${params.id}`))
);

export const destroy = async (params: ViewerParams): Promise<AxiosResponse> => (
  request(client.delete(`/cars/${params.carID}/viewers/${params.id}`))
);

export const transfer = async (params: ViewerParams): Promise<AxiosResponse> => (
  request(client.post(`/cars/${params.carID}/viewers/${params.id}/transfer`, { ...params }))
);
