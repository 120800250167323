import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { AxiosResponse } from 'axios';

import { useStoreActions, useStoreState } from '../../../store/hooks';
import Authenticated from '../../../components/layout/Authenticated';
import { paths } from '../../../lib/routing';
import icons from '../../../assets/images/icons';
import Switch from '../../../components/Switch';
import SubmitButton from '../../../components/buttons/SubmitButton';
import SubmitLoader from '../../../components/loaders/SubmitLoader';
import { isValidResponse } from '../../../api/axios';
import useNavigation from '../../../services/hooks/navigation';

import Form from './components/Form';

type FormParams = {
  assistant: boolean;
  demoCar: boolean;
  tips: boolean;
}

const TOTAL_TIPS = 4;

// @ts-ignore
const setTips = _.flow(
  _.get('tips'),
  _.valuesIn,
  _.compact,
  _.size,
  _.isEqual(_, TOTAL_TIPS),
);

const Settings: FunctionComponent = () => {
  const { settings } = useStoreState((state) => state.user.user);
  const { update } = useStoreActions((actions) => actions.settings);
  const { flash } = useStoreActions((actions) => actions);
  const { t } = useTranslation();
  const navigate = useNavigation();

  const handleOnSubmit = async (data: FormParams): Promise<void> => {
    const newSettings = { ...settings };
    newSettings.assistant.active = data.assistant;
    newSettings.demoCar = data.demoCar;
    newSettings.tips.draft = data.tips;
    newSettings.tips.vehicle = data.tips;
    newSettings.tips.engine = data.tips;
    newSettings.tips.event = data.tips;
    const response: AxiosResponse = await update(newSettings);
    if (isValidResponse(response)) {
      flash.set({ value: t('flash:success:settings'), type: 'success' });
      navigate(paths.home);
      return;
    }
    flash.set({ value: t('flash:error:settings'), type: 'error' });
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: {
      assistant: settings.assistant.active,
      demoCar: settings.demoCar,
      tips: setTips(settings) as boolean,
    },
    onSubmit: handleOnSubmit,
  });

  return (
    <Authenticated>
      <Form onSubmit={handleSubmit} title={t('authenticated:settings')} icon={icons.cog.blue}>
        {isSubmitting && <SubmitLoader />}
        <Switch
          value={values.assistant}
          placeholder={t('timeline:assistant_mode')}
          onChange={(e) => setFieldValue('assistant', e.target.checked)}
          border
        />
        <Switch
          value={values.demoCar}
          placeholder={t('user:attributes:setting_demo_car')}
          onChange={(e) => setFieldValue('demoCar', e.target.checked)}
          border
        />
        <Switch
          value={values.tips}
          placeholder={t('user:attributes:setting_tips')}
          onChange={(e) => setFieldValue('tips', e.target.checked)}
          border
        />
        {dirty && (
          <div className="w-full max-w-192 flex justify-end mt-12 mx-auto">
            <SubmitButton title={t('save')} disabled={isSubmitting} />
          </div>
        )}
      </Form>
    </Authenticated>
  );
};

export default Settings;
