import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import { carImage, carName } from '../../../../lib/helpers/car';
import { paths } from '../../../../lib/routing';
import { CarAttributes } from '../../../../store/types/car';
import TextContainer from '../../../../components/TextContainer';
import { DEMO_CAR_ID } from '../../../../store/model/cars/cars';
import i18n from '../../../../services/i18n';
import Tag from '../../../../components/Tag';
import icons from '../../../../assets/images/icons';
import useNavigation from '../../../../services/hooks/navigation';

import BaseCard from './components/BaseCard';

export type Props ={
  car: CarAttributes;
  accessLevel: string;
  pending: boolean;
}

const isDemoCar = (id: number): boolean => (
  _.isEqual(id, DEMO_CAR_ID)
);

const setURL = (accessLevel: string, id: number): string => {
  if (_.isEqual(accessLevel, 'FULL') || _.isEqual(accessLevel, 'TRANSFER')) {
    return paths.sharedCar(id);
  }
  if (_.isEqual(accessLevel, 'GARAGE')) return paths.serviceCar.edit(id);

  return paths.snapshot(id);
};

const SharedWithMeCard: FunctionComponent<Props> = ({ car, accessLevel, pending }: Props) => {
  const navigate = useNavigation();
  return (
    <BaseCard thumbnail={carImage(car.carImages, 'medium')} url={setURL(accessLevel, car.id)}>
      <div className="flex flex-1 flex-col justify-between">
        <div>
          <TextContainer tag="h5" font="montserrat" styles="max-h-12 overflow-hidden">
            {carName(car, 27)}
          </TextContainer>
          <TextContainer size="one-line-small" color="text-gray-2">
            {i18n.t(`share:${accessLevel}`)}
          </TextContainer>
        </div>

        <div className="flex flex-col justify-end items-end">
          {isDemoCar(car.id)
            ? <Tag text={i18n.t('share:demo_car')} type="blue" />
            : (
              <Tag
                text={pending ? i18n.t('share:pending') : i18n.t('share:verified')}
                type={pending ? 'gray' : 'green'}
                icon={pending ? '' : icons.verified.white}
                action={() => navigate(setURL(accessLevel, car.id))}
              />
            )}
        </div>
      </div>
    </BaseCard>
  );
};

export default SharedWithMeCard;
