import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { setCalendlyCookie } from '../../../services/cookies';
import { useStoreActions, useStoreState } from '../../../store/hooks';
import CloseTag from '../../modals/components/CloseTag';
import TextContainer from '../../TextContainer';

const Calendly: FunctionComponent = () => {
  const { setCalendly } = useStoreActions((actions) => actions.session);
  const { calendly } = useStoreState((state) => state.session);
  const { t } = useTranslation();

  const onCloseCalendly = (): void => {
    setCalendlyCookie();
    setCalendly(false);
  };

  const popupClass = classnames('calendly', {
    hidden: !calendly,
    block: calendly,
  });

  return (
    <div className={popupClass}>
      <CloseTag onClick={onCloseCalendly} />
      <TextContainer font="montserrat-bold" size="one-line-small" styles="mb-2 text-center">
        {t('calendly_title')}
      </TextContainer>
      <TextContainer font="opensans" size="one-line-small" styles="mb-4 text-center">
        {t('calendly')}
      </TextContainer>
      <a
        href="https://calendly.com/julio-saiz/30min"
        target="_blank"
        rel="noreferrer"
        className="text-blue montserrat-bold block underline one-line-small text-center"
      >
        {t('calendly_link')}
      </a>
    </div>
  );
};

export default Calendly;
