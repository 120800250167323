import React, { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash/fp';

import graphics from '../../../assets/images/graphics';
import { paths } from '../../../lib/routing';
import Image from '../../Image';

import LanguageSelector from './LanguageSelector';

type Props = {
  authenticated?: boolean;
  title?: ReactNode;
}

const Header: FunctionComponent<Props> = ({ authenticated = false, title }: Props) => (
  <header id="site-header">
    <div className="p-4 flex items-start justify-between">
      <Link to={paths.root} className="flex items-center">
        <Image src={graphics.logo} alt="The motor chain" styles="sm:mr-2 h-24" />
        <Image src={graphics.textLogo} alt="The motor chain" styles="hidden sm:block h-8" />
      </Link>
      {!_.isUndefined(title) && title}
      {!authenticated && <LanguageSelector authenticated={authenticated} />}
    </div>
  </header>
);

export default Header;
