import { DocumentBase64, DocumentURL } from './document';
import { ViewerAttributes } from './viewers';

export type Page = 'vehicle-data' | 'snapshot' | 'timeline';

type CarBase = {
  id: number;
  carMake: string;
  modelName: string;
  bodyType: string;
  manufactureYear: string;
  engine: string;
  gearbox: string; // Transmission
  chassis: string;

  intro: string;
  nickName: string;
  licensePlate: string;
  color: string; // Body color
  seatsColor: string;
  upholsteryColor: string; // Carpets color
  dashboard: string; // DashboardColor
  firstRegistration: string;
}

export type CarParams = CarBase & {
  images: DocumentBase64[];
  engineFile: DocumentBase64;
  gearboxFile: DocumentBase64; // Transmission
  chassisFile: DocumentBase64;
}

export type CarAttributes = CarBase & {
  carImages: DocumentURL[];
  engineImage: DocumentURL;
  gearboxImage: DocumentURL;
  chassisImage: DocumentURL;

  lastTimeEngineStart?: string;
  viewers: ViewerAttributes[];
  endSubscription: string;
  subscriptionCanceled: boolean;
  subscribed: boolean;
  coupon: string;
};

const emptyDocument: DocumentURL = {
  id: -1,
  thumb: '',
  medium: '',
  large: '',
  full: '',
};

export const emptyCar: CarAttributes = {
  id: -1,
  carMake: '',
  modelName: '',
  bodyType: '',
  manufactureYear: '',
  engine: '',
  gearbox: '',
  chassis: '',

  carImages: [],
  chassisImage: emptyDocument,
  engineImage: emptyDocument,
  gearboxImage: emptyDocument,

  intro: '',
  nickName: '',
  licensePlate: '',
  color: '',
  seatsColor: '',
  upholsteryColor: '',
  dashboard: '',
  firstRegistration: '',
  lastTimeEngineStart: '',
  viewers: [],
  endSubscription: '',
  subscriptionCanceled: false,
  subscribed: false,
  coupon: '',
};
