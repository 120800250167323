import React, { FunctionComponent } from 'react';

import icons from '../../../../../../assets/images/icons';
import LinkIcon from '../../../../../../components/buttons/LinkIcon';
import NavigationLink from '../../../../../../components/NavigationLink';
import TextContainer from '../../../../../../components/TextContainer';
import { paths } from '../../../../../../lib/routing';
import i18n from '../../../../../../services/i18n';
import { DEMO_CAR_ID } from '../../../../../../store/model/cars/cars';
import { Question } from '../../../../../../utils/assistant';

type Props = {
  carID: number;
  question: Question;
  isDemoCar: boolean;
}

const QuestionItem: FunctionComponent<Props> = ({ question, isDemoCar, carID }: Props) => (
  <div className="flex">
    <div className="min-w-24 w-24 max-w-24 border-r border-white" />
    <div className="pl-24 py-16 w-full relative">
      <LinkIcon
        icon={icons.add.white}
        title={i18n.t('authenticated:add_event')}
        to={`${paths.events.new(carID)}?category=${question.initialCategory}`}
        styles="absolute -left-24 top-12"
      />
      <TextContainer color="text-white" font="montserrat" styles="mb-2">
        {i18n.t(question.question)}
      </TextContainer>
      <div>
        <TextContainer color="text-white" size="text-small" styles="mb-4">
          {i18n.t(question.description)}
        </TextContainer>
        {isDemoCar && (
          <NavigationLink
            to={paths.sharedCar(DEMO_CAR_ID)}
            text={i18n.t('assistant:see_demo_car')}
            styles="border border-white inline-block py-4 px-6"
            color="text-white"
          />
        )}
      </div>
    </div>
  </div>
);

export default QuestionItem;
