import {
  Action,
  action,
  computed,
  Computed,
  Thunk,
  thunk,
} from 'easy-peasy';
import _ from 'lodash/fp';

import { isValidResponse } from '../../../api/axios';
import date from '../../../services/date';
import {
  emptyUser, AccountDelete, NewUserParams, UserAttributes, UserParams,
} from '../../types/user';

export interface UserModel {
  user: UserAttributes;

  init: Action<UserModel, UserAttributes>;

  index: Thunk<UserModel>;
  create: Thunk<UserModel, NewUserParams>;
  update: Thunk<UserModel, UserParams>;
  destroy: Thunk<UserModel, AccountDelete>;
  portal: Thunk<UserModel>;

  suggest: Thunk<UserModel, string>;

  isVerificationPending: Computed<UserModel, boolean>;
  isVerified: Computed<UserModel, boolean>;
}

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{7,}$/;

const userModel: UserModel = {
  user: emptyUser,

  init: action((state, params) => {
    state.user = { ...params };
  }),

  index: thunk(async (actions, _params, { injections }) => {
    const { userService } = injections;
    const response = await userService.index();
    if (isValidResponse(response)) {
      actions.init(response.data as UserAttributes);
    }
  }),

  create: thunk(async (actions, params, { injections }) => {
    const { userService } = injections;
    return userService.create(params);
  }),

  update: thunk(async (actions, params, { injections }) => {
    const { userService } = injections;
    const response = await userService.update(params);

    if (isValidResponse(response)) {
      actions.init(response.data as UserAttributes);
    }

    return isValidResponse(response);
  }),

  destroy: thunk(async (_actions, params, { injections, getStoreActions }) => {
    const { userService } = injections;
    const response = await userService.destroy(params);
    if (isValidResponse(response)) {
      // @ts-ignore
      getStoreActions().session.destroy();
      return '';
    }

    return response.data as string;
  }),

  portal: thunk(async (_actions, _params, { injections }) => {
    const { userService } = injections;
    const response = await userService.billingPortal();
    if (isValidResponse(response)) {
      return response.data;
    }

    return '';
  }),

  suggest: thunk(async (actions, email, { injections }) => {
    const { userService } = injections;
    const response = await userService.suggest(email);

    return response;
  }),
  isVerificationPending: computed((state) => (
    !state.isVerified && !_.isEmpty(state.user.document.full)
  )),
  isVerified: computed((state) => !date.isNullDate(state.user.verifiedDate)),
};

export default userModel;
