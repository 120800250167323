import {
  Thunk,
  thunk,
} from 'easy-peasy';

import { RecoveryPasswordParams } from '../../types/passwords';

export interface PasswordsModel {
  create: Thunk<PasswordsModel, string>;
  update: Thunk<PasswordsModel, RecoveryPasswordParams>;
}

const passwordsModel: PasswordsModel = {
  create: thunk(async (actions, email, { injections }) => {
    const { passwordsService } = injections;
    const response = await passwordsService.create(email);
    return response;
  }),

  update: thunk(async (actions, params, { injections }) => {
    const { passwordsService } = injections;
    const response = await passwordsService.update(params);
    return response;
  }),
};

export default passwordsModel;
