import React, { FunctionComponent, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';

import TextContainer from '../../../../../components/TextContainer';
import { CategoryFamilyAttributes, SubcategoryAttributes } from '../../../../../store/types/category';
import MajorCategory from '../../categories/MajorCategory';
import ActionButton from '../../../../../components/buttons/ActionButton';
import Image from '../../../../../components/Image';
import icons from '../../../../../assets/images/icons';

import MinorCategory from './components/MinorCategory';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  categories: CategoryFamilyAttributes[];
  setFilter: (name: string) => void;
}

const Filter: FunctionComponent<Props> = (
  {
    isOpen,
    onClose,
    categories,
    setFilter,
  }: Props,
) => {
  const [selected, setSelected] = useState<CategoryFamilyAttributes>(categories[0]);
  const [minorSelected, setMinorSelected] = useState<SubcategoryAttributes | null>(null);
  const { t } = useTranslation();
  const subcategoriesRef = useRef(null);

  const isMajorSelected = (id: number): boolean => _.isEqual(id, selected.category.id);
  const isMinorSelected = (id: number): boolean => _.isEqual(id, minorSelected?.id);

  const onSelectCategory = (categoryFamily: CategoryFamilyAttributes): void => {
    setSelected(categoryFamily);
    // @ts-ignore
    subcategoriesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const onClickCategory = (subcategory: SubcategoryAttributes): void => {
    if (_.isEqual(minorSelected?.id, subcategory.id)) {
      setMinorSelected(null);
      return;
    }
    setMinorSelected(subcategory);
  };

  const cleanFilter = (): void => {
    setSelected(categories[0]);
    setMinorSelected(null);
    onClose();
  };

  const onFilter = (): void => {
    if (_.isNil(minorSelected)) {
      setFilter(selected.category.name);
      cleanFilter();
      return;
    }
    setFilter(minorSelected.name);
    cleanFilter();
  };

  return (
    <div className={`fixed bg-white left-0 w-full h-full transition-all duration-200 z-50 ${isOpen ? 'top-0' : 'top-full'} overflow-y-scroll`}>
      <div className="absolute top-0 left-0 w-full h-full">
        <div className="wrap">
          <div className="flex justify-end py-16 xl:py-0 xl:ml-24">
            <button type="button" onClick={cleanFilter}>
              <Image src={icons.cross.blue} styles="w-12 h-12" />
            </button>
          </div>
          <div className="xl:flex pb-12">
            <div className="xl:w-screen-initial xl:px-2">
              <TextContainer tag="h3" size="one-line-medium" font="montserrat" styles="mb-16">
                {t('filter:find_event')}
              </TextContainer>
              <div className="w-full">
                <div className="">
                  {_.map((category: CategoryFamilyAttributes) => (
                    <MajorCategory
                      key={category.category.id}
                      category={category.category}
                      onClick={() => onSelectCategory(category)}
                      isSelected={isMajorSelected(category.category.id)}
                      isActive={_.isEqual(category.category.id, selected.category.id)}
                    />
                  ))(categories)}
                </div>
              </div>
            </div>
            <div className="xl:flex-1 xl:px-2" ref={subcategoriesRef}>
              <TextContainer font="opensans-bold" styles="mb-16">
                {t('add_event:category_options', { category: t(`categories:${selected.category.name}`) })}
              </TextContainer>
              <div className="flex flex-wrap">
                {_.map((subcategory: SubcategoryAttributes) => (
                  <MinorCategory
                    key={subcategory.id}
                    subcategory={subcategory}
                    onChange={onClickCategory}
                    isSelected={isMinorSelected(subcategory.id)}
                  />
                ))(selected.subcategories)}
              </div>
            </div>
            <div className="xl:w-128 xl:px-2 flex flex-col items-end justify-end">
              <ActionButton
                title={t('filter:find_in_timeline')}
                onClick={onFilter}
              />
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Filter;
