import React, {
  FunctionComponent, useState, useEffect, ChangeEvent,
} from 'react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash/fp';

import { EventAttributes, EventParams } from '../../../../store/types/event';
import { useStoreActions, useStoreState } from '../../../../store/hooks';
import { isValidResponse } from '../../../../api/axios';
import { CategoryFamilyAttributes } from '../../../../store/types/category';
import Authenticated from '../../../../components/layout/Authenticated';
import PageHeader from '../../components/PageHeader';
import ScreenLoader from '../../../../components/loaders/ScreenLoader';
import { CarAttributes } from '../../../../store/types/car';
import {
  parseCategoriesForServer,
  sanitizeEventCategories,
} from '../../../../lib/helpers/categories';
import { eventParams, isNewEvent, minimumEventDate } from '../../../../lib/helpers/event';
import useNavigation from '../../../../services/hooks/navigation';
import { paths } from '../../../../lib/routing';
import date from '../../../../services/date';
import graphics from '../../../../assets/images/graphics';
import SubmitLoader from '../../../../components/loaders/SubmitLoader';
import useQuery from '../../../../services/hooks/query';
import Back from '../../../../components/layout/components/Back';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import Image from '../../../../components/Image';
import icons from '../../../../assets/images/icons';

import Categories from './categories/Categories';
import Info from './info/Info';

type Props = {
  car: CarAttributes;
  event: EventAttributes;
  service?: boolean;
}

const isAssistantCategory = (assistantCategory: string | null): boolean => (
  !_.isNil(assistantCategory) && !_.isEmpty(assistantCategory)
);

const isAssistantEvent = (isAssistant: boolean, assistantCategory: string | null): boolean => (
  isAssistant || isAssistantCategory(assistantCategory)
);

const Event: FunctionComponent<Props> = (
  {
    car,
    event,
    service = false,
  }: Props,
) => {
  const { create, update, destroy } = useStoreActions((actions) => actions.events);
  const query = useQuery();
  const { index } = useStoreActions((actions) => actions.categories);
  const { flash } = useStoreActions((actions) => actions);
  const { user } = useStoreState((state) => state.user);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState<CategoryFamilyAttributes[]>([]);
  const [currentPage, setCurrentPage] = useState<'categories' | 'info'>('categories');
  const [isConfirm, setIsConfirm] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const { settings } = user;
  const navigate = useNavigation();
  const assistantCategory: string | null = query.get('category');

  useEffect(() => {
    index().then((response: AxiosResponse) => {
      if (isValidResponse(response)) {
        setCategories(response.data);
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const onDeleteEvent = async (): Promise<void> => {
    const response = await destroy({ carID: `${car.id}`, id: `${event.id}` });
    if (isValidResponse(response)) {
      setDeleteModal(false);
      navigate(paths.car.edit(car.id));
      return;
    }
    flash.set({ value: response.data, type: 'error' });
    setDeleteModal(false);
  };

  const schema = yup.object().shape({
    title: yup.string()
      .required(t('errors:required')),
    date: yup.date()
      .required(t('errors:required'))
      .min(minimumEventDate(car), t('errors:date:minimum')),
    mileage: yup.number()
      .integer(t('errors:number:integer')),
  });

  const handleSubmittion = async (data: EventParams): Promise<void> => {
    data.eventCategories = parseCategoriesForServer(
      data.eventCategories as CategoryFamilyAttributes[],
    );
    const response = isNewEvent(data.id) ? await create(data) : await update(data);
    if (isValidResponse(response)) {
      flash.set({ value: t('flash:success:event'), type: 'success' });
      navigate(service ? paths.serviceCar.edit(car.id) : paths.car.edit(car.id));
      return;
    }
    flash.set({ value: response.data, type: 'error' });
    navigate(service ? paths.serviceCar.edit(car.id) : paths.car.edit(car.id));
  };

  const handleOnSubmit = (data: EventParams): void => {
    if (!data.isDraft && _.get('tips.event')(settings)) {
      setIsConfirm(true);
      return;
    }

    handleSubmittion(data);
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setSubmitting,
    setFieldValue,
    isSubmitting,
    isValid,
  } = useFormik<EventParams>({
    // @ts-ignore
    initialValues: {
      carID: car.id,
      ..._.pick(eventParams)(event),
      isAssistant: isAssistantEvent(event.isAssistant, assistantCategory),
      date: date.sanitizeDate(event.date),
      documents: [],
      eventCategories: sanitizeEventCategories(event.eventCategories),
    },
    onSubmit: handleOnSubmit,
    validationSchema: schema,
  });

  const title = (
    <PageHeader
      title={t('authenticated:add_event')}
      graphic="hand"
      state={values.isDraft}
      setState={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('isDraft', e.target.checked)}
      deletion={_.gt(event.id, 0) && values.isDraft ? () => setDeleteModal(true) : undefined}
      deletionText={t('add_event:delete')}
    />
  );

  const backUrl = service ? paths.serviceCar.edit(car.id) : paths.car.edit(car.id);

  return (
    <Authenticated title={title}>
      {_.isEqual(currentPage, 'info')
        ? (
          <div onClick={() => setCurrentPage('categories')} className="back-arrow">
            <Image src={icons.arrow.left} styles="h-12 w-12" />
          </div>
        ) : (
          <Back url={backUrl} />
        )}

      {isLoading
        ? <ScreenLoader />
        : (
          <div
            className={`event-page expand has-bg-image ${currentPage} mb-4`}
            style={{ backgroundImage: `url(${values.isDraft ? graphics.draft : null})` }}
          >
            <ConfirmModal
              isOpen={deleteModal}
              message={t('add_event:delete_message')}
              onAccept={onDeleteEvent}
              onReject={() => setDeleteModal(false)}
              onAcceptMessage={t('add_event:delete_acceptance')}
              onRejectMessage={t('add_event:delete_keep')}
            />
            {isSubmitting && <SubmitLoader />}
            <form onSubmit={handleSubmit}>
              <Categories
                categories={categories}
                selection={values.eventCategories as CategoryFamilyAttributes[]}
                setFieldValue={setFieldValue}
                goForward={() => setCurrentPage('info')}
              />
              <Info
                car={car}
                event={event}
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                setSubmitting={setSubmitting}
                isSubmitting={isSubmitting}
                isValid={isValid}
                handleSubmittion={handleSubmittion}
                isConfirm={isConfirm}
                setIsConfirm={setIsConfirm}
              />
            </form>
          </div>
        )}
    </Authenticated>
  );
};

export default Event;
