import React, { FunctionComponent } from 'react';

import icons from '../../../../../assets/images/icons';
import Image from '../../../../../components/Image';

type Props = {
  onClick?: () => void;
  lastPage: boolean;
}

const NextArrow: FunctionComponent<Props> = ({ onClick, lastPage }: Props) => (
  <button
    type="button"
    className={`block ${lastPage ? 'opacity-50' : null}`}
    onClick={onClick}
  >
    <Image src={icons.arrow.right} styles="w-12" />
  </button>
);

export default NextArrow;
