import { CategoryFamilyAttributes, ServerCategories } from './category';
import { DocumentBase64, DocumentURL } from './document';
import { emptyUser, UserAttributes } from './user';

export type EventURLIDs = {
  carID: string;
  id: string;
}

type EventBase = {
  id: number;
  title: string;
  notes: string;
  cost: number;
  mileage: number;
  date: string;

  isAssistant: boolean;
  isHighlighted: boolean;
  isDraft: boolean;
}

export type EventParams = EventBase & {
  carID: number;

  documents: DocumentBase64[];

  eventCategories: ServerCategories[] | CategoryFamilyAttributes[];
};

export type EventAttributes = EventBase & {
  dateYear: number;
  dateMonth: number;

  servicedBy: UserAttributes;

  documents: DocumentURL[];

  eventCategories: CategoryFamilyAttributes[];
}

export type MonthEvents = {
  month: string;
  events: EventAttributes[];
}

export type YearEvents = {
  year: string;
  eventsByMonth: MonthEvents[];
}

export const emptyEvent: EventAttributes = {
  id: -1,
  title: '',
  notes: '',
  cost: 0,
  mileage: 0,
  date: '',
  isAssistant: false,
  isHighlighted: false,
  isDraft: true,

  dateYear: 0,
  dateMonth: 0,

  servicedBy: emptyUser,

  documents: [],
  eventCategories: [],
};
