import React, { FunctionComponent } from 'react';
import Loader from 'react-loaders';

import 'loaders.css/loaders.css';

const ScreenLoader: FunctionComponent = () => (
  <div className="w-full flex-1 flex flex-col justify-center items-center">
    <div className="wrap flex flex-col items-center">
      <Loader
        type="line-scale-pulse-out"
        innerClassName="loader-screen"
        active
      />
    </div>
  </div>

);

export default ScreenLoader;
