import _ from 'lodash/fp';
import { ChangeEvent } from 'react';

export const hasErrors = (errors: string | undefined): boolean => (
  !_.isUndefined(errors) && !_.isEmpty(errors)
);

export const isTouched = (touched: boolean | undefined): boolean => (
  _.isUndefined(touched) ? false : touched
);

export const onChangeFile = (e: ChangeEvent<HTMLInputElement>): File | undefined => {
  if (!e.cancelable) {
    const { files } = e.target;
    if (!_.isNil(files)) {
      return files[0];
    }
  }
  return undefined;
};

export const onChangeFiles = (e: ChangeEvent<HTMLInputElement>): FileList | undefined => {
  if (!e.cancelable) {
    const { files } = e.target;
    if (!_.isNil(files) && _.gt(_.size(files), 0)) {
      return files;
    }
  }
  return undefined;
};
