import { AxiosResponse } from 'axios';
import _ from 'lodash/fp';

import { AccountDelete, NewUserParams, UserParams } from '../../store/types/user';
import client, { request } from '../axios';

const joinPairs = _.join('=');

const queryParams = _.flow(
  _.toPairs,
  _.map(joinPairs),
  _.join('&'),
);

export const index = async (): Promise<AxiosResponse> => {
  try {
    return await client.get('/user');
  } catch (error) {
    // @ts-ignore
    return error.response;
  }
};

export const create = async (params: NewUserParams): Promise<AxiosResponse> => (
  request(client.post('/signup', { ...params }))
);

export const update = async (params: UserParams): Promise<AxiosResponse> => (
  request(client.put('/user', { ...params }))
);

export const suggest = async (email: string): Promise<AxiosResponse> => (
  request(client.post('/user/suggest', { email }))
);

export const destroy = async (params: AccountDelete): Promise<AxiosResponse> => (
  request(client.delete(`/user?${queryParams(params)}`))
);

export const billingPortal = async (): Promise<AxiosResponse> => (
  request(client.post('/user/portal'))
);
