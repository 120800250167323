import { AxiosResponse } from 'axios';

import client, { request } from '../axios';
import { SessionParams } from '../../store/types/session';

// eslint-disable-next-line import/prefer-default-export
export const create = async (params: SessionParams): Promise<AxiosResponse> => (
  request(
    client.post('/session', {
      email: params.email.toLowerCase(),
      password: params.password,
    }),
  )
);
