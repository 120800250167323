import React, { FunctionComponent, useEffect, useState } from 'react';
import _ from 'lodash/fp';

import { useStoreActions, useStoreState } from '../../../store/hooks';
import { CarAttributes } from '../../../store/types/car';
import Authenticated from '../../../components/layout/Authenticated';
import AppLoader from '../../../components/loaders/AppLoader';
import { hasPendingRequests, viewersNumber } from '../../../lib/helpers/viewers';
import { ViewerAttributes } from '../../../store/types/viewers';

import MyCars from './components/MyCars';
import SharedByMe from './components/SharedByMe';
import SharedWithMe from './components/SharedWithMe';

const hasViewersOrPendingRequests = (viewers: ViewerAttributes[]): boolean => (
  _.gt(viewersNumber(viewers), 0) || hasPendingRequests(viewers)
);

const sharedByMeCars = _.filter(
  (car: CarAttributes) => (
    !_.isEmpty(car.viewers) && hasViewersOrPendingRequests(car.viewers)
  ),
);

const Home: FunctionComponent = () => {
  const { index } = useStoreActions((actions) => actions.cars);
  const { cars } = useStoreState((state) => state.cars);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => index().then((result: boolean) => setIsLoading(!result)), []);

  if (isLoading) return <AppLoader />;

  return (
    <Authenticated>
      <div className="xl:flex xl:items-stretch md:py-16">
        <div className="flex flex-col items-center mb-12 xl:w-1/2 xl:mb-0">
          <MyCars cars={cars} />
        </div>
        <div className="flex flex-col items-center xl:w-1/2">
          <SharedByMe cars={sharedByMeCars(cars)} />
          <SharedWithMe />
        </div>
      </div>
    </Authenticated>
  );
};

export default Home;
