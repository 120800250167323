import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import TextContainer from '../../../../../../components/TextContainer';

type Props = {
  title?: string;
  content: string | number;
}

const fieldContent = (content: string | number): string | number => {
  if (typeof content !== 'string' || !_.gt(content.length, 50)) {
    return content;
  }

  return _.truncate({ length: 50, omission: '...' })(content);
};

const Field: FunctionComponent<Props> = ({ content, title = '' }: Props) => (
  <div className={`mb-4 ${!_.isEmpty(title) ? 'flex' : ''}`}>
    <TextContainer size="text-small" font="montserrat" styles="mr-2">
      {title}
    </TextContainer>
    <TextContainer size="text-small" color="text-primary">
      {fieldContent(content)}
    </TextContainer>
  </div>
);

export default Field;
