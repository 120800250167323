import { AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isValidResponse } from '../../../api/axios';
import Authenticated from '../../../components/layout/Authenticated';
import Back from '../../../components/layout/components/Back';
import AppLoader from '../../../components/loaders/AppLoader';
import InfoModal from '../../../components/modals/InfoModal';
import TextContainer from '../../../components/TextContainer';
import { paths } from '../../../lib/routing';
import useNavigation from '../../../services/hooks/navigation';
import { useStoreActions, useStoreState } from '../../../store/hooks';
import { emptySharedCarAttributes } from '../../../store/types/shared';
import SharedWithMeCard from '../home/cards/SharedWithMeCard';

type RouteParams = {
  id: string;
  token: string;
}

const TransferCarShare: FunctionComponent = () => {
  const { id, token } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState(true);
  const { flash } = useStoreActions((actions) => actions);
  const { create } = useStoreActions((actions) => actions.viewers);
  const [sharedCar, setSharedCar] = useState(emptySharedCarAttributes);
  const { isVerified } = useStoreState((state) => state.user);
  const navigate = useNavigation();
  const { t } = useTranslation();

  useEffect(() => {
    if (isVerified) {
      create({
        accessLevel: 'TRANSFER',
        pending: true,
        carID: parseInt(id, 10),
        token,
      }).then((response: AxiosResponse) => {
        if (isValidResponse(response)) {
          setSharedCar(response.data);
          setIsLoading(false);
          return;
        }
        navigate(paths.home);
        flash.set({ type: 'error', value: response.data });
      });
      return;
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <Authenticated>
      <div className="expand">
        {!isVerified && (
          <InfoModal
            isOpen={!isVerified && !isLoading}
            onAccept={() => navigate(paths.home)}
            message={t('popups:needs_verification')}
          />
        )}
        <Back url={paths.home} />
        <div className="max-w-192 mx-auto expand justify-center">
          <TextContainer>
            {t('share:car_for_transfer', { name: sharedCar.user.name })}
          </TextContainer>
          <SharedWithMeCard
            car={sharedCar.car}
            accessLevel={sharedCar.accessLevel}
            pending={sharedCar.pending}
          />
        </div>
      </div>
    </Authenticated>
  );
};

export default TransferCarShare;
