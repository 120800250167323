import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import ActionButton from '../buttons/ActionButton';
import TextContainer from '../TextContainer';
import i18n from '../../services/i18n';

import Modal from './components/Modal';
import ModalBackground from './components/ModalBackground';
import DontShowAgain from './components/DontShowAgain';

export type Props = {
  isOpen: boolean;
  message: string;
  onAccept: () => void;
  onAcceptMessage?: string;
  onReject: () => void;
  onRejectMessage?: string;
  alert?: boolean;
  isCheck?: boolean;
  onChangeCheck?: () => void;
}

const ConfirmModal: FunctionComponent<Props> = (
  {
    isOpen,
    message,
    onAccept,
    onAcceptMessage,
    onReject,
    onRejectMessage,
    alert = false,
    isCheck,
    onChangeCheck,
  }: Props,
) => (
  <ModalBackground isOpen={isOpen}>
    <Modal onClose={onReject} alert={alert}>
      <TextContainer
        font="opensans-bold"
        color="text-primary"
        styles="modal-message"
      >
        {message}
      </TextContainer>
      <ActionButton
        title={_.isUndefined(onRejectMessage) ? i18n.t('cancel') : onRejectMessage}
        onClick={onReject}
        styles="mb-8"
      />
      <ActionButton
        title={_.isUndefined(onAcceptMessage) ? i18n.t('accept') : onAcceptMessage}
        onClick={onAccept}
        type="outline"
        styles="mb-16"
      />
      {!_.isUndefined(isCheck) && !_.isUndefined(onChangeCheck) && (
        <DontShowAgain isCheck={isCheck} onChangeCheck={onChangeCheck} />
      )}
    </Modal>
  </ModalBackground>
);

export default ConfirmModal;
