import _ from 'lodash/fp';
import React, { FunctionComponent, MouseEvent } from 'react';

import {
  ButtonSize,
  ButtonType,
} from '../../lib/style/button';

export type Props = {
  title: string;
  onClick: ((event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void) | undefined;
  type?: ButtonType;
  size?: ButtonSize;
  styles?: string;
}

const ActionButton: FunctionComponent<Props> = (
  {
    title,
    onClick,
    type = 'regular',
    size = 'small',
    styles = '',
  }: Props,
) => (
  <button
    type="button"
    onClick={onClick}
    disabled={_.isUndefined(onClick)}
    className={`btn-${type} btn-${size} ${styles}`}
  >
    {title}
  </button>
);

export default ActionButton;
