import _ from 'lodash/fp';

import { useStoreActions, useStoreState } from '../../store/hooks';

type AssistantHook = {
  isActive: boolean;
  isHide: boolean;
  onHide: () => void;
  onClose: () => void;
  onShow: () => void;
  onCompleteCar: () => void;
}

const isCarAssistantComplete = (completed: string, carID: number): boolean => (
  _.gte(_.indexOf(`${carID}`)(_.split(',', completed)), 0)
);

const useAssistant = (carID: number): AssistantHook => {
  const { update } = useStoreActions((actions) => actions.settings);
  const { settings } = useStoreState((state) => state.user.user);
  const { active, hide, completedCars } = settings.assistant;

  const isAssistant = active && !isCarAssistantComplete(completedCars, carID);
  const isActive = isAssistant && !hide;
  const isHide = isAssistant && hide;

  const onHide = async () => {
    const newSettings = { ...settings };
    newSettings.assistant.hide = true;
    await update(newSettings);
  };

  const onShow = async () => {
    const newSettings = { ...settings };
    newSettings.assistant.hide = false;
    await update(newSettings);
  };

  const onClose = async () => {
    const newSettings = { ...settings };
    newSettings.assistant.active = false;
    await update(newSettings);
  };

  const onCompleteCar = async () => {
    const newSettings = { ...settings };
    newSettings.assistant.completedCars = `${settings.assistant.completedCars}${carID},`;
    await update(newSettings);
  };

  return {
    isActive,
    isHide,
    onHide,
    onClose,
    onShow,
    onCompleteCar,
  };
};

export default useAssistant;
