import {
  Action,
  action,
  computed,
  Computed,
  Thunk,
  thunk,
} from 'easy-peasy';
import _ from 'lodash/fp';

import { SessionAttributes, SessionParams } from '../../types/session';
import client, { isValidResponse } from '../../../api/axios';
import { removeToken, saveToken } from '../../../services/cookies/index';

export interface SessionModel {
  pasetoToken: string;
  calendly: boolean;

  init: Thunk<SessionModel, SessionAttributes>;

  create: Thunk<SessionModel, SessionParams>;
  destroy: Thunk<SessionModel>;

  setToken: Action<SessionModel, string>;
  deleteToken: Action<SessionModel>;
  setCalendly: Action<SessionModel, boolean>;

  isLoggedIn: Computed<SessionModel, boolean>;
}

const sessionModel: SessionModel = {
  pasetoToken: '',
  calendly: true,

  init: thunk((actions, params, { getStoreActions }) => {
    actions.setToken(params.token);
    // @ts-ignore
    getStoreActions().user.init(params.user);
    // @ts-ignore
    getStoreActions().flash.unSet();
  }),

  create: thunk(async (actions, params, { injections }) => {
    const { sessionService } = injections;
    const response = await sessionService.create(params);

    if (isValidResponse(response)) {
      actions.init(response.data as SessionAttributes);
      return '';
    }

    return response.data as string;
  }),

  destroy: thunk((actions, _params) => {
    actions.deleteToken();
  }),

  setToken: action((state, token) => {
    saveToken(token);
    state.pasetoToken = token;
    client.defaults.headers.common.Authorization = `Bearer ${token}`;
  }),

  deleteToken: action((state) => {
    removeToken();
    state.pasetoToken = '';
    client.defaults.headers.common.Authorization = '';
  }),

  setCalendly: action((state, params) => {
    state.calendly = params;
  }),

  isLoggedIn: computed((state) => !_.isEmpty(state.pasetoToken)),
};

export default sessionModel;
