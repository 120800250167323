import classNames from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  border?: boolean;
}

const Card: FunctionComponent<Props> = ({ children, border = false }: Props) => {
  const className = classNames('expand items-center py-8 justify-center rounded-md w-full max-w-screen-xs xs:my-16 relative', {
    'xs:border border-primary': border,
  });
  return (
    <div className={className}>
      {children}
    </div>
  );
};

export default Card;
