import _ from 'lodash/fp';
import React, { FunctionComponent } from 'react';

import Image from '../../../../components/Image';
import TextContainer from '../../../../components/TextContainer';

type Props = {
  title: string;
  icon: string;
  state: string;
}

const FormHeader: FunctionComponent<Props> = ({ title, icon, state }: Props) => (
  <div className="flex w-full justify-between items-center mb-12">
    <div>
      <TextContainer tag="h2" size="one-line-medium" font="montserrat">
        {title}
      </TextContainer>
      {!_.isEmpty(state) && (
        <TextContainer size="one-line-small" styles="mt-2" color="text-gray-3">
          {state}
        </TextContainer>
      )}
    </div>
    <div className="min-w-48 w-48 h-48 rounded-full bg-primary-x-light flex items-center justify-center">
      <Image src={icon} styles="w-32 h-32" />
    </div>
  </div>
);

export default FormHeader;
