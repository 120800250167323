import _ from 'lodash/fp';
import { useState } from 'react';

type Pagination = (() => void) | undefined;

const PAGE_CHANGE = 1;
const FIRST_PAGE = 1;

const calculateStart = (page: number, perPage: number): number => (page - FIRST_PAGE) * perPage;
// @ts-ignore
const calculateEnd = (start: number, carsLength: number, perPage: number): number => (
  _.min([start + perPage, carsLength])
);

function getPage<Type>(list: Type[], page: number, perPage: number): Type[] {
  if (_.isEqual(page, FIRST_PAGE)) {
    return _.take(perPage)(list);
  }
  return _.slice(
    calculateStart(page, perPage),
    calculateEnd(calculateStart(page, perPage), list.length, perPage),
  )(list);
}

function maxPages<Type>(list: Type[], perPage: number): number {
  return _.ceil(_.size(list) / perPage);
}

function usePagination<Type>(list: Type[], perPage: number): [Type[], Pagination, Pagination] {
  const [page, setPage] = useState(FIRST_PAGE);

  const firstPage = page > FIRST_PAGE;
  const lastPage = maxPages(list, perPage) <= page;

  return [
    _.lt(list.length, perPage) ? list : getPage(list, page, perPage),
    lastPage ? undefined : (): void => setPage(page + PAGE_CHANGE),
    !firstPage ? undefined : (): void => setPage(page - PAGE_CHANGE),
  ];
}

export default usePagination;
