import React, { FunctionComponent } from 'react';

import icons from '../../../../../../assets/images/icons';
import LinkIcon from '../../../../../../components/buttons/LinkIcon';
import i18n from '../../../../../../services/i18n';

type Props = {
  to: string;
}

const AddEvent: FunctionComponent<Props> = ({ to }: Props) => (
  <LinkIcon
    to={to}
    icon={icons.add.blue}
    title={i18n.t('authenticated:add_event')}
    text={i18n.t('authenticated:add_event')}
    styles="mt-8"
  />
);

export default AddEvent;
