import { AxiosResponse } from 'axios';

import { PaymentParams } from '../../store/types/payments';
import client, { request } from '../axios';

export const create = async (params: PaymentParams): Promise<AxiosResponse> => (
  request(client.post(`/cars/${params.carID}/${params.type}`, params))
);

export const index = async (params: string): Promise<AxiosResponse> => (
  request(client.get(`/coupon?id=${params}`))
);

export const update = async (params: number): Promise<AxiosResponse> => (
  request(client.put(`/cars/${params}/subscribe`))
);
