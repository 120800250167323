import {
  Thunk,
  thunk,
} from 'easy-peasy';

import {
  SnapshotViewParams,
  ViewerCreationParams,
  ViewerParams,
} from '../../types/viewers';

export interface ViewersModel {
  show: Thunk<ViewersModel, SnapshotViewParams>;

  create: Thunk<ViewersModel, ViewerCreationParams>;

  update: Thunk<ViewersModel, ViewerParams>;

  destroy: Thunk<ViewersModel, ViewerParams>;

  transfer: Thunk<ViewersModel, ViewerParams>;
}

const viewersModel: ViewersModel = {
  show: thunk(async (actions, params, { injections }) => {
    const { viewersService } = injections;
    return viewersService.show(params);
  }),

  create: thunk(async (actions, params, { injections }) => {
    const { viewersService } = injections;
    return viewersService.create(params);
  }),

  update: thunk(async (actions, params, { injections }) => {
    const { viewersService } = injections;
    return viewersService.update(params);
  }),

  destroy: thunk(async (actions, id, { injections }) => {
    const { viewersService } = injections;
    return viewersService.destroy(id);
  }),

  transfer: thunk(async (actions, params, { injections }) => {
    const { viewersService } = injections;
    return viewersService.transfer(params);
  }),
};

export default viewersModel;
