import React, { FunctionComponent, ReactNode } from 'react';

import ModalBackground from './components/ModalBackground';
import Modal from './components/Modal';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const UnautorizedModal: FunctionComponent<Props> = ({ isOpen, onClose, children }: Props) => (
  <ModalBackground isOpen={isOpen}>
    <Modal onClose={onClose} alert={false}>
      {children}
    </Modal>
  </ModalBackground>
);

export default UnautorizedModal;
