import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import icons from '../../../../assets/images/icons';
import usePagination from '../../../../services/hooks/pagination';
import { CarAttributes } from '../../../../store/types/car';
import Pagination from '../pagination/Pagination';
import SharedByMeCard from '../cards/SharedByMeCard';

import EmptyMessage from './EmptyMessage';
import HomeSection from './HomeSection';

type Props = {
  cars: CarAttributes[];
}

const CARS_PER_PAGE = 2;

const SharedByMe: FunctionComponent<Props> = ({ cars }: Props) => {
  const { t } = useTranslation();
  const [page, next, prev] = usePagination(cars, CARS_PER_PAGE);

  return (
    <HomeSection icon={icons.sharedByMe} title={t('home:shared_by_me')} styles="mb-12">
      {_.gt(_.size(cars), CARS_PER_PAGE) && (
      <Pagination next={next} prev={prev} />
      )}
      {_.isEmpty(cars) && <EmptyMessage message={t('home:empty_shared_by_me')} />}
      {_.map((car: CarAttributes) => (
        <SharedByMeCard key={car.id} car={car} />
      ))(page)}
    </HomeSection>
  );
};

export default SharedByMe;
