import { Thunk, thunk } from 'easy-peasy';

import { EventParams, EventURLIDs } from '../../types/event';

export interface EventsModel {
  index: Thunk<EventsModel, number>;

  show: Thunk<EventsModel, EventURLIDs>;

  create: Thunk<EventsModel, EventParams>;

  update: Thunk<EventsModel, EventParams>;

  destroy: Thunk<EventsModel, EventURLIDs>;
}

const eventsModel: EventsModel = {
  index: thunk(async (actions, carID, { injections }) => {
    const { eventsService } = injections;
    const response = await eventsService.index(carID);

    return response;
  }),

  show: thunk(async (actions, params, { injections }) => {
    const { eventsService } = injections;
    const response = await eventsService.show(params);

    return response;
  }),

  create: thunk(async (actions, params, { injections }) => {
    const { eventsService } = injections;
    const response = await eventsService.create(params);

    return response;
  }),

  update: thunk(async (actions, params, { injections }) => {
    const { eventsService } = injections;
    const response = await eventsService.update(params);

    return response;
  }),

  destroy: thunk(async (actions, params, { injections }) => {
    const { eventsService } = injections;
    const response = await eventsService.destroy(params);

    return response;
  }),
};

export default eventsModel;
