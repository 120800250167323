import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import icons from '../assets/images/icons';

import Image from './Image';
import NavigationLink from './NavigationLink';
import TextContainer from './TextContainer';

export type Props = {
  message: string;
  onClose?: () => void;
  direction?: 'none' | 'top-right' | 'bottom-left' | 'top-left' | 'top-center';
  color?: 'bg-green' | 'bg-primary-dark';
  icon?: string;
  link?: string;
  linkMessage?: string;
  styles?: string
}

const hasLink = (link: string, linkMessage: string) => (
  !_.isEmpty(link) && !_.isEmpty(linkMessage)
);

const Balloon: FunctionComponent<Props> = (
  {
    message,
    onClose,
    direction = 'none',
    color = 'bg-primary-dark',
    icon,
    link = '',
    linkMessage = '',
    styles = '',
  }: Props,
) => (
  <TextContainer
    color="text-white"
    size="text-small"
    font="opensans-bold"
    styles={`balloon ${direction} ${color} ${styles}`}
  >
    {!_.isUndefined(onClose) && (
      <button type="button" onClick={onClose} className="balloon-close">
        <Image src={icons.cross.white} styles="balloon-close-icon" />
      </button>
    )}
    <span className={`flex ${hasLink(link, linkMessage) ? 'with-link' : ''}`}>
      {!_.isUndefined(icon) && <Image src={icon} />}
      {message}
    </span>
    {hasLink(link, linkMessage) && (
      <NavigationLink
        to={link}
        text={linkMessage}
        styles="block montserrat one-line-small text-primary-x-light w-full text-right"
      />
    )}
  </TextContainer>
);

export default Balloon;
