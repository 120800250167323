import axios, { AxiosResponse } from 'axios';
import _ from 'lodash/fp';

import { getLanguage } from '../services/cookies';

export const DEEPLINK_BASE = process.env.REACT_APP_TMC_DEEPLINK_BASE;

export const isValidResponse = _.flow(
  _.get('status'),
  _.inRange(200, 300),
);

const baseURL = process.env.REACT_APP_TMC_API_BASE;

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line no-console
  console.info('Using server URL: ', baseURL);
}
// eslint-disable-next-line
export const request = async (requestFunction: any): Promise<AxiosResponse> => {
  try {
    return await requestFunction;
  } catch (error) {
    return error.response;
  }
};

const client = axios.create({ baseURL });

client.defaults.headers.common['Accept-Language'] = getLanguage();

export default client;
