import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import PrevArrow from './components/PrevArrow';
import NextArrow from './components/NextArrow';

type Props = {
  next?: () => void;
  prev?: () => void;
}

const Pagination: FunctionComponent<Props> = ({ next, prev }: Props) => (
  <div className="w-full max-w-192 pb-8 flex items-center justify-end">
    <PrevArrow onClick={prev} firstPage={_.isUndefined(prev)} />
    <div className="w-8" />
    <NextArrow onClick={next} lastPage={_.isUndefined(next)} />
  </div>
);

export default Pagination;
