import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import i18n from '../../services/i18n';
import ActionButton from '../buttons/ActionButton';
import TextContainer from '../TextContainer';

import DontShowAgain from './components/DontShowAgain';
import Modal from './components/Modal';
import ModalBackground from './components/ModalBackground';

export type Props = {
  isOpen: boolean;
  message: string;
  onAccept: () => void;
  onAcceptMessage?: string;
  onReject?: () => void;
  alert?: boolean;
  isCheck?: boolean;
  onChangeCheck?: () => void;
}

const InfoModal: FunctionComponent<Props> = (
  {
    isOpen,
    message,
    onAccept,
    onAcceptMessage,
    onReject,
    alert = false,
    isCheck,
    onChangeCheck,
  }: Props,
) => (
  <ModalBackground isOpen={isOpen}>
    <Modal
      onClose={_.isUndefined(onReject) ? onAccept : onReject}
      alert={alert}
    >
      <TextContainer
        font="opensans-bold"
        color="text-primary"
        styles="modal-message"
      >
        {message}
      </TextContainer>
      <ActionButton
        title={_.isUndefined(onAcceptMessage) ? i18n.t('accept') : onAcceptMessage}
        onClick={onAccept}
        styles="mb-16"
      />
      {!_.isUndefined(isCheck) && !_.isUndefined(onChangeCheck) && (
        <DontShowAgain isCheck={isCheck} onChangeCheck={onChangeCheck} />
      )}
    </Modal>
  </ModalBackground>
);

export default InfoModal;
