import React, { FunctionComponent, ChangeEvent } from 'react';

import icons from '../../../../assets/images/icons';
import Image from '../../../../components/Image';
import TextContainer from '../../../../components/TextContainer';
import i18n from '../../../../services/i18n';
import { SubcategoryAttributes } from '../../../../store/types/category';

type Props = {
  categoryID: number;
  subcategory: SubcategoryAttributes;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isSelected: boolean;
}

const MinorCategory: FunctionComponent<Props> = (
  {
    categoryID,
    subcategory,
    onChange,
    isSelected,
  }: Props,
) => (
  <label
    htmlFor={`${categoryID}.${subcategory.id}`}
    className="w-64 flex flex-col items-center mb-4 mr-4 cursor-pointer"
  >
    <Image
      src={isSelected ? icons.verified.blue : icons.add.empty}
      styles="min-w-16 min-h-16 w-16 h-16 max-w-16 max-h-16 mb-4"
    />
    <TextContainer color="text-primary" styles="text-center w-full mb-4">
      {i18n.t(`categories:${subcategory.name}`)}
    </TextContainer>
    <input
      type="checkbox"
      name={`${categoryID}.${subcategory.id}`}
      id={`${categoryID}.${subcategory.id}`}
      checked={isSelected}
      onChange={onChange}
      className="hidden"
    />
  </label>
);

export default MinorCategory;
