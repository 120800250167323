import React, { FunctionComponent, useState } from 'react';

import i18n from '../../../../services/i18n';
import { emptyCar, Page } from '../../../../store/types/car';
import CarPage from '../car-page/CarPage';
import Snapshot from '../snapshot/Snapshot';
import Timeline from '../timeline/Timeline';
import VehicleData from '../vehicle-data/VehicleData';

const NewCar: FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState<Page>('vehicle-data');

  return (
    <CarPage
      current={currentPage}
      setCurrent={setCurrentPage}
      title={i18n.t('car:new_car')}
      car={emptyCar}
    >
      <Timeline car={emptyCar} />
      <VehicleData car={emptyCar} />
      <Snapshot car={emptyCar} setCurrent={setCurrentPage} />
    </CarPage>
  );
};

export default NewCar;
