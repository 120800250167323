import { useStoreActions, useStoreState } from '../../store/hooks';

type Check = 'event' | 'draft' | 'engine' | 'vehicle'

export const useDemoCar = (): [boolean, () => void] => {
  const { update } = useStoreActions((actions) => actions.settings);
  const { settings } = useStoreState((state) => state.user.user);

  const deactivateDemoCar = (): void => {
    settings.demoCar = false;
    update(settings);
  };

  return [settings.demoCar, deactivateDemoCar];
};

export const useCheckTip = (type: Check): ((check: boolean) => void) => {
  const { update } = useStoreActions((actions) => actions.settings);
  const { settings } = useStoreState((state) => state.user.user);

  const checkTip = (check: boolean): void => {
    if (check) {
      settings.tips[type] = false;
      update(settings);
    }
  };

  return checkTip;
};
