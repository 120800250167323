import React, { FunctionComponent } from 'react';

import { paths } from '../../../../lib/routing';
import { CarAttributes } from '../../../../store/types/car';
import { carImage, carTitle, isCarComplete } from '../../../../lib/helpers/car';
import TextContainer from '../../../../components/TextContainer';
import i18n from '../../../../services/i18n';
import { hasTransferPending } from '../../../../lib/helpers/viewers';

import BaseCard from './components/BaseCard';

export type Props = {
  car: CarAttributes;
}

const CarCard: FunctionComponent<Props> = ({ car }: Props) => (
  <BaseCard url={paths.car.edit(car.id)} thumbnail={carImage(car.carImages, 'medium')}>
    <div className="flex flex-1 flex-col justify-between">
      <TextContainer tag="h5" font="montserrat" styles="max-h-24 overflow-hidden">
        {carTitle(car)}
      </TextContainer>
      {!isCarComplete(car) && (
        <TextContainer size="one-line-small" color="text-gray-2" styles="text-right">
          {i18n.t('car:draft')}
        </TextContainer>
      )}
      {isCarComplete(car) && hasTransferPending(car.viewers) && (
        <TextContainer size="one-line-small" color="text-gray-2" styles="text-right">
          {i18n.t('share:transfer_pending')}
        </TextContainer>
      )}
    </div>
  </BaseCard>
);

export default CarCard;
