import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import TextContainer from '../../../../components/TextContainer';
import i18n from '../../../../services/i18n';

const onboardingList: string[] = [
  'onboarding:slider_entry_1',
  'onboarding:slider_entry_2',
  'onboarding:slider_entry_3',
  'onboarding:slider_entry_4',
];

const OnboardingSlider: FunctionComponent = () => (
  <div className="max-w-screen-inital w-full my-4">
    <Carousel
      autoPlay
      emulateTouch
      infiniteLoop
      interval={6000}
      dynamicHeight={false}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      renderIndicator={(clickHandler, isSelected, index, label) => (
        <button
          value={index}
          tabIndex={index}
          aria-label={label}
          type="button"
          className={`slider-dot ${isSelected ? 'selected' : ''}`}
          onClick={clickHandler}
        />
      )}
    >
      {_.map((item: string) => (
        <TextContainer
          key={item}
          tag="p"
          size="text-regular"
          color="text-gray-3"
          styles="min-h-64"
        >
          {i18n.t(item)}
        </TextContainer>
      ))(onboardingList)}
    </Carousel>
  </div>
);

export default OnboardingSlider;
