import React, { FunctionComponent } from 'react';

import { DocumentURL } from '../../../../../../store/types/document';
import PopupGallery from '../../../../../../components/PopupGallery';

type Props = {
  documents: DocumentURL[];
}

const DocumentsGallery: FunctionComponent<Props> = ({ documents }: Props) => (
  <div className="mb-8">
    <PopupGallery
      slides={documents}
      styles="show-event-thumbs"
      thumbSize="medium"
    />
  </div>
);

export default DocumentsGallery;
