import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';

import { PASSWORD_REGEX } from '../../../store/model/user/user';
import { useStoreActions } from '../../../store/hooks';
import useNavigation from '../../../services/hooks/navigation';
import { RecoveryPasswordParams } from '../../../store/types/passwords';
import { isValidResponse } from '../../../api/axios';
import { paths } from '../../../lib/routing';
import TextContainer from '../../../components/TextContainer';
import Flash from '../../../components/forms/Flash';
import Unauthenticated from '../../../components/layout/Unauthenticated';
import EmailInput from '../../../components/forms/EmailInput';
import PasswordInput from '../../../components/forms/PasswordInput';
import SubmitLoader from '../../../components/loaders/SubmitLoader';
import Card from '../components/Card';
import SubmitButton from '../../../components/buttons/SubmitButton';
import Back from '../../../components/layout/components/Back';

type Inputs = RecoveryPasswordParams & {
  passwordConfirmation: string;
}

type RouteParams = {
  email: string;
  token: string;
};

const EditPassword: FunctionComponent = () => {
  const { t } = useTranslation();
  const { update } = useStoreActions((actions) => actions.passwords);
  const [message, setMessage] = useState('');
  const navigation = useNavigation();
  const params = useParams<RouteParams>();

  const schema = yup.object().shape({
    email: yup.string()
      .email(t('errors:email:valid'))
      .required(t('errors:email:required')),
    password: yup.string()
      .required(t('errors:required'))
      .matches(PASSWORD_REGEX, t('signup:password_hint')),
    passwordConfirmation: yup.string()
      .required(t('errors:required'))
      .oneOf([yup.ref('password'), null], t('errors:password')),
  });

  const handleOnSubmit = async (data: Inputs): Promise<void> => {
    const response = await update({
      email: data.email.toLowerCase(),
      password: data.password,
      token: data.token,
    });

    if (isValidResponse(response)) {
      navigation(`${paths.signin}?email=${data.email}&password=${data.password}`);
      return;
    }
    setMessage(response.data);
  };

  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: {
      email: params.email,
      password: '',
      passwordConfirmation: '',
      token: params.token,
    },
    onSubmit: handleOnSubmit,
    validationSchema: schema,
    validateOnBlur: true,
  });

  return (
    <Unauthenticated>
      {isSubmitting && <SubmitLoader />}
      <Card border>
        <Back url={paths.root} styles="absolute top-0 left-0" />
        <TextContainer
          tag="h1"
          font="montserrat"
          size="one-line-large"
          styles="text-center mb-16"
        >
          {t('passwords:title:edit')}
        </TextContainer>
        <form onSubmit={handleSubmit} className="block max-w-full">
          <Flash message={message} />
          <EmailInput
            name="email"
            value={values.email}
            placeholder={t('session:email')}
            onChange={handleChange('email')}
            onBlur={handleBlur('email')}
            touched={touched.email}
            errors={errors.email}
          />
          <PasswordInput
            name="password"
            value={values.password}
            placeholder={t('session:password')}
            onChange={handleChange('password')}
            hint={t('signup:password_hint')}
            onBlur={handleBlur('password')}
            touched={touched.password}
            errors={errors.password}
          />
          <PasswordInput
            name="passwordConfirmation"
            value={values.passwordConfirmation}
            placeholder={t('session:password_confirmation')}
            onChange={handleChange('passwordConfirmation')}
            onBlur={handleBlur('passwordConfirmation')}
            touched={touched.passwordConfirmation}
            errors={errors.passwordConfirmation}
          />
          <SubmitButton
            title={t('send')}
            type="outline"
            disabled={isSubmitting || !isValid}
            styles="mx-auto mb-16"
          />
        </form>
      </Card>
    </Unauthenticated>
  );
};

export default EditPassword;
