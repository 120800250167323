import {
  Action,
  action,
  Thunk,
  thunk,
} from 'easy-peasy';
import _ from 'lodash/fp';

import { isValidResponse } from '../../../api/axios';
import { SharedCarAttributes } from '../../types/shared';

export interface SharedModel {
  sharedCars: SharedCarAttributes[];

  init: Action<SharedModel, SharedCarAttributes[]>;

  index: Thunk<SharedModel, boolean>;

  show: Thunk<SharedModel, string>;
}

const sanitizeParams = (params: SharedCarAttributes[]): SharedCarAttributes[] => (
  _.isEmpty(params) ? [] : params
);

const sharedModel: SharedModel = {
  sharedCars: [],

  init: action((state, params) => {
    state.sharedCars = [...sanitizeParams(params)];
  }),

  index: thunk(async (actions, withDemo, { injections }) => {
    const { sharedService } = injections;
    const response = await sharedService.index(withDemo);

    if (isValidResponse(response)) {
      actions.init(response.data);
    }

    return isValidResponse(response);
  }),

  show: thunk(async (actions, id, { injections }) => {
    const { sharedService } = injections;
    return sharedService.show(id);
  }),
};

export default sharedModel;
