import React, { FunctionComponent, ReactNode } from 'react';

import icons from '../../../assets/images/icons';
import Image from '../../Image';

import CloseTag from './CloseTag';

type Props = {
  onClose: () => void;
  children: ReactNode;
  alert: boolean
}

const Modal: FunctionComponent<Props> = (
  {
    onClose,
    children,
    alert,
  }: Props,
) => (
  <div className="modal">
    <CloseTag onClick={onClose} />
    {alert && <Image src={icons.forbiden.active} styles="modal-alert" />}
    {children}
  </div>
);

export default Modal;
