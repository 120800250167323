import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import {
  ButtonSize,
  ButtonType,
} from '../../lib/style/button';

export type Props = {
  title: string;
  to: string;
  type?: ButtonType;
  size?: ButtonSize;
  styles?: string;
}

const LinkButton: FunctionComponent<Props> = (
  {
    title,
    to,
    type = 'regular',
    size = 'medium',
    styles = '',
  }: Props,
) => (
  <Link
    to={to}
    className={`btn-${type} btn-${size} ${styles}`}
  >
    {title}
  </Link>
);

export default LinkButton;
