import { AxiosResponse } from 'axios';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { isValidResponse } from '../../../../api/axios';
import AppLoader from '../../../../components/loaders/AppLoader';
import { paths } from '../../../../lib/routing';
import useNavigation from '../../../../services/hooks/navigation';
import { useStoreActions } from '../../../../store/hooks';
import { emptyCar } from '../../../../store/types/car';
import { emptyEvent } from '../../../../store/types/event';

import Event from './Event';

type RouteParams = {
  carID: string;
  id: string;
}

type Props = {
  service?: boolean;
}

const EditEvent: FunctionComponent<Props> = ({ service = false }: Props) => {
  const { carID, id } = useParams<RouteParams>();
  const { show } = useStoreActions((actions) => actions.cars);
  const { show: shared } = useStoreActions((actions) => actions.shared);
  const { show: edit } = useStoreActions((actions) => actions.events);
  const { flash } = useStoreActions((actions) => actions);
  const [isLoading, setIsLoading] = useState(true);
  const [car, setCar] = useState(emptyCar);
  const [event, setEvent] = useState(emptyEvent);
  const navigate = useNavigation();

  useEffect(() => {
    (async () => {
      const carResponse: AxiosResponse = service ? await shared(carID) : await show(carID);
      const eventResponse: AxiosResponse = await edit({ carID, id });
      if (isValidResponse(carResponse) && isValidResponse(eventResponse)) {
        setCar(service ? carResponse.data.car : carResponse.data);
        setEvent(eventResponse.data);
        setIsLoading(false);
        return;
      }
      if (!isValidResponse(carResponse)) flash.set({ value: carResponse.data, type: 'error' });
      if (!isValidResponse(eventResponse)) flash.set({ value: eventResponse.data, type: 'error' });
      navigate(paths.home);
    })();
  }, []);

  if (isLoading) {
    return <AppLoader />;
  }

  return (
    <Event car={car} event={event} service={service} />
  );
};

export default EditEvent;
