import React, { FunctionComponent } from 'react';
import _ from 'lodash/fp';

import Image from '../../../../../../components/Image';
import icons from '../../../../../../assets/images/icons';

export type Props = {
  title: string;
  onClick: () => void;
  isActive: boolean;
  isComplete?: boolean;
}

const needsIcon = (isComplete: boolean | undefined): boolean => (
  !_.isUndefined(isComplete) && !isComplete
);

const Tab: FunctionComponent<Props> = (
  {
    title,
    onClick,
    isActive,
    isComplete,
  }: Props,
) => (
  <button
    type="button"
    className={`${isActive ? 'border-b-2 border-primary-dark' : 'text-primary-light'} car-tab`}
    onClick={onClick}
  >
    {needsIcon(isComplete) && isActive && (
      <Image src={icons.forbiden.active} styles="w-8 h-8 mr-1 sm:mr-2" />
    )}
    {needsIcon(isComplete) && !isActive && (
      <Image src={icons.forbiden.inactive} styles="w-8 h-8 mr-1 sm:mr-2" />
    )}
    {title}
  </button>
);

export default Tab;
