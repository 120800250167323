import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

import icons from '../../../assets/images/icons';
import i18n from '../../../services/i18n';
import Image from '../../Image';

type Props = {
  url: string;
  styles?: string;
}

const Back: FunctionComponent<Props> = ({ url, styles = '' }: Props) => (
  <Link to={url} className={`back-arrow ${styles}`} title={i18n.t('back')}>
    <Image src={icons.arrow.left} styles="h-12 w-12" />
  </Link>
);

export default Back;
