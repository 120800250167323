import { Thunk, thunk } from 'easy-peasy';

export interface CategoriesModel {
  index: Thunk<CategoriesModel>;
}

const categoriesModel: CategoriesModel = {
  index: thunk(async (actions, _state, { injections }) => {
    const { categoriesService } = injections;
    const response = await categoriesService.index();

    return response;
  }),
};

export default categoriesModel;
